import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
  Zoom,
  List,
  ListItem,
  Popper,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTE } from 'route/RouteEnums';
import { resetPasswordSchema, checkPasswordCriteria } from 'utils/schema';
import { getEmailAPI, resetPasswordAPI, validateTokenAPI } from 'services/authServices';
import { errorMessages } from 'utils/helper';
import { toast } from 'react-toastify';
import COLORS from 'theme/colors';

const ResetPassword = (props: any) => {
  const [apiResponse, setApiResponse] = useState({ error: undefined });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState({
    passwordPopOver: null,
    mobilePopOver: null,
  });
  const [passwordCriteria, setPasswordCriteria] = useState<any>([]);
  const navigate = useNavigate();
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  useEffect(() => {
    validateTokenAPI(token)
      .then((response: any) => {
        if (response?.status === 200 && !response.data) {
          toast('Invalid token. Try to reset password again', {
            type: 'error',
            icon: true
          });
          setTimeout(() => {
            navigate(ROUTE.FORGOTPASSWORD);
          }, 2000);
        }
      }).catch((e) => {
        if (e?.data?.errorCode === 1002) {
          toast('Invalid token. Try to reset password again', {
            type: 'error',
            icon: true
          });
          setTimeout(() => {
            navigate(ROUTE.FORGOTPASSWORD);
          }, 2000);
        }
      });
  }, [navigate, token]);

  useEffect(() => {
    setPasswordCriteria(checkPasswordCriteria(getValues('password')));
  }, [getValues]);

  const getEmail = () => {
    setIsLoading(true);
    //Send login request to the API
    getEmailAPI(token, (response: any) => {
      setIsLoading(false);
      if (response?.status === 200) {
        setValue('email', response.data);
      } else {
        if (response?.data?.errorCode >= 1000) {
          navigate(ROUTE.LOGIN);
        } else setApiResponse(response?.data);
      }
    });
  };

  useEffect(() => {
    getEmail();
    // eslint-disable-next-line
  }, []);

  const onSubmitForm = (formData: any) => {
    const { email, password } = formData;
    setIsLoading(true);
    const data = {
      email,
      password,
    };
    //Send login request to the API
    resetPasswordAPI(data, (response: any) => {
      setIsLoading(false);
      if (response.status === 200) {
        setApiResponse(response.data);
        navigate(ROUTE.SUCCESSPASSWORD);
      } else {
        const message = errorMessages[response.data.errorCode as keyof typeof errorMessages];
        toast(message, {
          type: 'error',
          icon: true
        });
      }
    });
  };

  const handlePopoverOpen = (event: any, elementName: string) => {
    setAnchorEl({ ...anchorEl, [elementName]: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setAnchorEl({ passwordPopOver: null, mobilePopOver: null });
  };

  const open = {
    passwordPopOver: Boolean(anchorEl.passwordPopOver),
    mobilePopOver: Boolean(anchorEl.mobilePopOver),
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit((formData) => onSubmitForm(formData))}
      onChange={() => setApiResponse({ error: undefined })}
      sx={{ mr: { md: 8, lg: 18 }, ml: { md: 3, lg: 6 } }}
    >
      <Typography variant='h1'>Reset Password</Typography>
      {apiResponse?.error && (
        <Zoom in={true}>
          <Alert severity='error'>{apiResponse.error}</Alert>
        </Zoom>
      )}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <InputLabel htmlFor='newPassword'>New Password</InputLabel>
          <TextField
            fullWidth
            placeholder='Create a password'
            type='password'
            id='newPassword'
            autoComplete='new-password'
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password ? errors.password?.message : ''}
            onFocus={(event) => handlePopoverOpen(event, 'passwordPopOver')}
            onChange={(event) => {
              setPasswordCriteria(checkPasswordCriteria(event.target.value.toString()));
              register('password').onChange(event);
            }}
            onBlur={handlePopoverClose}
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='password help'
                    onMouseOver={(event) => handlePopoverOpen(event, 'passwordPopOver')}
                    onMouseOut={handlePopoverClose}>
                    <HelpOutlineIcon />
                  </IconButton>
                  <Popper
                    id='password-popover'
                    placement='right'
                    open={open.passwordPopOver}
                    anchorEl={anchorEl.passwordPopOver}
                  >
                    <Box
                      sx={{
                        backgroundColor: COLORS.White,
                        padding: '12px',
                        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.3)',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography variant='subtitle2' gutterBottom>
                        Password Criteria
                      </Typography>
                      <List
                        sx={{
                          listStyleType: 'disc',
                          pl: 3,
                          margin: 0,
                        }}
                      >
                        {passwordCriteria.length > 0 &&
                          passwordCriteria.map((condition: any) => {
                            return (
                              <ListItem
                                key={condition.name}
                                sx={{
                                  display: 'list-item',
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <Typography
                                  variant='body2'
                                  display='block'
                                  sx={{
                                    color: errors.password && !condition.isValid ? COLORS.Error : COLORS.Text,
                                  }}
                                >
                                  {condition.message}
                                </Typography>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </Popper>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor='confirmPwd'>Confirm New Password</InputLabel>
          <TextField
            fullWidth
            placeholder='Retype your password'
            type={showPassword ? 'text' : 'password'}
            id='confirmPwd'
            autoComplete='confirmPassword'
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword ? errors.confirmPassword?.message : ''}
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton loading={isLoading} variant='contained' type='submit' fullWidth sx={{ mt: 2, mb: 4 }}>
            Set Password
          </LoadingButton>
        </Grid>
        <Grid container justifyContent='center'>
          <Grid item>
            <Typography variant='body1'>
              Try another way?
              <Link href={ROUTE.LOGIN}> Go back</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
