import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import { API_URL } from 'shared/api/Constants';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//model component
const ModelDialog = (props: any) => {
  //states
  const [pageDataTitle, setPageDataTitle] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');

  //useeffect method
  useEffect(() => {
    // Make a GET request using Axios
    axios
      .get(`${API_URL.CONDITION_AND_POLICY}/${props.openModel.id}`)
      .then((response) => {
        setPageDataTitle(response.data.title.rendered); // Update the state with the received data

        setHtmlContent(response.data.content.rendered);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.openModel.id]); // array ensures the effect runs  on component mount

  //close dialog
  const handleClose = () => {
    props.handleClosemodel(false);
  };

  // return Dialog component
  return (
    <>
      <Dialog open={props.openModel.open && pageDataTitle} onClose={handleClose} scroll='body'>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={10} sm={10}>
            <DialogTitle id='scroll-dialog-title'>
              <Typography id='modal-modal-title' variant='h2' component='h2'>
                {pageDataTitle}
              </Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={2} sm={2} spacing={1}>
            <IconButton
              aria-label='Close'
              className='close-icon float-right pt-0 pr-0 pl-0 pb-0'
              style={{ marginTop: '12px' }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <DialogContent dividers={true} className={'grid-column-scroll'}>
              <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
              </DialogContentText>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ModelDialog;
