/* eslint-disable indent */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from 'shared/api/Constants';
import { ROUTE } from 'route/RouteEnums';
import 'react-toastify/dist/ReactToastify.css';
import { memoizedRefreshToken } from './refreshToken';

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json; charset=utf-8',
};

export enum StatusCode {
  NotFound = 404,
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  BadRequest = 400,
}

class Interceptor {
  private instance: AxiosInstance | null = null;

  private get axiosInstance(): AxiosInstance {
    return this.instance ?? this.initInterceptor();
  }

  initInterceptor() {
    const createInstance = axios.create({
      baseURL: BASE_URL,
      headers,
      withCredentials:false
    });

    const injectToken = (config: any) => {
      try {
        if (sessionStorage) {
          const token = sessionStorage.getItem('access-token');
          if (token != null && config.headers) {
            config.headers = {
              Authorization: `Bearer ${JSON.parse(token)}`,
              'Content-Type': 'application/json; charset=utf-8',
              Accept: 'application/json; charset=utf-8',
            };
          }
        }
        return config;
      } catch (_error) {
        return config;
      }
    };

    createInstance.interceptors.request.use(
      (config) => {
        const configuration = injectToken(config);
        return configuration;
      },
      (error) => {
        console.log('axios req err -', error);
        return Promise.reject(error);
      }
    );

    createInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { response } = error;
        return this.handleError(response);
      },
    );

    this.instance = createInstance;
    return createInstance;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.axiosInstance.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axiosInstance.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.axiosInstance.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.axiosInstance.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axiosInstance.delete<T, R>(url, config);
  }

  private async handleError(error: AxiosError & { status1?: number }) {
    const  {status} = error;
    switch (status) {
      case StatusCode.InternalServerError: {
        break;
      }
      case StatusCode.Forbidden: {
        break;
      }
      case StatusCode.Unauthorized: {
        // refresh token
        const token = await memoizedRefreshToken();
        const config:any = error?.config;
        if (token && config !== undefined) {
          config.headers = {
            Authorization:`Bearer ${token}`,
            'Content-Type': 'application/json; charset=utf-8'
          };
          return axios(config); 
        } else {
          sessionStorage.clear();
          window.location.href = ROUTE.LOGIN;
        }
        break;
      }
      case StatusCode.NotFound: {
        break;
      }
      case StatusCode.TooManyRequests: {
        break;
      }
      case StatusCode.BadRequest: {
        break;
      }
      default: {
        break;
      }
    }
    return Promise.reject(error);
  }
}

export const apiInterceptor = new Interceptor();
