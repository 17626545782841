import React from 'react';
import {
  Box,
  Container,
  Grid,
  //InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

function Step2(props: any) {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  const handleOnBlur=(e:any)=>{
    console.log(e.target.value);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1" textAlign={'center'}>
          Please enter your child&apos;s zip code below
        </Typography>
        <Typography
          variant="subtitle1"
          display="block"
          gutterBottom
          textAlign={'center'}
          sx={{
            fontWeight: 400,
            color: '#475467',
          }}
        >
          This helps us determine if our services are available in your area.
        </Typography>

        <Grid
          container
          display={'flex'}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          
          <Controller
            control={control}
            name="zipCode"
            rules={{
              required: true,
              pattern: {
                value: /^[0-9]{5}$/i,
                message: 'Zipcode length should be 5 digits',
              },
            }}
            render={({ field }: any) => {
              return (
                <>
                  <TextField
                    fullWidth
                    id="where-are-you-located?"
                    autoComplete="zip-code"
                    placeholder="Zip Code"
                    onChange={(data: any) => field.onChange(data)}
                    onBlur={(data:any) => handleOnBlur(data)}
                    error={!!errors.zipCode}
                    helperText={
                      errors?.zipCode
                        ? errors?.zipCode?.message?.toString()
                        : ''
                    }
                  />
                </>
              );
            }}
          />
        </Grid>
      </Box>
    </Container>
  );
}

export default Step2;
