import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAppointmentHistoryAPI } from 'services/appointmentHistoryService';
import { handleMessage } from 'shared/api/ErrorMessages';


const initialState = {
  loaders: {
    isAppointmentHistoryRequested: false,
  },
  error: undefined,
};

interface StateI {
  loaders: Loader;
  error: undefined;
}

interface Error {
  data: any;
}

interface Loader {
  isAppointmentHistoryRequested: boolean;
}

export const getAppointmentHistory = createAsyncThunk(
  'GET_APPOINTMENT_HISTORY',
  async (patientId: any, { rejectWithValue }: any) => {
    try {
      const response = await getAppointmentHistoryAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

const appointmentHisrotySlice = createSlice({
  name: 'appointmentHistory',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getAppointmentHistory.pending, (state: StateI) => {
      state.loaders.isAppointmentHistoryRequested = true;
    });
    builder.addCase(getAppointmentHistory.fulfilled, (state: StateI, action: any) => {
      state.loaders.isAppointmentHistoryRequested = false;
    });
    builder.addCase(getAppointmentHistory.rejected, (state: StateI) => {
      state.loaders.isAppointmentHistoryRequested = false;
    });
  },
});

export default appointmentHisrotySlice.reducer;