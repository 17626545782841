import { Box, Container } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        mt: 'auto',
        zIndex: '1201',
      }}
    >
      <Container maxWidth="lg">
        <Box>&copy; MindWeal Health, PLLC {currentYear}</Box>
      </Container>
    </Box>
  );
};

export default Footer;
