import { Container, Typography, Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import { ROUTE } from 'route/RouteEnums';
import { accountConfirmationAPI } from 'services/authServices';

const EmailVerified = (props: any) => {
  const { token } = useParams();
  const [msg, setMsg] = useState('Verifying your email. This won\'t take long, thank you.');
  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      accountConfirmationAPI(token, (response: any) => {
        if (response.status === 200) {
          setMsg('Your email is verified, please click on sign in to continue with onboarding process.');
          setVerified(true);
        } else if (response?.data?.errorCode === 2002) {
          setMsg('Token is expired or it is already used. Try Sign in.');
        } else if (response?.data?.errorCode === 1000) {
          setMsg('Invalid token');
        }
      });
    }
  }, [token]);

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {verified ? <img src='../icon-96x96.png' alt='mindweal-logo' /> : null}
        {verified && <Typography variant='h1' gutterBottom >
          Email Verification
        </Typography>}
        <Typography variant='subtitle1' gutterBottom >
          {msg}
        </Typography>
        {!verified ? <span style={{ height: '30%' }}><Loader /></span> : null}
        <Grid item xs={6} display='flex' direction='column' alignContent='center'>
          <Button
            fullWidth
            variant='outlined'
            sx={{
              mt: 2,
              mb: 2,
              mx: 4,
              py: '10px',
              px: '24px',
              minWidth: '266px',
              maxWidth: '266px',
            }}
            href={ROUTE.HOME}
          >
            Back to Website
          </Button>
          {verified ?
            <Button
              fullWidth
              sx={{
                mt: 2,
                mb: 2,
                mx: 4,
                py: '10px',
                px: '24px',
                minWidth: '266px',
                maxWidth: '266px',
              }}
              href={ROUTE.LOGIN}
            >
              Sign in
            </Button> : null}
        </Grid>
      </Box>
    </Container>
  );
};

export default EmailVerified;
