import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Box, CardMedia, Link, Typography, useMediaQuery } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { toast } from 'react-toastify';
import * as FaceSDK from 'faceplugin';
interface DragAndDropUploaderProps {
  onFileUpload: (files: File, side: string) => void;
  side?: any;
  disable?: boolean;
  profileImage?:boolean;
  profilePicture?:any;
}

const FileUploader: React.FC<DragAndDropUploaderProps> = ({ onFileUpload, side, disable,
  profileImage, profilePicture }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [droppedImage, setDroppedImage] = useState<string | null>(null);
  const [mode,setMode] = useState(0);
  const [detectSession,setDetectSession] = useState(null);
  const [canvasImg] = useState<any>();

  const canvas:any = useRef();


  const draw = () => {    
    const context = canvas?.current?.getContext('2d');
    const img1 = new Image();
    img1.onload = function () {
      context.drawImage(img1, 0, 0, 640, 480);
    };
    img1.src = canvasImg;
  };

  useEffect(() => {
    draw();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasImg]);

  useEffect(() => {
    loadModels();
    draw();
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadModels = async () => {
    await FaceSDK.load_opencv();
    const detectSession = await FaceSDK.loadDetectionModel();
    setDetectSession(detectSession);
  };

  
  const onDrop = useCallback((acceptedFiles: any, rejection:any) => {
    if (acceptedFiles.length > 0) {
      if (profileImage) {
        const canvasElement = document.getElementById('live-canvas');
        if (canvasElement !== null) {
          const ctx = (canvasElement as HTMLCanvasElement).getContext('2d');
          const img = new Image();
          img.src = URL.createObjectURL(acceptedFiles[0]);
          img.onload = function () {
            ctx?.drawImage(img, 0, 0, 400, 300);
          };

          draw();
          setTimeout(async () => {
            setMode(0);
            const detectionResult = await FaceSDK.detectFace(detectSession, 'live-canvas');
            const bbox = detectionResult.bbox;
            const face_count = bbox.shape[0];
            if (face_count > 0) {
              const file = acceptedFiles[0];
              const reader = new FileReader();
              reader.onload = () => {
                setDroppedImage(reader.result as string);
                setUploadedFile(file);
              };
              reader.readAsDataURL(file);
              onFileUpload(acceptedFiles, side);
            } else {
              toast('Please upload image with clear face', {
                type: 'error',
                icon: true,
              });
            }
          }, 100);
        }
      }
      else {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          setDroppedImage(reader.result as string);
          setUploadedFile(file);
        };
        reader.readAsDataURL(file);
        onFileUpload(acceptedFiles, side);
        onFileUpload(acceptedFiles, side);
      }
    } else if(rejection.length ){
      if(rejection[0].errors[0].code === 'file-invalid-type'){
        toast('File type must be one of jpg, jpeg or png', {
          type: 'error',
          icon: true,
        });
      } else if (rejection[0].errors[0].code === 'file-too-large') {
        toast( profileImage ? 'Please upload file with size 1 MB or below' :
          'Please upload file with size 10 MB or below', {
          type: 'error',
          icon: true,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage, detectSession, onFileUpload, side]);

  const formatFileSize = (size: number): string => {
    const kbSize = size / 1024;
    return `${kbSize.toFixed(2)} KB`;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/png': [],
      'image/jpeg': []
    },
    maxSize: profileImage ? 1000000 : 10000000,  // size in bytes
    disabled: disable
  });
  
  const isSm = useMediaQuery('(max-width:786px)');

  return (
    <Box
      {...getRootProps()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: uploadedFile ? 'start' : 'center',
        border: !profileImage ? '0.5px solid #3467FF' : '0px',
        borderRadius: '12px',
        background: isDragActive ? '#FFC44D' : 'transparent',
        minHeight: '57px',
      }}
    >
      {droppedImage &&
        profileImage ?
        <img src={droppedImage} alt="Dropped"
          style={{
            maxWidth: isSm ? '70px' : '100px',
            height: isSm ? '70px' : '100px',
            width: isSm ? '70px' : '100px',
            borderRadius: '50%',
            border:  '1px solid #333' 
          }} />
        :
        droppedImage &&
        <img src={droppedImage} alt="Dropped"
          style={{
            maxWidth:  300,
            height:  'auto',
            width: '100%',
            borderRadius:  0,
            border: 'none'
          }} />

      }
      <canvas ref={canvas} id="live-canvas" height={480} width={640}  style={{display:'none'}}/>
            
      {uploadedFile && !profileImage ? (
        <>
          <Typography variant="body1" gutterBottom sx={{ textAlign: 'start' }}>
            {uploadedFile.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatFileSize(uploadedFile.size)} - 100% uploaded
          </Typography>
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          {!profileImage ?
            <>
              <CloudUploadOutlinedIcon />
              <Typography variant="body1" gutterBottom>
                <Link variant='body1' sx={{ cursor: 'pointer' }}>Click to upload</Link> or drag and drop
              </Typography>
              <Typography variant="body1" gutterBottom>
                  PNG, JPG (max. 10MB)
              </Typography>
            </> :
            droppedImage === null ?
              <CardMedia
                component={'img'}
                src={profilePicture ? `data:image/png;base64, ${profilePicture}` : '../images/Asset 28.png'}
                alt=''
                sx={{ border: '1px solid #333', borderRadius: '50%', height: {xs:'70px', md:'106px'},
                  width: {xs:'70px', md:'106px'} }}
              />
              : null
            
          }
        </>
      )}
    </Box>
  );
};

FileUploader.propTypes = {};

export default FileUploader;
