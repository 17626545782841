import { Outlet } from 'react-router-dom';
import { Grid, CardMedia, Box, Container } from '@mui/material';
import PageHeader from './Header';
import PageFooter from './Footer';

const PageLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <PageHeader />
      <Container
        component='main'
        maxWidth='xl'
        sx={{
          height: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid sx={{ mb: 16 }} spacing={0} container direction='row' justifyContent='center' alignItems='center'>
          <Grid xs={12} md={6} item>
            <Outlet />
          </Grid>
          <Grid display={{ xs: 'none', md: 'block' }} md={6} justifyContent={'center'} item>
            <CardMedia component='img' image={require('../assets/images/image.png')} alt='image' />
          </Grid>
        </Grid>
      </Container>
      <PageFooter />
    </Box>
  );
};

export default PageLayout;
