import { Box, GlobalStyles } from '@mui/material';
import Footer from './AuthFooter';
import Header from './AuthHeader';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'utils/AuthContext';
import { ROUTE } from 'route/RouteEnums';
import COLORS from 'theme/colors';

const AuthLayout = () => {
  const auth = useAuth();

  return (
    <>
      {auth.isAuthenticated ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          {/* only for dashboard layout bg color */}
          <GlobalStyles
            styles={{
              body: { backgroundColor: COLORS.LightYellow },
            }}
          />
          <Header />
          <Outlet />
          <Footer />
        </Box>
      ) : (
        <Navigate to={{ pathname: ROUTE.LOGIN }} />
      )}
    </>
  );
};

export default AuthLayout;
