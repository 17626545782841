import React, { useState } from 'react';
import { Alert, Box, Grid, InputLabel, Link, TextField, Typography, Zoom } from '@mui/material';

import { ROUTE } from 'route/RouteEnums';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordSchema } from 'utils/schema';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { sendResetPasswordEmailAPI } from 'services/authServices';

const ForgotPassword = (props: any) => {
  const [displayMessage, setDisplayMessage] = useState(false);
  const [displayMigrationMessage, setDisplayMigrationMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmitForm = (formData: any) => {
    setIsLoading(true);
    sendResetPasswordEmailAPI(formData.email, (response: any) => {
      setIsLoading(false);
      if(response?.data?.errorCode === 2003){
        setDisplayMigrationMessage(true);
      }else{
        setDisplayMessage(true);
      }
      setTimeout(() => {
        setDisplayMessage(false);
        setDisplayMigrationMessage(false);
        navigate(ROUTE.LOGIN);
      }, 15000);
    });
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit((formData) => onSubmitForm(formData))}
      sx={{ mr: { md: 8, lg: 18 }, ml: { md: 3, lg: 6 } }}
    >
      <Typography variant='h1'>Forgot Password?</Typography>
      <Typography variant='subtitle1'>
        Please enter your registered email address to receive a verification code.
      </Typography>
      {displayMessage && (
        <Zoom in={true}>
          <Alert severity='info'>{`If you are registered with MindWeal,
            we have sent you an email with instructions to reset your password.
            If you are not registered, you can`} <Link href={ROUTE.SIGNUP}>
              sign up here</Link></Alert>
        </Zoom>
      )}
      {displayMigrationMessage && (
        <Zoom in={true}>
          <Alert severity='info'>{'If you you an existing BanCaps user, please'}
            <Link href={ROUTE.MIGRATION_SIGNUP}> activate your MindWeal account here. </Link>
           Do not sign up as a new patient or reset your password until you&apos;ve activated your account.
          </Alert>
        </Zoom>
      )}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <InputLabel htmlFor='email'>Email</InputLabel>
          <TextField
            margin='normal'
            fullWidth
            id='email'
            placeholder='Enter your email'
            autoComplete='email'
            autoFocus
            {...register('email')}
            error={!!errors.email}
            helperText={errors?.email?.message}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton loading={isLoading} variant='contained' type='submit' fullWidth sx={{ mt: 2, mb: 4 }}>
            Get Verification link
          </LoadingButton>
        </Grid>
        <Grid container justifyContent='center'>
          <Grid item>
            <Typography component='span' variant='body1'>
              Try another way?
              <Link href={ROUTE.LOGIN}> Go back</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
ForgotPassword.propTypes = {};

export default ForgotPassword;
