import { useEffect, useState, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-material-ui';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

import { ROUTE } from 'route/RouteEnums';
import { checkPasswordCriteria, singupSchema } from 'utils/schema';
import { migrateAPI, signUpAPI, validateCaptchaAPI } from 'services/authServices';
import COLORS from 'theme/colors';
import { hearAboutUs } from 'utils/constants';
import ModelDialog from 'components/ModelDialog';
import PinInput from 'react-pin-input';
import { captchaKey } from 'shared/content/constants';

export default function SignUp(props: any) {
  const [openModel, setOpenModelState] = useState({ open: false, id: '' });
  const [anchorEl, setAnchorEl] = useState({
    passwordPopOver: null,
    mobilePopOver: null,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState<any>([]);
  const [captchaValidity, setCaptchaValidity] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [pin, setPin] = useState<string>();
  const [showPinConfirm, setShowPinConfirm] = useState<boolean>(false);
  const [confirmPin, setConfirmPin] = useState<string>();
  const [error, setError] = useState<string>('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>(true);
  const [signupData,setSignupData] = useState<any>();
  const [isMigrated, setIsMigrated] = useState<boolean>(false);

  const navigate = useNavigate();
  let ele:PinInput | null;

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
      referral: '',
      enableNotification: false,
      enableSMSNotification:false
    },
    resolver: yupResolver(singupSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    setPasswordCriteria(checkPasswordCriteria(getValues('password')));
  }, [getValues]);

  useEffect(() =>{
    if(window.location.pathname === '/migration/signup'){
      setIsMigrated(true);
    }
  },[]);

  const getSignUpPayload = (data: any) => {
    const {
      registrationDetails: { relationship, zipCode, childBirthDate, enrolledInSchool, insurance },
    } = props;
    const { firstName, lastName, email, password, mobileNumber, enableNotification, enableSMSNotification } = data;
    return {
      firstName,
      lastName,
      email:email.toLowerCase(),
      password,
      mobileNumber: mobileNumber.slice(1),
      enableNotification, 
      enableSMSNotification, 
      registrationDetails: {
        childBirthDate,
        zipCode,
        enrolledInSchool: enrolledInSchool === 'yes' ? true : false,
        insurance,
        relationship,
        referral: data.referral,
      },
    };
  };

  const getPayloadForMigrated = (data: any) => {   
    const { firstName, lastName, email, password, mobileNumber } = data;
    return {
      firstName,
      lastName,
      email:email.toLowerCase(),
      password,
      mobileNumber: mobileNumber.slice(1),
      enableNotification:true, 
      enableSMSNotification:true
    };
  };

  const onSubmitForm = (formData: any) => {
    setShowDialog(true); 
    setSignupData(formData);
  };

  //open privacy and condition model
  const handleClickOpenModel = (e: any) => {
    setOpenModelState({ open: true, id: e.target.id });
  };

  //close privacy and condition model
  const handleCloseModel = () => {
    setOpenModelState({ open: false, id: '' });
  };

  const handlePopoverOpen = (event: any, elementName: string) => {
    setAnchorEl({ ...anchorEl, [elementName]: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setAnchorEl({ passwordPopOver: null, mobilePopOver: null });
  };

  const open = {
    passwordPopOver: Boolean(anchorEl.passwordPopOver),
    mobilePopOver: Boolean(anchorEl.mobilePopOver),
  };

  function onCaptchaChange(token:any) {
    if(token !== null){
      validateCaptchaAPI(token).then((response:any) =>{
        if(response.status === 200){
          if(response.data){
            setCaptchaValidity(true);
          } else{
            setCaptchaValidity(false);
          }
        }
      }); 
    }
  }

  const handleOnComplete=(value:any)=>{
    if(!showPinConfirm){
      setPin(value);
      setDisableNextButton(false);
    } else {
      setConfirmPin(value);
      setDisableConfirmButton(false);
    }    
  };

  const handleNext=()=>{
    setShowPinConfirm(true);
    if(ele){ ele.clear();}
  };

  const handleConfirmPin=()=>{
    if(pin === confirmPin){
      let body:any; 
      if(!isMigrated){
        body = getSignUpPayload(signupData);
      }
      else {
        body = getPayloadForMigrated(signupData);
      }
      const data = {...body, pin};  
      setShowDialog(false);  
      setPin('');
      setShowPinConfirm(false);    
      if(!isMigrated){
        signUpAPI(data, (response: any) => {
          if (response.status === 201) {       
            navigate(ROUTE.EMAILVERIFICATION, { state: { email: body.email } });     
          }
          else if (response?.data?.errorCode === 1002) {
            toast('This email id is already registered', {
              type: 'info',
              icon: true,
            });
          }
        });
      } else{
        migrateAPI(data, (response:any) =>  {
          if (response.status === 201) {       
            toast('PIN Set Up successfully!',{
              type:'success',
              icon:true
            });
            navigate(ROUTE.LOGIN);
          }
          else if (response?.data?.errorCode === 1010) {
            setShowPinConfirm(false);
            toast('Please use the same email address registered with BanCAPs to complete your MindWeal setup', {
              type: 'warning',
              icon: true,
            });
          }
          else if (response?.data?.errorCode === 1009) {
            setShowPinConfirm(false);
            toast('You have already registered on MindWeal successfully, please Log In', {
              type: 'info',
              icon: true,
            });
            navigate(ROUTE.LOGIN);
          } else{
            toast('We are having trouble completing your request right now. Please try again shortly.', {
              type: 'error',
              icon: true,
            });
          }         
        });
      }
    }else{
      setError('PIN does not match');      
      setTimeout(() =>{
        setError('');
      }, 3000);
      if(ele){ ele.clear();}
    }
  };

  const handleBack=()=>{
    setShowPinConfirm(false);
    setShowDialog(true);
    setError('');
    if(ele){ ele.clear();}
  };

  const handlePinChange=(val:any)=>{
    if(val.length < 6){
      setDisableNextButton(true);
      setDisableConfirmButton(true);
    }
  };

  return (
    <Container component='main' maxWidth='sm'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CardMedia
          component='img'
          image={!isMigrated ? 'icon-96x96.png' : '../icon-96x96.png'}
          sx={{
            height: 'auto',
            width: { xs: '35px', md: '47px' },
          }}
          alt='image'
        />
        <Typography variant='h1' textAlign={'center'}>
          {isMigrated ? 'Activate your MindWeal account as a BanCAPs user' : 'Create an Account'}</Typography>
        <Alert severity='info'>
          IMPORTANT: Please enter primary guardian&apos;s details. Primary guardian is the one who will log into the app
          using the information entered below. This information will also be used as our primary contact for all patient
          needs.
        </Alert>
        <Box
          component='form'
          noValidate
          onSubmit={handleSubmit((formData: any) => onSubmitForm(formData))}
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='firstName'>First Name of Guardian*</InputLabel>
              <TextField
                autoComplete='first-name'
                placeholder='Enter your First Name'
                required
                fullWidth
                id='firstName'
                autoFocus
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName?.message : ''}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='lastName'>Last Name of Guardian*</InputLabel>
              <TextField
                required
                fullWidth
                placeholder='Enter your Last Name'
                id='lastName'
                autoComplete='last-name'
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName?.message : ''}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='email'>Email of Guardian*</InputLabel>
              <TextField
                required
                fullWidth
                placeholder='Enter your email'
                id='email'
                autoComplete='email'
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email ? errors.email?.message : ''}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='phone'>Cell Ph. No. of Guardian*</InputLabel>
              <ReactPhoneInput
                component={TextField}
                label=''
                onlyCountries={['us']}
                country={'us'}
                masks={{ us: '(...) ...-....' }}
                autoFormat={true}
                disableDropdown={true}
                defaultErrorMessage={'Please enter a phone number'}
                {...register('mobileNumber')}
                inputProps={{
                  helperText: errors.mobileNumber ? errors.mobileNumber?.message : '',
                  error: !!errors.mobileNumber,
                }}
                countryCodeEditable={false}
                onChange={(e) => {
                  setValue('mobileNumber', e, { shouldValidate: true });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='phone'>Password*</InputLabel>

              <TextField
                type='password'
                required
                fullWidth
                placeholder='Enter your password'
                id='password'
                autoComplete='password'
                {...register('password')}
                error={!!errors.password}
                helperText={errors.password ? errors.password?.message : ''}
                onFocus={(event) => handlePopoverOpen(event, 'passwordPopOver')}
                onChange={(event) => {
                  setPasswordCriteria(checkPasswordCriteria(event.target.value.toString()));
                  register('password').onChange(event);
                }}
                onBlur={handlePopoverClose}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton
                        area-aria-owns='password-popover'
                        aria-haspopup='true'
                        onMouseOver={(event) => handlePopoverOpen(event, 'passwordPopOver')}
                        onMouseOut={handlePopoverClose}
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                      <Popper
                        id='password-popover'
                        placement='auto-start'
                        open={open.passwordPopOver}
                        anchorEl={anchorEl.passwordPopOver}
                      >
                        <Box
                          sx={{
                            backgroundColor: COLORS.White,
                            padding: '12px',
                            boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.3)',
                            borderRadius: '8px',
                          }}
                        >
                          <Typography variant='subtitle2' gutterBottom>
                            Password Criteria
                          </Typography>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              pl: 3,
                              margin: 0,
                            }}
                          >
                            {passwordCriteria.length > 0 &&
                              passwordCriteria.map((condition: any) => {
                                return (
                                  <ListItem
                                    key={condition.name}
                                    sx={{
                                      display: 'list-item',
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography
                                      variant='body2'
                                      display='block'
                                      sx={{
                                        color: errors.password && !condition.isValid ? COLORS.Error : COLORS.Text,
                                      }}
                                    >
                                      {condition.message}
                                    </Typography>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </Box>
                      </Popper>
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='confirmPwd'>Confirm Password*</InputLabel>
              <TextField
                required
                fullWidth
                placeholder='Retype your password'
                type={isVisible ? 'text' : 'password'}
                id='confirmPwd'
                autoComplete='new-password'
                {...register('confirmPassword')}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword?.message : ''}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setIsVisible(!isVisible)}>
                      {isVisible ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            {!isMigrated ? 
              <Grid item xs={12}>
                <InputLabel htmlFor='how-did-you-hear-about-mindweal?'>How did you hear about MindWeal?</InputLabel>
                <TextField
                  id='how-did-you-hear-about-mindweal?'
                  select // tell TextField to render select
                  fullWidth
                  {...register('referral')}
                  error={!!errors.referral}
                  helperText={errors.referral ? errors.referral?.message : ''}
                >
                  {hearAboutUs.map((item) => (
                    <MenuItem key={1} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> : ''}
            <Grid item xs={12}>
              <FormControlLabel
                {...register('enableNotification')}
                control={<Checkbox {...register('enableNotification')} checked={watch('enableNotification')} />}
                label='I agree to receive notifications from MindWeal.'
              />     
              <FormControlLabel
                {...register('enableSMSNotification')}
                control={<Checkbox {...register('enableSMSNotification')} checked={watch('enableSMSNotification')} />}
                label='I agree to receive SMS text messages from MindWeal.'
              />         
            </Grid>
          
            
            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey={captchaKey}
                onChange={onCaptchaChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component='span' variant='body2'>
                By creating an account, you accept our{' '}
                <Link id='39' variant='body2' underline='none' onClick={handleClickOpenModel} 
                  style={{cursor:'pointer'}}>
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link id='37' variant='body2' underline='none' onClick={handleClickOpenModel}
                  style={{cursor:'pointer'}}>
                  Privacy Policy
                </Link>
                .
              </Typography>
              {openModel.open && openModel.id && (
                <ModelDialog openModel={openModel} handleClosemodel={handleCloseModel} />
              )}
            </Grid>   
          </Grid>
          
          <Button type='submit' disabled={!isValid || !captchaValidity} 
            fullWidth variant='contained' sx={{ mt: 2, mb: 2 }}>
            Submit
          </Button>
          <Button type='submit' fullWidth variant='outlined' sx={{ mt: 2, mb: 2 }} href={ROUTE.HOME}>
            Back to Website
          </Button>
          <Grid container justifyContent='center'>
            <Grid item>
              <Typography component='span'>
                Already have an account?{' '}
                <Link href={ROUTE.LOGIN} variant='body2'>
                  {' '}
                  Log in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog  aria-labelledby="simple-dialog-title" 
        open={showDialog}>
        <Box sx={{ textAlign: 'right', m: '10px 20px 0 0' }}>
          <Typography sx={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={() => {setShowDialog(false); setShowPinConfirm(false);}}
          >X</Typography>
        </Box>
        <DialogTitle id="simple-dialog-title" sx={{padding:'16px 24px 0'}}>
          {!showPinConfirm ? 'Setup a Pin' : 'Confirm Your PIN'}</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          > 
            <Typography>
              {!showPinConfirm && showDialog? 
                'For security reason, please set up a 6-digit PIN for your account.' :
                'Re-enter your 6-digit PIN to confirm'}</Typography>
          
            <Grid item xs={3} mt={'20px'}>
              <PinInput 
                length={6} 
                focus 
                secret 
                secretDelay={500} 
                type='numeric'
                inputMode='numeric'
                onComplete={handleOnComplete}
                onChange={handlePinChange}
                inputStyle={{
                  border:'1px solid #FFC44D',
                  borderRadius:'8px',
                  margin:'10px',                  
                }}                
                ref={(n) => ele=n}
              />
            </Grid>
            <Typography sx={{color:'red'}}>{error}</Typography>
            <Grid container justifyContent="center" mt='10px'>
              {!showPinConfirm ?
                <Button onClick={handleNext} sx={{textAlign:'right'}} disabled={disableNextButton}> Next </Button> :
                <>
                  <Grid xs={12} sx={{m:'20px 0'}} textAlign={'center'}>
                    <Button onClick={handleConfirmPin} sx={{textAlign:'right'}} disabled={disableConfirmButton}>
                      Confirm</Button>
                  </Grid>
                  <Grid><Button variant='text' onClick={handleBack} sx={{textAlign:'right'}}> Back </Button></Grid>
                </>
              }
            </Grid>            
          </Grid>
        </DialogContent>
      </Dialog>
        
    </Container>
  );
}
