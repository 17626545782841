import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';

function Step4() {
  const { control } = useFormContext();
  const providers:any = useAppSelector((state) => state.signin.insuranceProviders);

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} sx={{ pt: 12.5 }}>
          <Grid item xs={12}>
            <Typography variant="h1" textAlign={'center'}>
              Who is your insurance provider?
            </Typography>
            <Typography
              variant="subtitle1"
              display="block"
              gutterBottom
              textAlign={'center'}
              sx={{
                fontWeight: 400,
                color: '#475467',
              }}
            >
              We partner with select insurers to guarantee our patients the highest quality care.
            </Typography>

            <Controller
              control={control}
              name="insurance"
              rules={{ required: true }}
              render={({ field }: any) => (
                <TextField
                  id="how-did-you-hear-about-mindweal?"
                  select
                  fullWidth
                  placeholder="Select your insurance provider"
                  onChange={(data: any) => field.onChange(data)}
                  defaultValue="select_your_insurance_provider"
                >
                  <MenuItem
                    key={-1}
                    value="select_your_insurance_provider"
                    disabled
                  >
                    Select your insurance provider
                  </MenuItem>
                  {providers.insurance.map((provider:any, index:number) => (
                    <MenuItem key={index + 1} value={provider}>
                      {provider}
                    </MenuItem>
                  ))}
                  <MenuItem key={0} value="others">
                    Other
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Step4;
