import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sanitize } from 'dompurify';
import COLORS from 'theme/colors';

interface MultiSelectProps {
  question: any;
  answers: any;
  handleNext: any;
  openModal: any;
}

const MultiSelectPrimary: React.FC<MultiSelectProps> = ({ question, answers, handleNext, openModal }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
  const [otherValue, setOtherValue] = useState<any>([{ id: null, disable: true, value: '' }]);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    if (question?.type !== 'optional') {
      if (selectedAnswers?.length > 0 || otherValue[1]?.value.length > 0) {
        setDisableNextButton(false);
      } else {
        setDisableNextButton(true);
      }
    }
    else {
      setDisableNextButton(false);
    }

  }, [otherValue, question.type, selectedAnswers]);

  const handleChange = async (e: any) => {
    const selected = [...selectedAnswers];
    if (e.target.checked) {
      const { id } = e.target;
      const val = parseInt(id);
      if (e.target.name === 'other') {
        const otherVal = [...otherValue];
        const newValues = { id: val, disable: false, value: '' };
        otherVal.push(newValues);
        setOtherValue(otherVal);
      } else if (e.target.name === 'exclusive') {
        setSelectedAnswers([{ id: val, type: 'exclusive' }]);
      }
      else {
        const selectedWithoutExclusive = selectedAnswers?.filter((x: any) => x.type !== 'exclusive');
        selectedWithoutExclusive.push({ id: val });
        setSelectedAnswers(selectedWithoutExclusive);
      }
    } else if (!e.target.checked) {
      const { id } = e.target;
      if (e.target.name === 'other') {
        const filterVal = otherValue?.filter((x: any) => x.id !== parseInt(id));
        setOtherValue(filterVal);
        setErrorMessage(false);
      } else if (e.target.name === 'exclusive') {
        setSelectedAnswers([]);
      }
      else {
        setSelectedAnswers(selected.filter((x: any) => x.id !== parseInt(id)));
      }
    }
  };

  const handleSubmit = () => {
    let answers = [...selectedAnswers];

    otherValue?.length > 0 && otherValue?.map((o: any) => {
      if (o.id !== null && o.value !== '') {
        const newObj = { id: o.id, input: o.value };
        answers.push(newObj);
      }
      return null;
    });

    if (question.type === 'optional' && selectedAnswers[1]?.id === '') {
      answers = [];
    }
    const answerDetails = {
      quizId: question.questionId,
      answers: answers,
      launchType: question.launchType
    };
    handleNext(answerDetails);
    setSelectedAnswers([]);
    setOtherValue({ id: null, disable: true, value: '' });
  };

  const handleBlur = (e: any) => {
    if (e.target.value.length === 0) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleOtherValue = (e: any, id: any) => {
    const val = [...otherValue];
    val.forEach((v: any) => {
      if (v.id === id) {
        v.value = e.target.value;
      }
      return null;
    });
    setOtherValue(val);
    if (e.target.value.length > 0) {
      setDisableNextButton(false);
    }
  };

  const handleChecked = (id: any, type: string) => {
    const len = selectedAnswers?.filter((s: any) => {
      return s.id === id;
    });
    const otherValLen = Array.isArray(otherValue) && otherValue?.filter((s: any) => {
      return s.id === id;
    });
    if (len.length === 1 || Object.keys(otherValLen).length === 1) {
      return true;
    }
    else {
      return false;
    }
  };

  const handleGridClick = (e: any, obj: any) => {
    e.preventDefault();
    const data = {
      target: {
        id: obj.id,
        name: obj.type,
        checked: obj.type === 'other' ? otherValue.filter((o: any) => o.id === obj.id).length === 1 ? false : true :
          selectedAnswers.filter((s: any) => s.id === obj.id).length === 1 ? false : true
      }
    };
    handleChange(data);
  };

  const handleTextFiledClick = (e: any) => {
    e.stopPropagation();
    return false;
  };

  const handleBackButton=()=>{
    const obj = { event : 'back'};
    handleNext(obj);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#3467ff' : '#308fe8',
    },
  }));

  return (
    <Grid container maxWidth='100%'>
      <Box sx={{position: {xs:'inherit', md:'absolute'}, left:'20px', top:'130px'}} >
        <Button 
          sx={{ background: COLORS.LightBlue, minWidth: 140, mb:1  }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => handleBackButton()}  
          disabled={question?.event === 'back'? true : false}
        >Back</Button>
      </Box>
      <Grid item xs={12}>
        <BorderLinearProgress  value={question?.progress} variant='determinate'/>
        {question?.header !== null ? question.header : null}
        
        <Container sx={{ background: '#fff', padding: '20px 40px', borderRadius: '12px' }}>
          <Typography sx={{ color: '#6941C6', fontSize: '18px', fontWeight: 500, mb: '24px', lineHeight:'28px' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.heading) }} /></Typography>

          <Typography mb={3}
            sx={{ lineHeight: '28px', mt: '5px', mb:'18px', 
              fontSize:'20px', fontWeight:500, color:'#2C3446', fontStyle:'italic' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.prompt) }} /> </Typography>

          <Typography mb={3} 
            sx={{ lineHeight: '28px', mt: '5px', mb:'6px', fontSize:'20px', fontWeight:500, color:'#2C3446' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.question) }} /></Typography>

          {question?.informationSection && question?.informationSection !== null ? (
            <Box mb={6}>
              <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}> 
                <div dangerouslySetInnerHTML={{ __html: sanitize(question?.informationSection) }} /> </Alert>
            </Box>
          ) : null}  

          {question.info !== null ? (           
            <Box sx={{ background: '#F8F5FF', p:'8px 12px', alignSelf:'stretch', borderRadius:'8px', mb:'48px' }}>
              <Grid container>
                <Grid item xs={0.5}>
                  <InfoOutlinedIcon fontSize='small' color='primary' sx={{ mr: '10px' }} />
                </Grid>
                <Grid item xs={11.5} mt='2px'>
                  <Typography color='#1C1F24'>
                    <div dangerouslySetInnerHTML={{ __html: sanitize(question?.info) }} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {question?.instruction !== null ?
            <Typography mb={3} sx={{ fontWeight: 400, fontSize:'18px', color:'#2C3446' }}>
              <div dangerouslySetInnerHTML={{ __html: sanitize(question?.instruction) }} /></Typography>
            : null}

          <Grid container item xs={12} direction='column'>
            {answers?.length > 1 ?
              answers?.map((a: any) => {
                return (
                  <div key={a.text}>
                    <Typography mt={2} variant='h2' fontSize={20} mb={3} color='#1C1F24'><b>{a.text}</b></Typography>
                    {a?.answers?.map((ans: any, i: number) => {
                      return (
                        <div key={ans.text} onClick={(e) => handleGridClick(e, ans)}>
                          <Grid item xs={12}
                            sx={{
                              background: '#F9F9FB',
                              borderRadius: '8px',
                              display: { sm: 'flex' },
                              justifyContent: 'space-between',
                              marginBottom: '5px',
                              border: selectedAnswers.filter((x:any) => x.id === ans.id).length ? 
                                '1px solid #9F84FF' : 'none' 
                            }}
                          >
                            
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={ans.id.toString()}
                                  onChange={handleChange}
                                  inputProps={{ 'aria-label': 'controlled', }}
                                  value={ans.text}                                  
                                  sx={{ marginLeft: '30px',                                   
                                    '&.Mui-checked': {                                     
                                      '&, & + .MuiFormControlLabel-label': {
                                        color: '#9F84FF',
                                        fontSize:'16px'
                                      },                                    
                                    },
                                    '&, & + .MuiFormControlLabel-label':{
                                      fontSize:'16px',                                      
                                    }
                                  }}
                                  name={ans.type}
                                  checked={handleChecked(ans.id, ans.type)}                         
                                />
                              }
                              label={ <Typography sx={{color:'#1C1F24', fontSize:'16px' }}>
                                <div dangerouslySetInnerHTML={{ __html: sanitize(ans?.text) }} />
                              </Typography>}
                              labelPlacement='end'
                            />
                            {ans.info && ans.info !== null ? (
                              <Tooltip title={ans.info} arrow placement='bottom-start' enterTouchDelay={0}>
                                <InfoOutlinedIcon fontSize='small' 
                                  sx={{ position: 'relative', top: '10px', right: 4 }} />
                              </Tooltip>
                            ) : null}
                            {ans.type === 'other' ? (
                              <Box sx={{ width: { sm: '50%' }, position: 'relative' }}>
                                <TextField
                                  autoComplete='other'
                                  placeholder='Describe'
                                  fullWidth
                                  id='other'
                                  autoFocus
                                  multiline
                                  rows={1}
                                  sx={{ background: '#fff' }}
                                  inputProps={{ maxLength: 255 }}
                                  // eslint-disable-next-line max-len 
                                  disabled={otherValue?.length && otherValue?.filter((o: any) => { return o.id === ans.id; }).length === 1 ?
                                    otherValue[otherValue?.map((o: any) => o.id).indexOf(ans.id)]?.disable :
                                    otherValue[0]?.disable}
                                  // eslint-disable-next-line max-len
                                  value={otherValue?.length && otherValue?.filter((o: any) => { return o.id === ans.id; })?.length === 1 ?
                                    otherValue[otherValue?.length &&
                                    otherValue?.map((o: any) => o.id).indexOf(ans.id)]?.value : otherValue[0]?.value
                                  }
                                  onChange={(e) => handleOtherValue(e, ans.id)}
                                  onBlur={(e) => handleBlur(e)}
                                  onClick={(e) => handleTextFiledClick(e)}
                                />
                                {errorMessage ?
                                  otherValue?.length &&
                                otherValue.map((o: any) => {
                                  if (o.id === ans.id) {
                                    return (
                                      <Box key={o.id} sx={{ position: 'absolute', left: 0, right: 0, top: '100%' }} >
                                        <Alert severity='error' >Please describe </Alert>
                                      </Box>
                                    );
                                  }
                                  return null;
                                })
                                  :
                                  null}
                              </Box>
                            ) : null}
                          </Grid >
                        </div>
                      );
                    })}
                  </div>
                );
              })

              :
              answers[0]?.answers.map((ans: any, i: number) => {
                return (
                  <div key={ans.text} onClick={(e) => handleGridClick(e, ans)}>
                    <Grid item xs={12} key={i}
                      sx={{
                        background: '#F9F9FB',
                        borderRadius: '8px',
                        display: { sm: 'flex', xs: 'flex' },
                        justifyContent: 'space-between',
                        marginBottom: '5px',                      
                        border: selectedAnswers.filter((x:any) => x.id === ans.id).length ? 
                          '1px solid #9F84FF' : '1px solid #EEF0F6'                     
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={ans.id.toString()}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            value={ans.text}
                            name={ans.type}
                            sx={{ marginLeft: '30px',  
                                                                                                                 
                              '&.Mui-checked': {                              
                                '&, & + .MuiFormControlLabel-label': {
                                  color: '#9F84FF',   
                                  fontSize:'16px'                                                      
                                },
                                
                              },
                              '&, & + .MuiFormControlLabel-label':{
                                fontSize:'16px'
                              }
                            }}
                            checked={handleChecked(ans.id, ans.type)}
                          
                          />
                        }
                        label={<Typography sx={{color:'#1C1F24', fontSize:'16px'}}>
                          <div dangerouslySetInnerHTML={{ __html: sanitize(ans?.text) }} />
                        </Typography>}
                        labelPlacement='end'
                      />
                      {ans.info && ans.info !== null ? (
                        <Tooltip title={ans.info} arrow placement='bottom-start' enterTouchDelay={0}>
                          <InfoOutlinedIcon fontSize='small' sx={{ position: 'relative', top: '10px', right: 4 }} />
                        </Tooltip>
                      ) : null}
                      {ans.type === 'other' ? (
                        <Box sx={{ width: { sm: '50%' }, position: 'relative' }}>
                          <TextField
                            autoComplete='other'
                            placeholder='Describe'
                            fullWidth
                            id='other'
                            autoFocus
                            multiline
                            rows={1}
                            sx={{ background: '#fff' }}
                            inputProps={{ maxLength: 255 }}
                            disabled={otherValue?.length > 1 ? otherValue[1]?.disable : otherValue[0]?.disable}
                            value={otherValue?.length > 0 ? otherValue[1]?.value : ''}
                            onChange={(e) => handleOtherValue(e, ans.id)}
                            onBlur={(e) => handleBlur(e)}
                            onClick={(e) => handleTextFiledClick(e)}
                          />

                          {errorMessage ?
                            <Box sx={{ position: 'absolute', left: 0, right: 0, top: '100%' }}>
                              <Alert severity='error' > Please describe </Alert>
                            </Box> : null}
                        </Box>
                      ) : null}

                    </Grid>
                  </div>
                );
              })
            }
          </Grid>

         
          <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: errorMessage ? '50px' : '15px' }}>
            <Box sx={{ textAlign: { xs: 'center' } }} width='70%'>           
              <Button onClick={handleSubmit} disabled={disableNextButton} fullWidth>
              Next
              </Button>
            </Box>
          </Grid>        
        </Container >
        <Box sx={{ textAlign: { xs: 'center' } }} mt={4}> 
          <Button variant='outlined' sx={{ marginRight: '8px', marginBottom: { xs: 2, sm: 0 } }} onClick={openModal}>
              Continue Later
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultiSelectPrimary;
