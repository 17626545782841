import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'utils/AuthContext';
import { ROUTE } from 'route/RouteEnums';

const PermissionsProvider = ({ permissions }: { permissions: string[] }) => {
  const { user } = useAuth();
  const userPermissions = user?.permissions.length ? user?.permissions : [];
  /*check permissions with user permission*/
  const canAccess = userPermissions.some((userPermission: any) => permissions.includes(userPermission));

  return (
    <>
      {canAccess ? (
        <Outlet />
      ) : (
        /* In Case user not found so compare role which is assign at the time of signin */
        <Navigate
          to={{
            pathname: user?.isAdmin ? ROUTE.ADMIN_DASHBOARD : !user?.isAdmin ? ROUTE.DASHBOARD : ROUTE.LOGIN,
          }}
        />
      )}
    </>
  );
};

export default PermissionsProvider;
