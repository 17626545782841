import { API_URL } from 'shared/api/Constants';
import { apiInterceptor } from './axios';

export const scheduleAppointmentAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.SCHEDULE_APPOINTMENT}`, data).then((response: any) => {
    return response;
  });
};

export const getAppointmentDetailsAPI = () => {
  return apiInterceptor.get(`${API_URL.SCHEDULE_APPOINTMENT}`).then((response: any) => {
    return response;
  });
};

export const getAppointmentConfirmationDetailsAPI = () => {
  return apiInterceptor.get(`${API_URL.APPOINTMENT_CONFIRMATION_DETAILS}`).then((response: any) => {
    return response;
  });
};

export const getAppointmentDetailsAsPerPatientId = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.SCHEDULE_APPOINTMENT}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const getCheckInDetailsAPI = (data:any) => {
  return apiInterceptor.put(`${API_URL.CHECK_IN_DETAILS}/${data.patientId}/${data.status}`).then((response: any) => {
    return response;
  });
};


export const createPatientAPI = (id:any) => {
  return apiInterceptor.post(`${API_URL.CREATE_PATIENT}/${id}`).then((response: any) => {
    return response;
  });
};

export const getAddCardLinkAPI = () => {
  return apiInterceptor.get(`${API_URL.GET_STRIPE_LINK}`).then((response: any) => {
    return response;
  });
};

export const payAmountAPI = (patientId:any) => {
  return apiInterceptor.get(`${API_URL.PAY_AMOUNT}/${patientId}`).then((response: any) => {
    return response;
  });
};
