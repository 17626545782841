import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Log a warning and show a toast!
 */
export const globalErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (action?.payload?.show) {
      // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
      if (isRejectedWithValue(action)) {
        toast(action.payload.message, {
          position: 'top-center',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
          theme: 'dark',
          draggable: true,
          progress: undefined,
          style: {
            background: '#ffffff',
            borderRadius: '5px',
            border: '2px solid #B1C5B2',
            padding: '12px 15px',
            color: '#2B2D35',
            fontSize: '16px',
          },
        });
      }
    }

    return next(action);
  };
