import React, { useEffect } from 'react';
import { Container, Typography, Button, Box, useMediaQuery } from '@mui/material';
import { ROUTE } from 'route/RouteEnums';

import { resendEmailVerificationLinkAPI } from 'services/authServices';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const EmailVerification = (props: any) => {
  const isSm = useMediaQuery('(max-width:900px)');
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.email) navigate(ROUTE.LOGIN);
  });

  const resendEmailVerification = () => {
    resendEmailVerificationLinkAPI(state.email, (response: any) => {
      if (response.status === 200) {
        const message = 'Email verification link sent';
        toast(message, {
          type: 'success',
          icon: true
        });
      }
    });
  };

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={'images/Group 9441.png'}
          alt='bg-img'
          style={{
            position: 'fixed',
            bottom: '0',
            width: '636',
            height: 'auto',
            left: 0,
            zIndex: -1,
            display: isSm ? 'none' : 'block',
          }}
        />
        <img src='icon-96x96.png' alt='mindweal-logo' />
        <Typography variant='h1' gutterBottom >
          Verify your email
        </Typography>
        <Typography variant='subtitle1' gutterBottom >
          Please verify your email account by clicking on the link sent to your inbox.
        </Typography>
        <Button variant='text' onClick={() => resendEmailVerification()}>
          Resend link
        </Button>
        <Button variant='outlined' href={ROUTE.HOME} sx={{ mt: 2 }}>
          Back to Website
        </Button>
      </Box>
      { state?.email &&
      toast('PIN Set Up successfully!',{
        type:'success',
        icon:true
      })
      }
    </Container>
  );
};

EmailVerification.propTypes = {};

export default EmailVerification;
