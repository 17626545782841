import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';

const Navigate = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`${ROUTE.MCAT_ASSESSMENT}`, { state: { understand: true } });
  };

  return (
    <>
      <Grid item xs={1} display={'flex'} flexDirection={'row-reverse'} sx={{m:'20px 20px 0 0'}}>
        <Button variant='text' sx={{color:'#000'}}  onClick={() => navigate(`${ROUTE.DASHBOARD}`)}>X</Button>
      </Grid>
      <Container component='section' maxWidth='xl'
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} pb={2} maxWidth={'md'}>
          <CardMedia component={'img'} src='/images/mcat1.svg' alt='main_logo' sx={{ width: 82, height: 84 }} />
          <Typography variant='h1' textAlign={'center'} pt={4} pb={4}>
          Navigating M-CAT <br />
           Key Tips for Smooth Assessment    
          </Typography>

          <Box sx={{
            display:'flex',
            flexDirection:'column',
            padding:'30px 24px 24px 24px',
            alignItems:'flex-start',
            borderRadius:'8px',
            background:'#fff',
            boxShadow:'0px 4px 16px 0px rgba(0, 0, 0, 0.12)'
          }}>
            <Box sx={{fontSize:'18px', lineHeight:'28px'}}>
              <ul>
                <li style={{marginBottom:'20px'}}>
                  <b>Pause and Resume:</b> You&apos;re free to take breaks and continue the assessment 
                    when convenient. However, completion before your appointment is necessary.
                </li>
                <li style={{marginBottom:'20px'}}>
                  <b>Navigating Unclear Questions:</b> Some questions may seem vague. In those instances, 
                use your best judgment based on your understanding of your child.
                </li>
                <li style={{marginBottom:'20px'}}>
                  <b>No Right or Wrong:</b> This isn&apos;t about correct answers; it&apos;s about conveying 
                your child&apos;s mental health experiences as you perceive them.
                </li>
              </ul>
            </Box>
        
            <Grid container mt={4} sx={{background:'#F8F5FF', borderRadius:'8px', display:'flex', padding:'20px 12px',
              alignItems:'flex-start', alignSelf:'stretch'}}>
              <Grid item xs={1} md={0.5}>
                <InfoOutlined color='primary'/>
              </Grid>
              <Grid item xs={11} md={11.5}>
                <Typography mt='4px'>
              Remember, your insights are invaluable in helping us grasp and address your child&apos;s 
              unique challenges.
                </Typography>
              </Grid>          
            </Grid>
          </Box>
          <Box mt={4} textAlign={'center'}>
            <Button onClick={handleNavigation}>Let&apos;s Get Started</Button>
          </Box>
        </Box>
        <Grid container sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid item sm={4}>
            <CardMedia
              component={'img'}
              src='/images/mcatFooter.png'
              alt='main_logo'
              sx={{ width: 'auto', height: 200 }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Navigate;