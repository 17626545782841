import { AppBar, Toolbar, Container, CardMedia } from '@mui/material';
import logo from 'assets/images/logo.svg';

const PageHeader = () => {
  return (
    <AppBar elevation={0} position="sticky" color="inherit">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ minHeight: { xs: '80px', sm: '96px', lg: '135px' } }}
        >
          <CardMedia
            component="img"
            image={logo}
            sx={{
              width: 'auto',
              height: { xs: '35px', md: '47px' },
              margin: { md: 'auto' },
            }}
            alt="image"
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default PageHeader;
