import React from 'react';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';

function Step1(props: any) {
  const { control } = useFormContext();
  const [showGrade, setShowGrade] = React.useState<boolean>(false);

  const handleDateChange=(date:any)=>{    
    const data = dayjs(date).format('YYYY-MM-DD');
    const age = dayjs().diff(dayjs(data), 'month') / 12;
    if(new Date(date).getFullYear() > 1000){
      if (Number(age) > 18) {
        setShowGrade(true);
      } else {
        setShowGrade(false);
      }
    }
  };

  return (
    <Grid container direction={'column'} alignItems={'center'}>
      <Grid item xs={12} display={'flex'} direction={'column'} alignItems={'center'}>
        <Typography variant='h1'>How old is your child?</Typography>
        <Typography variant='body1' gutterBottom>
        At MindWeal, we specialize in caring for children between the ages of 4 and 18. 
       
        </Typography>
        <Typography variant='body1' gutterBottom paddingBottom={2}>
        We also offer our expertise to individuals over 18 who are still in high school.
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            name='childBirthDate'
            rules={{
              required: true,
            }}
            render={({ field }: any) => (
              <DatePicker
                sx={{ width:'100%', maxWidth: 434,marginBottom: '24px' }}
                disableFuture={true}
                onChange={(date: any) => {field.onChange(dayjs(date).format('YYYY-MM-DD')); handleDateChange(date);}}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      {showGrade ? 
        <Grid item xs={12} sm={6} display={'flex'} direction={'column'} alignItems={'center'}>
          <Typography variant='h1' gutterBottom textAlign={'center'}>
          Is your child in grade 12 or below?
          </Typography>
          <Typography variant='body1' gutterBottom textAlign={'center'} sx={{maxWidth:602,paddingBottom:2}}>
          At MindWeal, we specialize in caring for children between the ages of 4 and 18. We also offer our 
          expertise to individuals over 18 who are still in high school.
          </Typography>
          <Controller
            control={control}
            name='enrolledInSchool'
            rules={{
              required: true,
            }}
            render={({ field }: any) => (
              <TextField
                fullWidth
                id='is-your-child-enrolled-in-school'
                select // tell TextField to render select
                placeholder='Select your answer here'
                onChange={(data: any) => {
                  field.onChange(data.target.value);
                }}
                defaultValue='select_schooling'
                sx={{maxWidth:434}}
              >
                <MenuItem key={1} value='yes'>
                Yes
                </MenuItem>
                <MenuItem key={0} value='no'>
                No
                </MenuItem>
              </TextField>
            )}
          />
        </Grid> : null}
    </Grid>
  );
}

export default Step1;
