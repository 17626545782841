import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';

const MCAT = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`${ROUTE.MCAT_INTRO}`, { state: { understand: true } });
  };

  return (
    <>
      
      <Grid item xs={1} display={'flex'} flexDirection={'row-reverse'} sx={{m:'20px 20px 0 0'}}>
        <Button variant='text' sx={{color:'#000'}}  onClick={() => navigate(`${ROUTE.DASHBOARD}`)}>X</Button>
      </Grid>
      
      <Container component='section' maxWidth='xl'
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} pb={2} maxWidth={'md'}>
          <CardMedia component={'img'} src='/images/mcat1.svg' alt='main_logo' sx={{ width: 82, height: 84 }} />
      
          <Typography variant='h1' textAlign={'center'} pt={4} pb={4}>
          M-CAT <br />
          MindWeal - Comprehensive Assessment Tool
          </Typography>

          <Box sx={{
            display:'flex',
            flexDirection:'column',
            padding:'30px 24px 24px 24px',
            alignItems:'flex-start',
            borderRadius:'8px',
            background:'#fff',
            boxShadow:'0px 4px 16px 0px rgba(0, 0, 0, 0.12)'
          }}>
            <Typography variant='h3'>Explore M-CAT: A Wellness Wayfinder For Your Child</Typography>
            <Typography textAlign={'left'} mt={2} sx={{fontSize:'18px'}}>
        Designed by Dr. Bansal, an expert in child and adolescent mental health, M-CAT simplifies the 
        complex world of diagnosis in Pediatric Psychiatry. This user-friendly online tool cleverly 
        spots the patterns in various mental health symptoms, focusing on their underlying causes while 
        considering your child&apos;s developmental stage.
            </Typography>
        
            <Grid container mt={4} sx={{background:'#F8F5FF', borderRadius:'8px', display:'flex', padding:'20px 12px',
              alignItems:'flex-start', alignSelf:'stretch'}}>
              <Grid item xs={1}>
                <InfoOutlined color='primary'/>
              </Grid>
              <Grid item xs={11}>
                <Typography >
          Please carefully review the upcoming screens, which offer a comprehensive overview of the
          MCAT including what to expect, essential do&apos;s and don&apos;ts, tips for a smooth 
          assessment and rewards of successfully completing the assessment.
                </Typography>
              </Grid>          
            </Grid>
          </Box>
          <Box mt={4} textAlign={'center'}>
            <Button onClick={handleNavigation}>Let&apos;s go</Button>
          </Box>
        </Box>
        <Grid container sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid item sm={4}>
            <CardMedia
              component={'img'}
              src='/images/mcatFooter.png'
              alt='main_logo'
              sx={{ width: 'auto', height: 200 }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MCAT;