/* eslint-disable indent */

import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import relativeTime from 'dayjs/plugin/relativeTime';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { querySchema } from 'utils/schema';
import { ROUTE } from 'route/RouteEnums';
import { useNavigate } from 'react-router-dom';
import Signup from './SignUp';
import dayjs from 'dayjs';
import { sendQueryAPI } from 'services/authServices';
import { validateZipcode } from 'store/slices/signInSlice';
import { useAppDispatch } from 'store/hooks';

const steps = [
  {
    label: 'Child’s Age',
    errorScreenContent: {
      error: true,
      heading: 'Thank you for your interest ',
      // eslint-disable-next-line max-len
      body:
        'MindWeal specializes in psychiatric care exclusively for children aged 4 to 18.' +
        ' For other mental health referrals, please consult your health insurance carrier or primary care provider.' +
        ' For urgent situations, immediately dial 911 or visit the nearest emergency room.',
    },
  },
  {
    label: 'Zip Code',
    errorScreenContent: {
      error: true,
      heading: 'We\'re sorry, services aren’t currently available in your area.',
      // eslint-disable-next-line max-len
      body: 'We deeply value you and are working hard to expand. Stay tuned and thanks for your patience! For referral to a provider in your area, please consult your health insurance carrier or primary care provider. For urgent situations, immediately dial 911 or visit the nearest emergency room.',
    },
  },
  {
    label: 'Consent',
    errorScreenContent: {
      error: true,
      heading: 'Thank you for your interest ',
      // eslint-disable-next-line max-len
      body: 'Access to this app is reserved for parents and legal guardians of our patients. Please request them to create an account with us.',
    },
  },
  {
    label: 'Insurance Provider',
    errorScreenContent: {
      error: true,
      heading: 'Are you willing to pay out-of-pocket for this visit?',
      // eslint-disable-next-line max-len
      body: 'We regret to inform you that we currently do not accept your insurance. However, we value your health and offer the option to access our services through out-of-pocket payment.',
    },
  },
];

const modalContent = {
  onLoad: {
    header: 'Welcome to MindWeal.',
    subTitle:
      // eslint-disable-next-line max-len
      'Empower your child\'s mental wellness! Answer some questions to tailor their care, then book a session with our expert.',
      getStartedButton: false,
    img: '../images/Group 9427.svg',
  },
  onComplete: {
    header: 'Congratulations!',
    subTitle: 'Sign up now and start your journey with us.',
    getStartedButton: true,
    img: '../images/Group 9440.svg',
  },
};

const SignUp = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [errorScreenContent, setErrorScreenContent] = useState<any>({
    error: false,
    heading: null,
    body: null,
  });
  const methods = useForm({
    mode: 'onChange',
  });
  const [isOpen, setOpen] = useState(false);
  const [modalData, setModalData] = useState(modalContent.onLoad);
  const [userEligibleError, setUserEligibleError] = useState(false);
  const [bgImagePath, setBgImagePath] = useState('images/Group 9552.png');
  const [checkForExistingPatient,setCheckForExistingPatient] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register: queryRegister,
    formState: { isValid },
    handleSubmit: queryHandleSubmit,
  } = useForm({
    resolver: yupResolver(querySchema),
    mode: 'all',
  });
  const isSm = useMediaQuery('(max-width:786px)');
  const [progress, setProgress] = React.useState(true);

  const getStepView = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      case 3:
        return <Step4 />;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleScreen = () => {
    setErrorScreenContent({ ...steps[activeStep].errorScreenContent });
  };

  const isValidStep = (data: any) => {
    switch (activeStep) {
      case 0: {
        setBgImagePath('images/Group 9554.png');
        dayjs.extend(relativeTime);
        const age = dayjs().diff(dayjs(data.childBirthDate), 'month') / 12;
        if (Number(age) < 4 || Number(age) > 18) {
           if(Number(age) > 18 && data.enrolledInSchool === 'yes'){
            handleNext();
           } else if(Number(age) > 18 && data.enrolledInSchool === 'no'){
            handleScreen();
           }else handleScreen();
          }
        else handleNext();
        break;
      }

      case 1: {
        setBgImagePath('images/Group 9556.png');
        dispatch(validateZipcode(data.zipCode))
        .unwrap()
        .then((res:any) =>{
          if(res?.status === 200){
            handleNext();
          }
        })
        .catch((e:any) => {
          if(e.err.errorCode){
            handleScreen();
          }
        });
        break;
      }
      case 2: {
        setBgImagePath('images/Group 9558.png');
        if (data.relationship === 'other') handleScreen();
        else handleNext();
        break;
      }
      case 3: {
        setBgImagePath('images/Group 9441.png');
        if (data.insurance === 'others') handleScreen();
        else {
          setProgress(true);
          setModalData(modalContent.onComplete);
          setOpen(true);        
        }
        break;
      }
    }
  };

  const redirectForMigration=()=>{
    navigate(ROUTE.MIGRATION_SIGNUP);
  };

  const handleNewRegistration=()=>{
    setCheckForExistingPatient(false);
    setOpen(true);
  };

  const onSubmit = (data: any) => {
    isValidStep(data);
  };

  const sendQuery = (data: any) => {
    sendQueryAPI(data, (response: any) => {
      navigate(ROUTE.HOME);
    });
  };

  const getNotEligibleContent = () => {
    return (
      <>
        <Grid item xs={12}>
          <img
            src={!isSm ? 'icon-96x96.png' : 'icon-48x48.png'}
            alt='mindweal-logo'
            style={{ margin: '0 auto', display: 'block' }}
          />
          <Typography variant='h1'  textAlign={'center'}>
            Based on your responses, we&apos;re currently unable to serve you.
          </Typography>
          <Typography
            variant='h2'
            gutterBottom
            textAlign={'center'}
            sx={{ fontSize:{xs:14,md:18}, fontWeight: 400, pt:1, mb:4 }}
          >
            However, we&apos;re here to help. Please call us at 618-310-0085 for further assistance or submit your
            inquiry below. For urgent situations, immediately dial 911 or visit the nearest emergency room.
          </Typography>

          <Box
            component='form'
            onSubmit={queryHandleSubmit((formData) => sendQuery(formData))}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextField
              id='filled-multiline-flexible'
              multiline
              rows={8}
              variant='filled'
              placeholder='Please enter your queries here...'
              fullWidth
              {...queryRegister('message')}
            />
            <Grid container direction={'row'} alignContent={'center'} justifyContent={'center'}>
              <Button
                fullWidth
                variant='outlined'
                sx={{
                  mt: 2,
                  mb: 2,
                  mx: 2,
                  py: '10px',
                  px: '24px',
                  maxWidth: '203px',
                }}
                href={ROUTE.HOME}
              >
                Close
              </Button>

              <Button
                fullWidth
                type='submit'
                disabled={!isValid ? true : false}
                sx={{
                  mt: 2,
                  mb: 2,
                  mx: 2,
                  py: '10px',
                  px: '24px',
                  maxWidth: '203px',
                }}
              >
                Save
              </Button>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(() => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
  }));

  function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <StepIconRoot ownerState={{ active }} className={className}>
        {active ? (
          <img src='images/active.png' alt='active' />
        ) : completed ? (
          <img src='images/complete.png' alt='completed' />
        ) : (
          <img src='images/pending.png' alt='pending' />
        )}
      </StepIconRoot>
    );
  }
  return (
    <>
      <Container
        maxWidth='lg'
        component='main'
        sx={{
          filter: isOpen ? 'blur(10px)' : '',
          paddingBottom: '50px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={bgImagePath}
            alt='bg-img'
            style={{
              position: 'fixed',
              bottom: '0',
              width: 450,
              height: 'auto',
              left: 0,
              zIndex: -1,
              display: isSm ? 'none' : 'block',
            }}
          />

          {activeStep === steps.length ? (
            <Signup registrationDetails={methods.getValues()} />
          ) : !errorScreenContent.error ? (
            <Grid container spacing={2}>
              <Grid item xs={12} display={'flex'} direction='column' justifyContent='center' alignItems='center'>
                <img src='icon-48x48.png' alt='mindweal-logo' />
                {!isSm && (
                  <Typography variant='h3' gutterBottom>
                    Pre-verification
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((step, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};

                    return (
                      <Step key={step.label} {...stepProps}>
                        <StepLabel {...labelProps} StepIconComponent={StepIcon}>
                          {step.label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: 7.2 }}>
                <FormProvider {...methods}>
                  <form id='preVerify' onSubmit={methods.handleSubmit(onSubmit)}>
                    {getStepView(activeStep)}
                  </form>
                </FormProvider>
              </Grid>
              <Grid item xs={12} display={'flex'} direction='column' justifyContent='center' alignItems='center'>
                <Button
                  fullWidth
                  form='preVerify'
                  type='submit'
                  disabled={!methods.formState.isValid}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ padding: '10px 24px', maxWidth: '203px' }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container>              
              {activeStep < 3 ? (
                <Grid
                  item
                  display={'flex'}
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  justifyItems='center'
                  xs={12}
                > 
                  <img src={!isSm ? 'icon-96x96.png' : 'icon-48x48.png'} alt='mindweal-logo' />
                  <Typography variant='h1' textAlign={'center'}>
                    {errorScreenContent.heading}
                  </Typography>
                  <Typography variant='subtitle1' gutterBottom textAlign={'center'}>
                    {errorScreenContent.body}
                  </Typography>
                  <Button
                    fullWidth
                    type='submit'
                    variant='outlined'
                    sx={{ mt: 4, mb: 2, maxWidth: '266px' }}
                    href={ROUTE.HOME}
                  >
                    Back to Website
                  </Button>
                </Grid>
              ) : !userEligibleError ? (
                <>
                  <Grid item xs={12} >
                    <Grid container>
                      <Grid item sx={{ mt:4, position: { md: 'absolute' }, display: { xs: 'none', md: 'block' } }}>
                        {/* <Button
                          startIcon={<ArrowBackIcon />}
                          variant='outlined'
                          onClick={() =>
                            setErrorScreenContent({
                              ...errorScreenContent,
                              error: false,
                            })
                          }
                        >
                          Back
                        </Button> */}
                      </Grid>
                      <Grid item xs={12} sx={{mt:1}}> 
                        <img
                          src={!isSm ? 'icon-96x96.png' : 'icon-48x48.png'}
                          alt='mindweal-logo'
                          style={{ margin: '0 auto', display: 'block' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 8 }}>
                    <Typography variant='subtitle1' gutterBottom textAlign={'center'} fontSize={{xs:16,md:18}}>
                      {errorScreenContent.body}
                    </Typography>
                    <Typography variant='h1' textAlign={'center'} mt={4}>
                      {errorScreenContent.heading}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent='center'
                    alignContent='center'
                    flexWrap={'wrap'}
                    sx={{ pb: 18 ,pt:2}}
                  >
                    <Button
                      fullWidth
                      type='submit'
                      variant='outlined'
                      sx={{
                        mt: 1,
                        mb: 1,
                        mx: 4,
                        maxWidth: '203px',
                      }}
                      onClick={() => setUserEligibleError(true)}
                    >
                      No
                    </Button>
                    <Button
                      fullWidth
                      type='submit'
                      sx={{
                        mt: 1,
                        mb: 1,
                        maxWidth: '203px',
                      }}
                      onClick={() => {
                        setModalData(modalContent.onComplete);
                        setOpen(true);
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                </>
              ) : (
                getNotEligibleContent()
              )}
            </Grid>
          )}
        </Box>
      </Container>
      <Dialog maxWidth='xs' aria-describedby='alert-dialog-description' open={isOpen} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px',
              textAlign:'center'
            }}
          >
            <img src={modalData.img} alt='welcometomindweal' onLoad={() => setProgress(false)} />
            {!progress ? (
              <>
                {/* <Typography variant='h2' gutterBottom>
              {modalData.header}
            </Typography> */}
                <Typography variant='subtitle1' gutterBottom >
                  {modalData.subTitle}
                </Typography>
                {!modalData.getStartedButton && (
                  <Button fullWidth sx={{ mt: 2, mb: 2 }} onClick={() => setOpen(false)}>
                    Continue
                  </Button>
                )}
                {modalData.getStartedButton && (
                  <Button
                    type='submit'
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    onClick={() => {
                      setOpen(false);
                      handleNext();
                    }}
                  >
                    Get Started
                  </Button>
                )}
              </>
            ) : (
              <CircularProgress />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth='xs' aria-describedby='alert-dialog-description' open={checkForExistingPatient}>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px',
              textAlign:'center'
            }}
          >
           <Typography variant='h3'>Are you an existing patient from BanCAPs
             (now MindWeal Health) with at least one completed clinical visit?</Typography>
            <Grid container mt={2} spacing={1}>
              <Grid item xs={6}>
             <Button variant='contained'
             sx={{minWidth:{xs:'100px', md:'150px'}}}
             onClick={() => redirectForMigration()}>Yes</Button>
             </Grid>
             <Grid item xs={5}>
             <Button variant='outlined'
             sx={{minWidth:{xs:'100px', md:'150px'}}}
             onClick={() => handleNewRegistration()}>No</Button>
             </Grid>
             </Grid> 
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignUp;
