import { useEffect } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, GridProps, 
  Typography, styled } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';

interface CustomizedState {
  understand: boolean;
}

const Advisory = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {
    understand
  } = state || {};

  useEffect(() => {
    if (understand === undefined) {
      navigate(ROUTE.MCAT_TEST);
    }
  });

  const handleNavigation = () => {
    navigate(`${ROUTE.MCAT_NAVIGATE}`, { state: { understand: true } });
   
  };

  const StyleGridItem = styled(Box)<GridProps>(({ theme }) => ({
    background: 'url("/images/Ellipse.png") ',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '96px',
    height: '86px'
  }));

  return (
    <>
      <Grid item xs={1} display={'flex'} flexDirection={'row-reverse'} sx={{m:'20px 20px 0 0'}}>
        <Button variant='text' sx={{color:'#000'}}  onClick={() => navigate(`${ROUTE.DASHBOARD}`)}>X</Button>
      </Grid>
      <Container
        component='section'
        maxWidth='xl'
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} pb={2} maxWidth={'md'} >
        
          <CardMedia component={'img'} src='/images/mcat-urgentcare.svg' alt='urgent care'
            sx={{ width: 63, height: 56 }} />
        
          <Box mb={5}>
            <Typography textAlign={'center'} textTransform={'capitalize'}
              sx={{ fontSize: '36px', fontWeight: '600', lineHeight: '44px' }}>
            Urgent Care Advisory
            </Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: '400', lineHeight: '30px' }}>
            Important Precautions Before M-CAT
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography textAlign={'left'} lineHeight={'24px'} sx={{ fontSize: '18px' }}>
            Should you suspect that your child is at immediate risk of harm to themselves or others,
            including thoughts of suicide, it&apos;s crucial to halt this assessment now. Please promptly
            seek assistance at the nearest Emergency Room for an urgent mental health evaluation. Your
            child&apos;s safety is the utmost priority.
            </Typography>
          </Box>

          <Grid container mb={4}
            sx={{
              background: '#fff', 
              display: 'flex', 
              padding: '24px', 
              flexDirection: 'column',
              alignItems: 'flex-start', 
              alignSelf: 'stretch',
              borderRadius:'8px',
              boxShadow:'0px 4px 16px 0px rgba(0, 0, 0, 0.12)'
            }}>

          

            <Card sx={{ background: 'transparent', boxShadow: 'none',  width:'100%' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <StyleGridItem item xs={2} >
                    <Typography variant='h3' fontSize={22} lineHeight={23} fontWeight={600}>
                  &nbsp;911
                    </Typography>
                  </StyleGridItem>
                  <Grid item xs={10}>
                    <Typography variant='h2'>ER</Typography>
                    <Typography variant='body1' mt='7px'>
                  For any medical or mental health emergency, call 911 or go to your local ER.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ background: 'transparent', boxShadow: 'none',  width:'100%' }}>
              <CardContent>
                <Grid container spacing={2} >
                  <StyleGridItem item xs={2}>
                    <Typography variant='h3' fontSize={22} fontWeight={600}>
                    &nbsp;988
                    </Typography>
                  </StyleGridItem>
                  <Grid item xs={10}>
                    <Typography variant='h2'>Suicide & crisis lifeline</Typography>
                    <Typography variant='body1' mt='7px'>
                  If in distress, call or text this number to receive 24/7, free and confidential support.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ background: 'transparent', boxShadow: 'none',  width:'100%' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <StyleGridItem item xs={2} >
                    <Typography variant='h3' fontSize={20} fontWeight={600}>
                741741
                    </Typography>
                  </StyleGridItem>
                  <Grid item xs={10}>
                    <Typography variant='h2'>Crisis text line</Typography>
                    <Typography variant='body1' mt='7px'>
                  Text MHA to this number to receive free, text-based 24/7 support from a trained Crisis Counsellor.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

          </Grid>


          <Button onClick={handleNavigation}>Get Started</Button>
        </Box>
        <Grid container sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid item sm={4}>
            <CardMedia
              component={'img'}
              src='../images/mcatFooter.png'
              alt='main_logo'
              sx={{ width: 'auto', height: 200 }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Advisory;