import React from 'react';
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';

import { ROUTE } from 'route/RouteEnums';
import logo from 'assets/images/logo.svg';
import resetLinkImage from 'assets/images/reset-email-image.svg';

const SuccessPassword = (props: any) => {
  return (
    <Container component='main' maxWidth='xl' sx={{ position: 'relative' }}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '655px',
          margin: 'auto',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <CardMedia
          component='img'
          image={logo}
          sx={{
            width: 'auto',
            height: { xs: '47px', md: '72px' },
            margin: { xs: '0 0 35px 0', md: '0 auto 48px auto' },
          }}
          alt='image'
        />
        <CardMedia
          component='img'
          image={resetLinkImage}
          sx={{
            width: { xs: '280px', sm: 'auto' },
            height: { xs: 'auto', sm: '250px', xl: '380px' },
            position: 'absolute',
            bottom: '0',
            left: '0',
          }}
          alt='image'
        />
        <Typography variant='h1' fontSize={60}>
          Password Changed!
        </Typography>
        <Typography variant='subtitle1' mb={4}>
          Your password has been changed successfully
        </Typography>
        <Grid display='flex' direction='column'>
          <Button
            variant='outlined'
            sx={{ mt: 2, mb: 2, mx: 4, py: '10px', px: '24px', minWidth: '266px' }}
            href={ROUTE.HOME}
          >
            Back to Website
          </Button>
          <Button sx={{ mt: 2, mb: 2, mx: 4, py: '10px', px: '24px', minWidth: '266px' }} href={ROUTE.LOGIN}>
            Sign in
          </Button>
        </Grid>
      </Box>
    </Container>
    // <Container component="main" maxWidth="sm">
    //   <Box
    //     sx={{
    //       marginTop: 8,
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'ceter',
    //     }}
    //   >
    //     <Typography variant="h1">Password Changed!</Typography>
    //     <Typography variant="subtitle1" gutterBottom>
    //       Your password has been changed successfully
    //     </Typography>
    //     <Button variant="outlined" href={ROUTE.HOME}>
    //       Back to Website
    //     </Button>
    //   </Box>
    // </Container>
  );
};

SuccessPassword.propTypes = {};

export default SuccessPassword;
