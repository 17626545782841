import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleMessage } from 'shared/api/ErrorMessages';
import { addVitalsDetailsAPI, getVitalsDetailsAPI } from 'services/vitalServices';

interface Error {
  data: any;
}

export const getVitalsDetails = createAsyncThunk(
  'GET_VITALS_DETAILS',
  async (patientId: any, { rejectWithValue }: any) => {
    try {
      const response:any = await getVitalsDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const addVitalDetails = createAsyncThunk(
  'ADD_VITALS_DETAILS',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await addVitalsDetailsAPI(payload);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

interface Loader {
  isVitalFetched: boolean;
  isVitalSubmitted: boolean;
}

interface StateI {
  loaders: Loader;
  error: undefined;
}

const initialState = {
  loaders: {
    isVitalFetched: false,
    isVitalSubmitted:false,
  },
  error: undefined,
};

const vitalSlice = createSlice({
  name: 'vital',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVitalsDetails.pending, (state: StateI) => {
      state.loaders.isVitalFetched = true;
    });
    builder.addCase(getVitalsDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isVitalFetched = false;
    });
    builder.addCase(getVitalsDetails.rejected, (state: StateI) => {
      state.loaders.isVitalFetched = false;
    });
    builder.addCase(addVitalDetails.pending, (state: StateI) => {
      state.loaders.isVitalSubmitted = true;
    });
    builder.addCase(addVitalDetails.fulfilled, (state: StateI, action: any) => {
      state.loaders.isVitalSubmitted = false;
    });
    builder.addCase(addVitalDetails.rejected, (state: StateI) => {
      state.loaders.isVitalSubmitted = false;
    });
  },
});

export default vitalSlice.reducer;