import { useState, useContext, useEffect } from 'react';
import { Container, Grid, Tab, Tabs, Typography, styled } from '@mui/material';
import { DataGrid, DataGridProps, GridColDef,getGridDateOperators,gridClasses } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import CreateTask from 'components/Task/CreateTask';
import { useAppDispatch } from 'store/hooks';
import { getTaskList, getTaskCount } from 'store/slices/secureMessageSlice';
import COLORS from 'theme/colors';
import { AuthContext } from 'utils/AuthContext';
import { CustomToolbar } from 'utils/functions';

const TaskList = () => {

  const [taskList, setTaskList] = useState<any[] | null>();
  const [editItem, setEditItem] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [taskCount, setTaskCount] = useState<any>('');
  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);

  const StyledDataGrid = styled(DataGrid)<DataGridProps>(({ theme }) => ({
    border: 'none',
    backgroundColor: COLORS.LightYellow,
    fontSize: 14,
    fontWeight: 400,
    color: COLORS.Black,
    '.MuiDataGrid-toolbarContainer': {
      background: '#FFFBF1',
    },
    '.MuiDataGrid-columnHeaders': {
      marginBottom: 20,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
    },
    '.MuiDataGrid-columnHeadersInner': {
      background: COLORS.White,
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
    // '.MuiDataGrid-virtualScroller': {
    //   border: '1px solid rgb(224 224 224) !important',
    // },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: COLORS.White,
      '&:hover, &.Mui-hovered': {
        backgroundColor: COLORS.LightBlue,  
      },
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: COLORS.LightGray,
      '&:hover, &.Mui-hovered': {
        backgroundColor: COLORS.LightBlue,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 400,
      fontSize: '16px',
      color: '#000000',
      lineHeight: '24px'
    },
  }));


  const getList = async () => {
    const data = {
      id : user?.userId,
      listType : selectedTab === 0 ? 'open' : selectedTab === 1 ? 'in-progress' :'closed'
    };

    await dispatch(getTaskList(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setTaskList(response?.data);
          setLoading(false);
        }
      });

    await dispatch(getTaskCount({}))
      .unwrap()
      .then((response:any) => {
        if(response?.status === 200){
          const data = {
            open: 
          // eslint-disable-next-line array-callback-return
          response.data.map((x:any) => {
            if(x.status === 'open'){
              return x.count;
            }
          }).filter((ele:any) => ele),
            // eslint-disable-next-line array-callback-return
            inprogress : response.data.map((x:any) => {
              if(x.status === 'in-progress'){
                return x.count;
              }
            }).filter((ele:any) => ele),
            closed: 
            // eslint-disable-next-line array-callback-return
            response.data.map((x:any) => {
              if(x.status === 'closed'){
                return x.count;
              }
            }).filter((ele:any) => ele),
          };
          setTaskCount(data);          
        }
      });  
  };

  useEffect(() => {
    getList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem, selectedTab]);

  const columns: GridColDef[] = [
    {
      field:'id',
      headerName:'Id',
      flex:0.5,
      filterable:false
    },
    {
      field: 'taskTitle',
      headerName: 'Task Title',
      flex: 1.5,
    },
    {
      field: 'assignedBy',
      headerName: 'Assigned By',
      flex: 1,
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Task Status',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize'
            }}
          >
            {params?.row?.status}
          </span>
        );
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.row?.dueDate).format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.dueDate).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return (
          <>
            {dayjs(params?.row?.dueDate).format('MM/DD/YYYY')}
          </>
        );
      }
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.priority === 'medium' ?
              <span
                style={{
                  color: '#CA8504'
                }}>
                 Medium
              </span> :
              params?.row?.priority === 'low' ?
                <span
                  style={{
                    color: '#12B76A',
                  }}>
                 Low
                </span> :
                <span
                  style={{
                    color: '#F04438'
                  }}>
                  High
                </span>
            }

          </>
        );
      }
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize'
            }}
          >
            {params?.row?.patientName}
          </span>
        );
      }
    },
  ];

  const rowSelect=(params:any)=>{
    // eslint-disable-next-line array-callback-return
    const editItem = taskList?.filter((d:any) =>{
      if(d.id === params.row.id){
        return d;
      }
    }); 
    setEditItem(editItem);   
  };

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth='lg'>
      <Grid item xs={12}>
        <Typography
          variant='h5'
          position={'relative'}
          width={'fit-content'}
          sx={{ paddingTop: 6, textTransform: 'capitalize' }}
        >
          Task List
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: COLORS.White }}>
        <Grid container sx={{background:'#FFFBF1'}}>
          <Grid item xs={4} mb='10px'>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{
                background: '#F9FAFB', 
                borderRadius: '23px',
                border:'1px solid #F2F4F7', 
                marginTop: '10px',
                padding:'6px 14px',
                '.MuiTab-root.Mui-selected': {
                  background: '#9F84FF',
                  color: '#fff',
                  borderRadius: '23px',
                  fontWeight: 500,
                }
              }}
              TabIndicatorProps={{
                style: {
                  background: 'transparent',
                }
              }}
              centered
            >
              <Tab
                label={<Grid>
                      Open
                  <span style={{background: selectedTab === 0 ? '#644EB5' : '#F2F4F7',
                    borderRadius:'23px', 
                    padding:'5px 7px',
                    marginLeft:'5px'}}>
                    {taskCount && taskCount?.open?.length ? taskCount.open : 0}</span>
                </Grid>}/>
                  
              <Tab
                label={<Grid>
                    In-Progress
                  <span style={{
                    background: selectedTab === 1 ? '#644EB5' : '#F2F4F7', 
                    borderRadius:'23px', 
                    padding:'5px 7px'}}>
                    {taskCount && taskCount?.inprogress?.length ? taskCount.inprogress : 0}</span>
                </Grid>}/>
                  
              <Tab
                label={<Grid>
                      Closed 
                  <span style={{
                    background: selectedTab === 2 ? '#644EB5' : '#F2F4F7', 
                    borderRadius:'23px', 
                    padding:'5px 7px'}}>
                    {taskCount && taskCount?.closed?.length ? taskCount.closed : 0}</span>
                </Grid>}/>
                  
            </Tabs>
          </Grid>    
        </Grid> 
        
        <StyledDataGrid
          autoHeight
          rows={taskList ? taskList : []}
          columns={columns}
          loading={loading}
          onRowClick={rowSelect}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
            sorting:{
              sortModel:[{field:'id', sort:'desc'}]
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          columnVisibilityModel={{
            id:false,
            assignedTo: user?.roleType === 'Global Manager' ? true : false
          }}
          pageSizeOptions={[10, 25, 50]}
          rowSpacingType='margin'
          getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          style={{ cursor: 'pointer' }}
        />
      </Grid>
      {editItem !== null && 
      <CreateTask 
        onClose={() => setEditItem(null)} 
        editData={editItem !== null ? editItem : null}
        userId={editItem[0].mindwealUserId} 
        patientName = {editItem === null ? editItem[0].userName : null}
      />
      }
    </Container>
  );
};
export default TaskList;