import { API_URL } from 'shared/api/Constants';
import { apiInterceptor } from './axios';

export const getVitalsDetailsAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.VITALS_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const addVitalsDetailsAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.VITALS_DETAILS}`, data).then((response: any) => {
    return response;
  });
};