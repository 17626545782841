const COLORS = {
  // minweal color
  Yellow: '#FFC44D',
  White: '#FFFFFF',
  Black: '#000000',
  Link: '#3467FF', // blue  #3467FF;
  DarkBlue: '#0A2277',
  LightBlue: '#EEF0F6',
  FormLabel: '#344054',
  FormInput: '#1C1F24',
  LightGray: '#F9F5FF',
  LightYellow: '#FFFBF1',
  Peach: '#FFECC8',

  Text: '#2C3446',
  Gray300: '#D0D5DD',
  Gray900: '#101828',
  Gray600: '#475467',
  BorderGray: '#D0D5DD',
  Error: '#F04438',
  Primary700: '#6941C6',
  GrayDark: '#353940',
  Success500: '#12B76A'
};

export default COLORS;
