import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sanitize } from 'dompurify';
import COLORS from 'theme/colors';

interface UserInputProps {
  question: any;
  handleNext: any;
  openModal: any;
}

const UserInput: React.FC<UserInputProps> = ({ question, handleNext, openModal }) => {
  const [value, setValue] = useState('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);

  useEffect(() => {
    if (question.type !== 'optional') {
      if (value.length > 0) {
        setDisableNextButton(false);
      } else if (value.length === 0) {
        setDisableNextButton(true);
      }
    } else {
      setDisableNextButton(false);
    }

  }, [question.type, value]);

  const handleSubmit = () => {
    const answerDetails = {
      quizId: question.questionId,
      answers: [{ input: value }],
      launchType: question.launchType
    };
    handleNext(answerDetails);
  };

  const handleBackButton=()=>{
    const obj = { event : 'back'};
    handleNext(obj);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#3467ff' : '#308fe8',
    },
  }));
  
  return (
    <Grid container maxWidth='100%'>
      <Box sx={{position: {xs:'inherit', md:'absolute'}, left:'20px', top:'130px'}} >
        <Button 
          sx={{ background: COLORS.LightBlue, minWidth: 140, mb:1  }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => handleBackButton()}  
          disabled={question?.event === 'back'? true : false}
        >Back</Button>
      </Box>
      <Grid item xs={12}>
        <BorderLinearProgress  value={question?.progress} variant='determinate'/>
        {question?.header !== null ? question.header : null}
        
        <Container sx={{ background: '#fff', padding: '20px 40px', borderRadius: '12px' }}>
          <Typography sx={{ color: '#6941C6', fontSize: '18px', fontWeight: 500, mb: '24px', lineHeight:'28px' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.heading) }} /></Typography>

          <Typography mb={3}
            sx={{ lineHeight: '28px', mt: '5px', mb:'24px', fontSize:'18px', 
              fontWeight:500, color:'#2C3446', fontStyle:'italic' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.prompt) }} /> </Typography>

          <Typography mb={3} 
            sx={{ lineHeight: '28px', mt: '5px', mb:'6px', fontSize:'20px', fontWeight:500, color:'#2C3446' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.question) }} /> </Typography>

          {question?.informationSection && question?.informationSection !== null ? (
            <Box mb={6}>
              <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}>
                <div dangerouslySetInnerHTML={{ __html: sanitize(question?.informationSection) }} /> </Alert>
            </Box>
          ) : null}  

          {question.info !== null ? (           
            <Box sx={{ background: '#F8F5FF', p:'8px 12px', alignSelf:'stretch', borderRadius:'8px', mb:'48px' }}>
              <Grid container>
                <Grid item xs={0.5}>
                  <InfoOutlinedIcon fontSize='small' color='primary' sx={{ mr: '10px' }} />
                </Grid>
                <Grid item xs={11.5} mt='2px'>
                  <Typography color='#1C1F24'>
                    <div dangerouslySetInnerHTML={{ __html: sanitize(question?.info) }} /></Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {question?.instruction !== null ?
            <Typography mb={3} sx={{ fontSize:'18px', fontWeight: 400, color:'#2C3446' }}>
              <div dangerouslySetInnerHTML={{ __html: sanitize(question?.instruction) }} /></Typography>
            : null}
          <Grid container item xs={12} direction='row'>
            <TextField
              autoComplete='answer'
              placeholder='Type here'
              fullWidth
              id='answer'
              autoFocus
              value={value}
              inputProps={{ maxLength: 300 }}
              onChange={(e) => setValue(e.target.value)}
              multiline
              rows={4}
              variant="standard"
              sx={{ border:'1px solid #D0D5DD', borderRadius:'8px', padding:'12px 14px' }}
              InputProps={{ disableUnderline:true }}
            />
        
          </Grid>

        
          <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: '15px' }} >
            <Box sx={{ textAlign: { xs: 'center' } }} width='50%'>            
              <Button onClick={handleSubmit} disabled={disableNextButton} fullWidth>
              Next
              </Button>
            </Box> 
          </Grid>
          {!disableNextButton &&<Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: '15px' }} >
            <Box sx={{ textAlign: { xs: 'center' } }} width='50%'>            
              <Button onClick={handleSubmit} variant='text' sx={{color:'#475467'}}>
                <u>SKIP QUESTION</u>
              </Button>
            </Box>
          </Grid>}
        
        </Container>
        <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: '15px' }} >
          <Box sx={{ textAlign: { xs: 'center' } }}>
            <Button variant='outlined' sx={{ marginRight: '8px', marginBottom: { xs: 2, sm: 0 } }} onClick={openModal}>
              Continue Later
            </Button>            
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserInput;
