import React, { useCallback, useState } from 'react';
import { Badge, Box, CardMedia, IconButton, Link, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

interface DragAndDropUploaderProps {
  onFileUpload: (files: File) => void;
  disable?: boolean;
  profileImage?: boolean;
  profilePicture?: any;
  defaultPicture: any;
  selectedTab: boolean;
  guardianDetails?:any;
}

const GuardianProfilePicture: React.FC<DragAndDropUploaderProps> = ({
  onFileUpload,
  disable,
  profileImage,
  profilePicture,
  defaultPicture,
  selectedTab,
  guardianDetails
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [droppedImage, setDroppedImage] = useState<string | null>(null);
  const onDrop = useCallback(
    (acceptedFiles: any, rejection:any) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          setDroppedImage(reader.result as string);
          setUploadedFile(file);
        };
        reader.readAsDataURL(file);
        onFileUpload(acceptedFiles);
      }else if(rejection.length ){
        if(rejection[0].errors[0].code === 'file-invalid-type'){
          toast('File type must be one of jpg, jpeg or png', {
            type: 'error',
            icon: true,
          });
        } else if (rejection[0].errors[0].code === 'file-too-large') {
          toast('Please upload file with size 1 MB or below', {
            type: 'error',
            icon: true,
          });
        }
      }
    },
    [onFileUpload]
  );

  const formatFileSize = (size: number): string => {
    const kbSize = size / 1024;
    return `${kbSize.toFixed(2)} KB`;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/png': [],
      'image/jpeg': [],
    },
    maxSize: 1000000, // size in bytes
    disabled: disable,
  });
  return (
    <Box
      // {...getRootProps()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: uploadedFile ? 'start' : 'center',
        border: !profileImage ? '0.5px solid #3467FF' : '0px',
        // borderRadius: '12px',
        background: isDragActive ? '#FFC44D' : 'transparent',
        minHeight: '57px',
      }}
    >
      {uploadedFile && !profileImage ? (
        <>
          <Typography variant='body1' gutterBottom sx={{ textAlign: 'start' }}>
            {uploadedFile.name}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {formatFileSize(uploadedFile.size)} - 100% uploaded
          </Typography>
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          {!profileImage ? (
            <>
              <CloudUploadOutlinedIcon />
              <Typography variant='body1' gutterBottom>
                <Link variant='body1' sx={{ cursor: 'pointer' }}>
                  Click to upload
                </Link>{' '}
                or drag and drop
              </Typography>
              <Typography variant='body1' gutterBottom>
                PNG, JPG (max. 10MB)
              </Typography>
            </>
          ) : (
            <Badge
              badgeContent={
                <>
                  {selectedTab && (
                    guardianDetails?.id ?
                      <IconButton {...getRootProps()} sx={{ padding: 1, mt: 1 }}>
                        <AddPhotoAlternateIcon color='primary' 
                          sx={{ backgroundColor: 'transparent' }} fontSize='small' />
                      </IconButton> : 
                      <IconButton {...getRootProps()} sx={{ padding: 1, mt: 1 }} disabled>
                        <AddPhotoAlternateIcon color='disabled' 
                          sx={{ backgroundColor: 'transparent' }} fontSize='small' />
                      </IconButton>

                  )}
                </>
              }
            >
              {droppedImage ? (
                <img
                  src={droppedImage}
                  alt='Dropped'
                  style={{
                    maxWidth: '72px',
                    height: profileImage ? '72px' : 'auto',
                    width: '72px',
                    border: selectedTab ? '2px solid #FFC44D' : 'none',
                  }}
                />
              ) : (
                <CardMedia
                  component={'img'}
                  src={profilePicture ? `data:image/png;base64, ${profilePicture}` : defaultPicture}
                  alt=''
                  width={'60px'}
                  height={'auto'}
                  sx={{
                    position: 'relative',
                    maxWidth: '60px',
                    border: selectedTab ? '2px solid #FFC44D' : 'none',
                    borderRadius: '4px',
                  }}
                />
              )}
            </Badge>
          )}
        </>
      )}
    </Box>
  );
};

GuardianProfilePicture.propTypes = {};

export default GuardianProfilePicture;
