import React from 'react';
import { Box, Container, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

function Step3(props: any) {
  const { control } = useFormContext();
  const isSm = useMediaQuery('(max-width:786px)');
  return (
    <Container maxWidth='md'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h1' textAlign={'center'}>
          How are you related to the child? 
        </Typography>
        <Typography
          variant='subtitle1'
          display='block'
          gutterBottom
          textAlign={'center'}
          sx={{
            fontWeight: 400,
            color: '#475467',
          }}
        >
          We ask this to ensure that only authorized guardians approve this evaluation.
        </Typography>
        <Controller
          control={control}
          name='relationship'
          rules={{ required: true }}
          render={({ field }: any) => (
            <ToggleButtonGroup onChange={(data: any) => field.onChange(data)} value={field.value} fullWidth={!isSm}>
              <ToggleButton value='parent'>Parent</ToggleButton>
              <ToggleButton value='guardian'>Legal Guardian</ToggleButton>
              <ToggleButton value='other'>Other</ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </Box>
    </Container>
  );
}

export default Step3;
