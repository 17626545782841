import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Tooltip,
  Typography,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  LinearProgress,
  styled,
  linearProgressClasses
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { sanitize } from 'dompurify';
import COLORS from 'theme/colors';

interface SingleSelectProps {
  question: any;
  answers: any;
  handleNext: any;
  openModal: any;
}

const SingleSelect: React.FC<SingleSelectProps> = ({ question, answers, handleNext, openModal }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [otherValue, setOtherValue] = useState<string>('');
  const [value, setValue] = useState('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    if (question.type !== 'optional') {
      if (selectedAnswers > 0) {
        setDisableNextButton(false);
      } else {
        setDisableNextButton(true);
      }
    } else {
      setDisableNextButton(false);
    }

  }, [question, selectedAnswers]);


  useEffect(() => {
    if (selectedAnswers > 0) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswers]);

  const handleChange = (e: any) => {
    const { id } = e.target;
    setSelectedAnswers(parseInt(id));
    setValue(e.target.value);
    if (e.target.name === 'other') {
      setDisabled(false);
      setDisableNextButton(true);
    } else {
      setOtherValue('');
      setDisabled(true);
      setErrorMessage(false);
    }
  };



  const handleGridClick = (obj: any) => {
    const data = {
      target: {
        id: obj.id,
        name: obj.type,
        value: obj.text
      }
    };
    handleChange(data);
  };

  const handleSubmit = () => {
    let answerDetails: { quizId: any; answers: { id: any; input: any; }[] | { id: any; }[]; launchType: any };
    if (!disabled && otherValue !== '') {
      answerDetails = {
        quizId: question.questionId,
        answers: [{ id: selectedAnswers, input: otherValue }],
        launchType: question.launchType
      };
    } else if (selectedAnswers > 0) {
      answerDetails = {
        quizId: question.questionId,
        answers: [{ id: selectedAnswers }],
        launchType: question.launchType
      };
    } else {
      answerDetails = {
        quizId: question.questionId,
        answers: [],
        launchType: question.launchType
      };
    }
    handleNext(answerDetails);
    setValue('');
    setSelectedAnswers([]);
  };

  const handleBlur = (e: any) => {
    if (e.target.value.length === 0) {
      setErrorMessage(true);
      setDisableNextButton(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleTextChange = (e: any) => {
    setOtherValue(e.target.value);
    if (e.target.value.length > 0) {
      setDisableNextButton(false);
      setErrorMessage(false);
    }
  };

  const handleBackButton = () => {
    const obj = { event: 'back', launchType: question.launchType};
    handleNext(obj);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#3467ff' : '#308fe8',
    },
  }));

  return (
    <Grid container maxWidth='100%'>
      <Box sx={{ position: { xs: 'inherit', md: 'absolute' }, left: '20px', top: '130px' }} >
        <Button
          sx={{ background: COLORS.LightBlue, minWidth: 140, mb: 1 }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => handleBackButton()}
          disabled={question?.event === 'back' ? true : false}
        >Back</Button>
      </Box>

      <Grid item xs={12}>
        <BorderLinearProgress value={question?.progress} variant='determinate' />
        {question?.header !== null ? question.header : null}
        <Container sx={{ background: '#fff', padding: '20px 40px', borderRadius: '12px' }}>
          <Typography sx={{ color: '#6941C6', fontSize: '18px', fontWeight: 500, mb: '24px',lineHeight:'28px' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.heading) }} />
          </Typography>

          <Typography mb={3}
            sx={{
              lineHeight: '28px', mt: '5px', mb: '24px', fontSize: '18px',
              fontWeight: 500, color: '#2C3446', fontStyle:'italic'
            }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.prompt) }} /> </Typography>

          <Typography mb={3}
            sx={{ lineHeight: '30px', mt: '5px', mb: '6px', fontSize: '20px', fontWeight: 500, color: '#2C3446' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.question) }} /> </Typography>

          {question?.informationSection && question?.informationSection !== null ? (
            <Box mb={6}>
              <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}> 
                <div dangerouslySetInnerHTML={{ __html: sanitize(question?.informationSection) }} /> </Alert>
            </Box>
          ) : null}

          {question?.warn && question?.warn !== null ?
            <Box>
              <Grid container mt={4} sx={{
                background: '#F8F5FF', borderRadius: '8px', display: 'flex', padding: '20px 12px',
                alignItems: 'flex-start', alignSelf: 'stretch'
              }}>
                <Grid item xs={0.5}>
                  <WarningAmberIcon color='error' />
                </Grid>
                <Grid item xs={11.5}>
                  <Typography >
                    <div dangerouslySetInnerHTML={{ __html: sanitize(question?.warn) }} />
                  </Typography>
                </Grid>          
              </Grid>
            </Box> : null}

          {question.info !== null ? (
            <Box sx={{ background: '#F8F5FF', p: '8px 12px', alignSelf: 'stretch', borderRadius: '8px', mb: '48px' }}>
              <Grid container>
                <Grid item xs={0.5}>
                  <InfoOutlinedIcon fontSize='small' color='primary' sx={{ mr: '10px' }} />
                </Grid>
                <Grid item xs={11.5} mt='2px'>
                  <Typography color='#1C1F24'>
                    <div dangerouslySetInnerHTML={{ __html: sanitize(question?.info) }} /></Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {question?.instruction !== null ?
            <Typography mb={3} sx={{ fontWeight: 400, fontSize: '18px', color: '#2C3446' }}>
              <div dangerouslySetInnerHTML={{ __html: sanitize(question?.instruction) }} /></Typography>
            : null}
          <Grid container item xs={12} direction='column'>
            <RadioGroup
              value={value}
              sx={{ width: '100%' }}
            >
              {answers[0]?.answers?.map((a: any) => {
                return (
                  <div key={a.id} onClick={() => handleGridClick(a)} >
                    <Grid item xs={12}
                      minHeight='50px'
                      sx={{
                        background: value === a.text ? '#F8F5FF' : '#EEF0F6',
                        borderRadius: '8px',
                        display: { sm: 'flex' },
                        justifyContent: 'space-between',
                        marginBottom: '5px',
                        border: value === a.text ?
                          '1px solid #9F84FF' : '1px solid #EEF0F6',
                        ':hover': {
                          cursor: 'pointer',
                          border: '1px solid #9F84FF'
                        }
                      }}
                    >
                      <ToggleButtonGroup
                        key={a.text}
                        value={value}
                        exclusive
                        onChange={() => handleGridClick(a)}
                        sx={{
                          borderRadius: '8px',
                          border: '1px solid #EEF0F6',
                          background: '#EEF0F6',
                          padding: 0,
                          '.MuiToggleButtonGroup-grouped:hover': {
                            background: '#EEF0F6!important',
                            border: '0px solid #9F84FF',
                            borderRadius: '0px!important',
                            color: '#9F84FF!important'
                          },
                          '.Mui-selected': {
                            background: '#F8F5FF!important',
                            borderRadius: '0px!important',
                            color: '#6941C6!important'
                          },
                          '.Mui-selected:hover': {
                            background: '#F8F5FF!important',
                            border: '0px solid #9F84FF',
                            borderRadius: '0px!important',
                            color: '#6941C6!important'
                          }
                        }}
                      >
                        <ToggleButton
                          value={a.text}
                          sx={{
                            display: 'flex',
                            padding: '10px 24px',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                            color: '#1C1F24!important',
                            fontSize: '16px',
                            textTransform: 'initial',
                            fontFamily: 'Graphik-Regular,sans-serif',
                            textAlign:'left'
                          }}
                        >
                          <div dangerouslySetInnerHTML={{ __html: sanitize(a?.text) }} />
                        </ToggleButton>

                      </ToggleButtonGroup>

                      {a.info && a.info !== null ? (
                        <Tooltip title={a.info} arrow placement='bottom-start' enterTouchDelay={0}>
                          <InfoOutlinedIcon fontSize='small' sx={{ position: 'relative', top: '10px', right: 4 }} />
                        </Tooltip>
                      ) : null}

                      {a.type === 'other' ? (
                        <Box sx={{ width: { sm: '50%' }, position: 'relative' }} py={1} mr={1}>
                          <TextField
                            autoComplete='other'
                            placeholder='Describe'
                            id='other'
                            autoFocus
                            fullWidth
                            disabled={disabled}
                            value={otherValue}
                            onChange={(e) => handleTextChange(e)}
                            onBlur={(e) => handleBlur(e)}
                            sx={{ background: '#fff' }}
                            inputProps={{ maxLength: 255 }}
                          />
                          {errorMessage ?
                            <Box sx={{ position: 'absolute', left: 0, right: 0, top: '90%' }} >
                              <Alert severity='error' >Please describe </Alert>
                            </Box>
                            :
                            null}
                        </Box>
                      ) : null}
                    </Grid>
                  </div>
                );
              })}
            </RadioGroup>
          </Grid>

          <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: errorMessage ? '50px' : '15px' }}>
            <Box sx={{ textAlign: { xs: 'center' } }} width='70%'>
              {!disableNextButton ?
                <Button onClick={handleSubmit} variant='text' sx={{ color: '#475467' }}>
                  <u>SKIP QUESTION</u>
                </Button> : null}
            </Box>
          </Grid>
        </Container >
        <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: errorMessage ? '50px' : '15px' }}>
          <Box sx={{ textAlign: { xs: 'center' } }} width='70%'>
            <Button variant='outlined' sx={{ marginRight: '8px', marginBottom: { xs: 2, sm: 0 } }} onClick={openModal}>
              Continue Later
            </Button>

          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleSelect;
