import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box,
  Button, CardMedia, Container, Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';

interface CustomizedState {
  understand: boolean;
}

const MCATIntro = () => {
  const [expanded, setExpanded] = useState('panel1');
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const {
    understand
  } = state || {};

  useEffect(() => {
    if (understand === undefined) {
      navigate(ROUTE.MCAT_TEST);
    }
  });

  const handleNavigation = () => {
    navigate(`${ROUTE.MCAT_ADVISORY}`, { state: { understand: true } });
  };

  const handleAccordionChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(panel);
  };

  return (

    <>
      <Grid item xs={1} display={'flex'} flexDirection={'row-reverse'} sx={{m:'20px 20px 0 0'}}>
        <Button variant='text' sx={{color:'#000'}}  onClick={() => navigate(`${ROUTE.DASHBOARD}`)}>X</Button>
      </Grid>
      <Container
        component='section'
        maxWidth='xl'
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} pb={2} maxWidth={'md'}>
        
          <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')} 
            sx={{background:'none', boxShadow:'none', border:'0px', m:'0px!important' }} >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ background: 'none!important', 
                borderLeft: expanded === 'panel1' ? '5px solid #FFC44D' : '5px solid #D9D9D9',
                borderRadius:'0px!important', pl:'30px',
                height:'auto!important',
                '.MuiAccordionSummary-content':{
                  flexDirection:'column'
                }
              }}
            >
              <Typography variant={'h4'} sx={{color:'#6D6CEC'}}> START STRONG WITH M-CAT</Typography>
              <Typography variant={'h2'}> Precision Care from Day One </Typography>
            </AccordionSummary>
            <AccordionDetails 
              sx={{ 
                borderLeft: expanded === 'panel1' ? '5px solid #FFC44D' : '5px solid #D9D9D9',
                pl:'30px' }}>
              <Typography sx={{fontWeight:400, fontSize:'18px', lineHeight:'28px'}}>
              Kick off with M-CAT and give your doctor a head start with essential insights into your 
              child&apos;s mental health. It&apos;s the smart way to ensure they receive care that&apos;s perfectly 
              tailored from the very first appointment.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}
            sx={{background:'none', boxShadow:'none', border:'0px', m:'0px!important' }}> 
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ background: 'none!important', 
                borderLeft: expanded === 'panel2' ? '5px solid #FFC44D' : '5px solid #D9D9D9',
                borderRadius:'0px!important',
                pl:'30px',
                height:'auto!important',
                // pb: expanded !== 'panel2' ? '60px' : '0px',
                // pt: expanded !== 'panel2' ? '60px' : '0px',
                '.MuiAccordionSummary-content':{
                  flexDirection:'column'
                }
              }}
            >
              <Typography variant={'h4'} sx={{color:'#6D6CEC'}}> INVEST TIME GAIN INSIGHTS</Typography>
              <Typography variant={'h2'}> M-CAT&apos;s Path to Personalized Care  </Typography>
            </AccordionSummary>
            <AccordionDetails 
              sx={{ 
                borderLeft: expanded === 'panel2' ? '5px solid #FFC44D' : '5px solid #D9D9D9',
                pl:'30px' }}>
              <Typography sx={{fontWeight:400, fontSize:'18px', lineHeight:'28px'}}>
              Dedicate 30 minutes to 1 hour to the M-CAT, and help us tailor the best care for your child 
              from the start. Your effort makes all the difference in their mental health journey.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}
            sx={{background:'none', boxShadow:'none', border:'0px', m:'0px!important' }}>
            <AccordionSummary
              aria-controls="panel3a-content"
              id="panel3a-header"
              sx={{ background: 'none!important', 
                borderLeft: expanded === 'panel3' ? '5px solid #FFC44D' : '5px solid #D9D9D9',
                borderRadius:'0px!important', pl:'30px',
                height:'auto!important',
                // pt: expanded !== 'panel3' ? '60px' : '0px',
                '.MuiAccordionSummary-content':{
                  flexDirection:'column'
                }
              }}
            >
              <Typography variant={'h4'} sx={{color:'#6D6CEC'}}> PRE-VISIT ESSENTIALS</Typography>
              <Typography variant={'h2'}> Setting the Stage for a Clinical Relationship  </Typography>
            </AccordionSummary>
            <AccordionDetails 
              sx={{ 
                borderLeft: expanded === 'panel3' ? '5px solid #FFC44D' : '5px solid #D9D9D9',
                pl:'30px' }}>
              <Typography sx={{fontWeight:400, fontSize:'18px', lineHeight:'28px'}}>
              Just a heads-up: Completing this questionnaire is a preliminary step and doesn&apos;t 
              start the formal doctor-patient relationship. That begins once we&apos;ve had our first 
              clinical appointment together.
              </Typography>
            </AccordionDetails>
          </Accordion>
        
          <Box mt={4} textAlign={'center'}>
            <Button onClick={handleNavigation}>Next</Button>
          </Box>
        </Box>
        
        <Grid container sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid item sm={4}>
            <CardMedia
              component={'img'}
              src='../images/mcatFooter.png'
              alt='main_logo'
              sx={{ width: 'auto', height: 200 }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MCATIntro;