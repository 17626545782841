import { Box, Button, CardMedia, Container, Typography } from '@mui/material';
import React from 'react';
import { ROUTE } from 'route/RouteEnums';

import logo from 'assets/images/logo.svg';
import resetLinkImage from 'assets/images/reset-email-image.svg';

const SuccessEmail = (props: any) => {
  return (
    <Container component='main' maxWidth='xl' sx={{ position: 'relative' }}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '655px',
          margin: 'auto',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <CardMedia
          component='img'
          image={logo}
          sx={{
            width: 'auto',
            height: { xs: '47px', md: '72px' },
            margin: { xs: '0 0 35px 0', md: '0 auto 48px auto' },
          }}
          alt='image'
        />
        <CardMedia
          component='img'
          image={resetLinkImage}
          sx={{
            width: { xs: '280px', sm: 'auto' },
            height: { xs: 'auto', sm: '250px', xl: '380px' },
            position: 'absolute',
            bottom: '0',
            left: '0',
          }}
          alt='image'
        />
        <Typography variant='h1' fontSize={60}>
          Reset link has been sent
        </Typography>
        <Typography variant='subtitle1' mb={4} textAlign={'center'}>
          Please check your email for a password reset link. Click on it to regain access to your account.
        </Typography>
        <Button variant='outlined' sx={{ minWidth: '266px' }} href={ROUTE.HOME}>
          Back to Website
        </Button>
      </Box>
    </Container>
  );
};

SuccessEmail.propTypes = {};

export default SuccessEmail;
