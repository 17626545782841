import { useEffect } from 'react';
import { Box, Grid, InputLabel, TextField} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { mcatIScoreSchema } from 'utils/schema';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { addMCATIScoreDetails, 
  getMCATIScoreAppointmentDetails, 
  getMCATIScoreDetails } from 'store/slices/patientInfoSlice';
import { useAppDispatch } from 'store/hooks';
import { toast } from 'react-toastify';

const MCATIScore = (props: any) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors},
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(mcatIScoreSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (props.patientId) {
      dispatch(getMCATIScoreDetails(props.patientId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            setValue('mcatIScore', response?.data?.mcatIScore ? `${response?.data?.mcatIScore}` : '', {
              shouldValidate: true,
              shouldDirty: true,
            });
            if(response.data.updatedOn){
              setValue(
                'updatedOn', dayjs(response?.data?.updatedOn).format('MM/DD/YYYY hh:mm A').toString(),
                {
                  shouldValidate: true,
                  shouldDirty: false,
                }
              );
            }
          }
        });
      dispatch(getMCATIScoreAppointmentDetails(props.patientId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            setValue(
              'updatedOn', response?.data[0]?.value,
              {
                shouldValidate: true,
                shouldDirty: false,
              });
          }
        });
    }
    //eslint-disable-next-line
  }, []);

  const getPayload = (fromData: any) => {
    return {
      patientId: Number(props.patientId),
      mcatIScore: Number(fromData.mcatIScore),
    };
  };

  const onSubmitForm = (formData: any) => {
    const payload = getPayload(formData);
    dispatch(addMCATIScoreDetails(payload))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const message = 'Patient details updated successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
        }
      });      
  };

 
  return (
    <Box component='form' width={'100%'} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='mcatIScore'>Score</InputLabel>
          <TextField
            fullWidth
            id='mcatIScore'
            placeholder=''
            {...register('mcatIScore')}
            error={errors?.mcatIScore ? true : false}
            value={watch('mcatIScore') ? watch('mcatIScore') : ''}
            disabled
            sx={{background:'#e0e0e0',borderRadius:1}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor='time'>Updated On<span style={{ color: 'red' }}>*</span></InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={12}>
              <DatePicker 
                disablePast
                value={watch('updatedOn') ? dayjs(watch('updatedOn')) : null}
                disabled
                sx={{background:'#e0e0e0',width:'100%',borderRadius:1}}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MCATIScore;
