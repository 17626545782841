export const insuranceProvider = [
  'Blue Cross Blue Shield (BCBS)',
  'United Health Care (UHC)',
  'Cigna',
  'TriCare',
  'Aetna',
  'Humana',
];
export const diagnoticianType = [
  { name: 'Type 1', value: 'type1' },
  { name: 'Type 2', value: 'type2' },
  { name: 'Type 3', value: 'type3' },
];

export const hearAboutUs = [
  'Pediatrician/Doctor',
  'Hospital/ER',
  'Therapist/Counsellor',
  'Insurance Payor',
  'School',
  'Word of mouth',
  'Google Search',
  'Facebook Ad',
  'Instagram Ad',
  'Press/News Article',
  'Other',
];

export const YES = 'yes';
export const NO = 'no';

export const GaurdianRelation = [
  { name: 'Mother', value: 'Mother' },
  { name: 'Father', value: 'Father' },
  { name: 'Legal Guardian', value: 'Guardian' },
];

export enum permissions {
  EDIT_PROFILE = 'EDIT_PROFILE',
  VIEW_PROFILE = 'VIEW_PROFILE',
  TAKE_MCAT = 'TAKE_MCAT',
  UPDATE_MCAT_SCORE = 'UPDATE_MCAT_SCORE',
  DOWNLOAD_MCAT = 'DOWNLOAD_MCAT',
  TAKE_MFSH = 'TAKE_MFSH',
  DOWNLOAD_MFSH = 'DOWNLOAD_MFSH',
  TAKE_WELLBEING = 'TAKE_WELLBEING',
  DOWNLOAD_WELLBEING = 'DOWNLOAD_WELLBEING',
  VIEW_WELLBING_RESULT = 'VIEW_WELLBING_RESULT',
  REQUEST_APPOINTMENT = 'REQUEST_APPOINTMENT',
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  VIEW_APPOINTMENT = 'VIEW_APPOINTMENT',
  SAVE_APPOINTMENT_DATA = 'SAVE_APPOINTMENT_DATA',
  VIEW_APPOINTMENT_DATA = 'VIEW_APPOINTMENT_DATA',
  SAVE_EHR_DETAILS = 'SAVE_EHR_DETAILS',
  VIEW_EHR_DETAILS = 'VIEW_EHR_DETAILS',
  VIEW_DOCUMENTS = 'VIEW_DOCUMENTS',
  SEND_REMINDERS = 'SEND_REMINDERS',
  VIEW_PATIENTS = 'VIEW_PATIENTS',
  CREATE_PATIENT = 'CREATE_PATIENT',
}
