import { Route, Routes, Navigate } from 'react-router-dom';

import { ROUTE } from './RouteEnums';
import AuthLayout from 'components/AuthLayout';
import PageLayout from 'components/Layout';
import Profile from 'pages/Profile';
import SignOut from 'pages/SignOut';
import EmailVerified from 'pages/SignUp/EmailVerified';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import SignUp from 'pages/SignUp';
import MigratedSignUp from 'pages/SignUp/SignUp';
import SignIn from 'pages/SignIn';
import EmailVerification from 'pages/SignUp/EmailVerfication';
import Dashboard from 'pages/Dashboard';
import SuccessEmail from 'pages/ForgotPassword/SuccessEmail';
import SuccessPassword from 'pages/ResetPassword/SuccessPassword';
import Treatment from 'pages/Treatment';
import Appointment from 'pages/Appointment';
import ProfileDashboard from 'pages/Profile/ProfileDashboard';
import Documents from 'pages/Documents';
import MCAT from 'pages/M-CAT';
import MCATIntro from 'pages/M-CAT/intro';
import Advisory from 'pages/M-CAT/advisory';
import Navigation from 'pages/M-CAT/navigate';
import Assessment from 'pages/M-CAT/assessment';
import WellBeing from 'pages/Wellbeing';
import DiyPosts from 'pages/DiyPosts';
import AdminDashboard from 'pages/Admin/Dashboard';
import PatientInfo from 'pages/Admin/PatientInfo';
import { permissions } from 'utils/constants';
import PermissionsProvider from 'components/PermissionsProvider';
import { InstallPWA } from 'components/PWA/InstallPWA';
import WellnessDashboard from 'pages/Profile/Wellness';
import MCATiDashboard from 'pages/Profile/MCAT-i';
import ChatboxAdmin from 'components/ChatboxAdmin';
import AppointmentDetails from 'pages/Admin/AppointmentDetails';
import SystemVitals from 'pages/SystemVitals';
import AdminProfile from 'pages/Admin/AdminProfile';
import TaskList from 'pages/Admin/Task';
import ConversationList from 'pages/Admin/Conversations';
import MCATResult from 'pages/Profile/MCATResult';

const OnboardingRouter = () => {
  return (
    <Routes>
      <Route path={ROUTE.INSTALL} element={<InstallPWA />} />
      <Route element={<PageLayout />}>
        <Route path={ROUTE.FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTE.RESETPASSWORD} element={<ResetPassword />} />
        <Route path={ROUTE.LOGIN} element={<SignIn />} index />
      </Route>
      <Route path={ROUTE.SIGNUP} element={<SignUp />} />
      <Route path={ROUTE.MIGRATION_SIGNUP} element={<MigratedSignUp/>}/>
      <Route path={ROUTE.VALIDATE_USER} element={<EmailVerified />} />
      <Route path={ROUTE.EMAILVERIFICATION} element={<EmailVerification />} />
      <Route path={ROUTE.SUCCESSEMAIL} element={<SuccessEmail />} />
      <Route path={ROUTE.SUCCESSPASSWORD} element={<SuccessPassword />} />

      <Route path={ROUTE.SIGN_OUT} element={<SignOut />} />

      {/* protected  routes after login... */}
      <Route element={<AuthLayout />}>
        {/* Added edit profile permission which is only for patient so admin can not visit this route */}
        <Route element={<PermissionsProvider permissions={[permissions.EDIT_PROFILE]} />}>
          <Route path={ROUTE.DASHBOARD} element={<Dashboard />} />
        </Route>
        <Route element={<PermissionsProvider permissions={[permissions.EDIT_PROFILE]} />}>
          <Route path={ROUTE.TREATMENT} element={<Treatment />} />
        </Route>
        {/* Added document permission related to document  */}
        <Route element={<PermissionsProvider permissions={[permissions.VIEW_DOCUMENTS]} />}>
          <Route path={ROUTE.DOCUMENTS} element={<Documents />} />
        </Route>
      
        {/* Added multiple permission related to profile  */}
        <Route element={<PermissionsProvider permissions={[permissions.EDIT_PROFILE, permissions.VIEW_PROFILE]} />}>
          <Route path={ROUTE.PROFILE}>
            <Route index element={<Profile for='patientInfo' />} />
            <Route path={ROUTE.PATIENT_INFORMATION} element={<Profile for='patientInfo' />} />
            <Route path={ROUTE.GUARDIAN_INFORMATION} element={<Profile for='guardianInfo' />} />
            <Route path={ROUTE.PROVIDER_INFORMATION} element={<Profile for='providerInfo' />} />
            <Route path={ROUTE.PHARMACY_DETAILS} element={<Profile for='pharmacyDetails' />} />
            <Route path={ROUTE.INSURANCE_DETAILS} element={<Profile for='insuranceDetails' />} />
            <Route path={ROUTE.WELLNESS_DASHBOARD} element={<WellnessDashboard/>}/>
            <Route path={ROUTE.MCAT_DASHBOARD} element={<MCATiDashboard/>}/>
            <Route path={ROUTE.MCAT_RESULT} element={<MCATResult/>}/>
          </Route>
        </Route>
        {/* <Route path={ROUTE.HELP} element={<Help />} /> */}
        {/* Added multiple permission related to dashboard widgets  */}
        <Route
          element={
            <PermissionsProvider
              permissions={[
                permissions.TAKE_MCAT,
                permissions.TAKE_MFSH,
                permissions.TAKE_WELLBEING,
                permissions.VIEW_EHR_DETAILS,
              ]}
            />
          }
        >
          <Route path={ROUTE.PROFILE_DASHBOARD} element={<ProfileDashboard />} />
        </Route>
        <Route element={<PermissionsProvider permissions={[permissions.VIEW_DOCUMENTS]} />}>
          <Route path={ROUTE.DOCUMENTS} element={<Documents key={Date.now()} />} />
        </Route>
        <Route element={<PermissionsProvider permissions={[permissions.TAKE_MCAT]} />}>
          <Route path={ROUTE.MCAT_TEST} element={<MCAT />} />
          <Route path={ROUTE.MCAT_INTRO} element={<MCATIntro />} />
          <Route path={ROUTE.MCAT_ADVISORY} element={<Advisory />} />
          <Route path={ROUTE.MCAT_NAVIGATE} element={<Navigation />} />
          
          <Route path={ROUTE.MCAT_ASSESSMENT} element={<Assessment />} />
        </Route>

        {/* WellBeing */}
        <Route element={<PermissionsProvider permissions={[permissions.TAKE_WELLBEING]} />}>
          <Route path={ROUTE.WELLBEING} element={<WellBeing />} />
          <Route path={ROUTE.WELLBEING_ASSESSMENT} element={<Assessment />} />
        </Route>
        <Route path={ROUTE.DIY_POSTS} element={<DiyPosts />} />
        {/* <Route path={ROUTE.FAQ} element={<Faq />} /> */}
        {/* Added view patients list permission which is used only for admin  */}
        <Route element={<PermissionsProvider permissions={[permissions.VIEW_PATIENTS]} />}>
          <Route path={ROUTE.ADMIN_DASHBOARD} element={<AdminDashboard />} />
        </Route>
        {/* Added multiple permission in case have some of them allocated other will restricted at component level */}
        <Route
          element={
            <PermissionsProvider
              permissions={[
                permissions.UPDATE_MCAT_SCORE,
                permissions.DOWNLOAD_MCAT,
                permissions.DOWNLOAD_MFSH,
                permissions.DOWNLOAD_WELLBEING,
                permissions.CREATE_APPOINTMENT,
                permissions.SAVE_EHR_DETAILS,
                permissions.SEND_REMINDERS,
                permissions.SAVE_APPOINTMENT_DATA,
              ]}
            />
          }
        >
          <Route path={`${ROUTE.ADMIN_PATIENT_INFO}/:id`} element={<PatientInfo />} />
          <Route path={`${ROUTE.ADMIN_MESSAGES}`} element={<ChatboxAdmin />} />
          <Route path={`${ROUTE.ADMIN_TASKLIST}`} element={<TaskList />} />
          <Route path={`${ROUTE.ADMIN_CONVERSATION_LIST}`} element={<ConversationList />} />
      
          <Route path={`${ROUTE.ADMIN_APPOINTMENT_DETAILS}/:id`} element={<AppointmentDetails />} />
          <Route path={`${ROUTE.ADMIN_PROFILE}`} element={<AdminProfile />} />
        </Route>
        {/* Added only one permission which is used for admin only so patient can not visit these routes */}
        <Route element={<PermissionsProvider permissions={[permissions.CREATE_PATIENT]} />}>
          <Route path={ROUTE.ADMIN_PATIENT_PROFILE}>
            <Route index element={<Profile for='patientInfo' from='admin' />} />
            <Route path={ROUTE.ADMIN_PATIENT_INFORMATION} element={<Profile for='patientInfo' from='admin' />} />
            <Route path={ROUTE.ADMIN_GUARDIAN_INFORMATION} element={<Profile for='guardianInfo' from='admin' />} />
            <Route path={ROUTE.ADMIN_PROVIDER_INFORMATION} element={<Profile for='providerInfo' from='admin' />} />
            <Route path={ROUTE.ADMIN_PHARMACY_DETAILS} element={<Profile for='pharmacyDetails' from='admin' />} />
            <Route path={ROUTE.ADMIN_INSURANCE_DETAILS} element={<Profile for='insuranceDetails' from='admin' />} />
          </Route>
          
        </Route>
        <Route path='*' element={<Navigate to="/dashboard" replace />} />
        <Route path={ROUTE.SYSTEM_VITALS} element={<SystemVitals/>} />
      </Route>

      {/* not part of a app */}
      {/* <Route path={ROUTE.THEME} element={<ThemePage />} /> */}
      <Route path='*' element={<Navigate to="/signout" replace />} />
    </Routes>
  );
};

export default OnboardingRouter;
