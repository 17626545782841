import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';
import React from 'react';

interface DomainInfoProps {
  info: any;
  handleDomainModal: any;
  imageId:any;
}

const DomainInfo: React.FC<DomainInfoProps> = ({ info, handleDomainModal, imageId }) => {
  const imagePath = `../images/mcat/${imageId}.svg`;
  return (
    <>
      <CardMedia
        component='img'
        image= {imagePath}
        sx={{
          width: '170px',
          height: 'auto',
          margin: { md: 'auto' },
          display: { xs: 'none', sm: 'block' },        
        }}
        alt='domain info'        
      />
      <Typography textAlign='center' mt='24px' sx={{fontSize:'36px', fontWeight:600, color:'#101828'}}>
        {info?.heading}</Typography>
      {info?.subTitle !== null ? 
        <Typography textAlign='center' mt='24px' sx={{ fontSize: '22px', fontWeight: 600, color:'#1C1F24' }}>
          {info?.subTitle}</Typography> : ''}
      <Container sx={{ background: '#fff', padding: '20px 40px', borderRadius: '12px', mt:'36px' }}>        
        {info?.wellnessSubtitle ?
          <Box margin={'5px 10px'}>
            <Typography textAlign={'left'} sx={{ fontSize: '18px', color: '#2C3446', fontWeight: 700 }}>
              {info?.wellnessSubtitle}
            </Typography>
          </Box>
          : null} 
        <Box margin={'5px 10px'}>
          <Typography textAlign={'left'} sx={{fontSize:'18px', color:'#2C3446'}}>{info?.question}</Typography>
        </Box>
        <Grid container item xs={12} mt={4} sx={{ justifyContent: 'center' }}>
          <Button onClick={handleDomainModal}>
            {info?.type ==='information' ? info?.buttonText : 'Next'}
          </Button>
        </Grid>
      </Container>
    </>
  );
};

export default DomainInfo;