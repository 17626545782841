import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';

export const getPatientsListAPI = (data:any) => {
  return apiInterceptor.get(`${API_URL.PATIENTS_LIST}?listType=${data.listType}&date=${data.date}`)
    .then((response: any) => {
      return response;
    });
};
export const getPatientDetailsAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.PATIENT_EHR_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};
export const getDrChronoChartIdDetailsAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.DR_CHRONO_CHART_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const getPaymentStatusAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.PAYMENT_STATUS}/${patientId}`).then((response: any) => {
    return response;
  });
};
export const getUpcomingAppointmentAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.UPCOMING_APPOINTMENT_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};
export const getPostMeetingDetailsAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.POST_MEETING_DETAILS}/admin/${patientId}`).then((response: any) => {
    return response;
  });
};
export const getMCATIScoreAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.MCAT_I_SCORE_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const getMCATIScoreAppointmentAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.MCAT_I_SCORE_APPOINTMENT_DETAILS}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const addDrChronoChartDetailsAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.DR_CHRONO_CHART_DETAILS}`, data).then((response: any) => {
    return response;
  });
};

export const addPaymentStatusAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.PAYMENT_STATUS}`, data).then((response: any) => {
    return response;
  });
};
export const scheduleUpcomingAppointmentAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.UPCOMING_APPOINTMENT_DETAILS}`, data).then((response: any) => {
    return response;
  });
};
export const addPostMeetingAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.POST_MEETING_DETAILS}`, data).then((response: any) => {
    return response;
  });
};

export const addMCATIScoreAPI = (data: any) => {
  return apiInterceptor.post(`${API_URL.MCAT_I_SCORE_DETAILS}`, data).then((response: any) => {
    return response;
  });
};

export const sendDocumentReminderAPI = (patientId: number) => {
  return apiInterceptor.post(`${API_URL.SEND_DOCUMENT_REMINDER}/${patientId}`).then((response: any) => {
    return response;
  });
};

export const sendAssessmentReminderAPI = (data: { patientId: number; type: string }) => {
  const URL = `${API_URL.SEND_ASSESSNEBT_REMINDER}/${data?.type}/1.0/send/reminder/${data?.patientId}`;
  return apiInterceptor.post(`${URL}`).then((response: any) => {
    return response;
  });
};

export const downloadPDFAPI = (data: { patientId: number; type: string }) => {
  const URL = `${API_URL.SEND_ASSESSNEBT_REMINDER}/${data?.type}/1.0/report/${data?.patientId}`;
  return apiInterceptor.get(`${URL}`).then((response: any) => {
    return response;
  });
};

export const downloadWellnessParentPDFAPI = (data: { patientId: number; type: string }) => {
  const URL = `${API_URL.SEND_ASSESSNEBT_REMINDER}/${data?.type}/1.0/report/${data?.patientId}/parent`;
  return apiInterceptor.get(`${URL}`).then((response: any) => {
    return response;
  });
};


export const downloadWellnessChildPDFAPI = (data: { patientId: number; type: string }) => {
  const URL = `${API_URL.SEND_ASSESSNEBT_REMINDER}/${data?.type}/1.0/report/${data?.patientId}/child`;
  return apiInterceptor.get(`${URL}`).then((response: any) => {
    return response;
  });
};

export const getPostMeetingSummaryAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.POST_MEETING_SUMMARY}/${patientId}`).then((response: any) => {
    return response;
  });
};


export const syncProfileAPI = (patientId: number) => {
  return apiInterceptor.get(`${API_URL.SYNC_PROFILE}/${patientId}`).then((response: any) => {
    return response;
  });
};
