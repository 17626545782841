import React from 'react';
import { Card, CardContent, Container, Grid, GridProps, Typography, styled } from '@mui/material';

const DashboardFooter = (props: any) => {

  const StyleGridItem = styled(Grid)<GridProps>(({ theme }) => ({
    background: 'url("images/Ellipse 2.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }));

  return (
    <Container component={'footer'} maxWidth='xl'>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Grid container spacing={1} >
                <StyleGridItem item xs={3}>
                  <Typography variant='h3' fontWeight={600}
                    sx={{ xs: { fontSize: '20px', sm:'22px' } }}
                  >
                    741-741
                  </Typography>
                </StyleGridItem>
                <Grid item xs={9}>
                  <Typography variant='h2'>Crisis care text line</Typography>
                  <Typography variant='body1'>
                    For any emotional distress, text on this number to connect with a counsellor immediately.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Grid container spacing={2}>
                <StyleGridItem item xs={3}>
                  <Typography variant='h3' fontSize={22} fontWeight={600} >
                    988
                  </Typography>
                </StyleGridItem>
                <Grid item xs={9}>
                  <Typography variant='h2'>National suicide hotline</Typography>
                  <Typography variant='body1'>
                    Call or text the National Suicide Prevention Lifeline for 24/7 emotional support.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
            <CardContent>
              <Grid container spacing={2}>
                <StyleGridItem item xs={3}>
                  <Typography variant='h3' fontSize={22} fontWeight={600} >
                    911
                  </Typography>
                </StyleGridItem>
                <Grid item xs={9}>
                  <Typography variant='h2'>ER</Typography>
                  <Typography variant='body1'>
                    For any medical or mental health emergency, call 911 or go to your local ER.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashboardFooter;
