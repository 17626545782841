import styled from '@emotion/styled';
import {
  Card,
  CardContent,
  CardMedia,
  CardProps,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  TextField,
} from '@mui/material';
import { Container } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import Details from './Details';
import UpcomingAppointment from './UpcomingAppointment';
import PostMeeting from './PostMeeting';
import { getProfileImageAPI } from 'services/profileServices';
import { useParams } from 'react-router-dom';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { Restricted } from 'components/Restricted';
import { permissions } from 'utils/constants';
import PatientDetails from './PatientDetails';
import TabPanel from 'components/TabPanel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getVitalsDetails } from 'store/slices/vitalSlice';
import { vitalSchema } from 'utils/schema';

const PatientInfo = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [profilePicture, setProfilePicture] = useState<any>(null);

  const patientId = useParams();

  const {
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(vitalSchema),
    mode: 'all',
  });
  const dispatch = useAppDispatch();

  const GetVitalDetails = () => {
    dispatch(getVitalsDetails(patientId?.id))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          Object.keys(response?.data).forEach((key: any) => {
            if (response.data[key] === null) setValue(key, '', { shouldValidate: false });
            else setValue(key, response.data[key], { shouldValidate: false });
          });
        } 
      });
  };

  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #EFF4FF',
    borderRadius: 12,
    background: '#FFF',
    margin: '12px 16px',
    position: 'relative',
    zIndex: 99,
    padding: 20,
    '& .MuiTypography-root': {
      position: 'relative',
    },
    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },
    '.MuiTypography-h2': {
      fontSize: 20,
    },
    '& .Mui-selected':{
      background:'#9F84FF',
      color:'#FFF!important',
      borderRadius:'23px',
      width:'100%'
    },
    '& .MuiTab-root':{
      margin:'0 60px!important'
    }
  }));

  useEffect(() =>{
    getProfileImageAPI(patientId?.id)
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setProfilePicture(response.data);
        } 
      });
    GetVitalDetails();
    //eslint-disable-next-line
  },[]);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Page backDir={ROUTE.ADMIN_DASHBOARD} buttonText='Dashboard'>
      <Container component='main' maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={10} display={'flex'} alignItems={'center'}>
            <Typography variant='h5' gutterBottom>
              Patient Info
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton>
              <CardMedia
                component={'img'}
                src= {profilePicture !== null ? `data:image/png;base64, ${profilePicture}` :'/images/Group 9513.png'}
                alt='patient picture'
                sx={{ border: '1px solid #333',
                  borderRadius: '50%', 
                  height: {xs:'70px', md:'106px'},
                  width: {xs:'70px', md:'106px'},
                  cursor:'default' }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <StyleCard sx={{ borderTop: '12px solid #FFC44D !important' }}>
              <CardContent>
                <PatientDetails patientId={patientId.id} />
              </CardContent>
            </StyleCard>
            <StyleCard>
              <CardContent sx={{ opacity: 0.5 }}>
                <Typography variant='h2' 
                  sx={{ fontSize: 20, fontWeight: 500, lineHeight: 1.4 }} mb={2}>System Vitals Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor='weight'>Weight (in pounds)</InputLabel>
                    <TextField id='weight' variant='outlined'
                      fullWidth 
                      value={watch('weight') ? watch('weight')+' lb':''}
                      disabled 
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor='height'>Height (in)</InputLabel>
                    <TextField id='height' variant='outlined' fullWidth 
                      value={watch('height') ? watch('height')+' in':''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor='systolic'>Systolic Blood Pressure</InputLabel>
                    <TextField
                      id='systolic'
                      variant='outlined'
                      fullWidth
                      value={watch('bloodPressure1') ? watch('bloodPressure1')+' mmHg':''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor='diastolic'>Diastolic Blood Pressure</InputLabel>
                    <TextField
                      id='diastolic'
                      variant='outlined'
                      fullWidth
                      value={watch('bloodPressure2') ? watch('bloodPressure2')+' mmHg':''}
                      disabled
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>

            <StyleCard>
              <CardContent>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  sx={{background:'#F9FAFB', borderRadius:'23px'}}
                  TabIndicatorProps={{
                    style: {
                      background:'#F9FAFB'
                    }
                  }}
                  centered
                >
                  <Tab
                    label={'Patient Profile & Appointments'}
                  />
                  <Tab
                    label={'Post-Visit Summary & Treatment Plan'}
                  />
                </Tabs>
                
                <TabPanel value={selectedTab} index={0}>
                  <Restricted permission={permissions.SAVE_EHR_DETAILS}>
                    <Grid container borderBottom={'1px solid #EAECF0'} paddingBottom={2.5} paddingTop={2.5}>
                      <Details patientId={patientId.id} />
                    </Grid>
                  </Restricted>

                  <Restricted permission={permissions.CREATE_APPOINTMENT}>
                    <Grid container borderBottom={'1px solid #EAECF0'} paddingBottom={2.5} paddingTop={2.5}>
                      <UpcomingAppointment
                        patientId={patientId.id}
                      />
                    </Grid>
                  </Restricted>
                </TabPanel>
                
                <TabPanel value={selectedTab} index={1}>
                  <Restricted permission={permissions.SAVE_APPOINTMENT_DATA}>
                    <Grid container borderBottom={'1px solid #EAECF0'} paddingBottom={2.5} paddingTop={2.5}>
                      <PostMeeting patientId={patientId.id} />
                    </Grid>
                  </Restricted>
                </TabPanel>

              </CardContent>
            </StyleCard>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PatientInfo;
