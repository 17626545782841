import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'utils/AuthContext';

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: React.JSX.Element;
};
export type LoggedInUserProps = {
  loggedInUserPath: string;
  outlet: React.JSX.Element;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  const auth = useAuth();
  if (auth.isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}

export function AuthRoute({ loggedInUserPath, outlet }: LoggedInUserProps) {
  const auth = useAuth();
  if (!auth.isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: loggedInUserPath }} />;
  }
}
