import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { sanitize } from 'dompurify';

import { afterMeetingSchema } from 'utils/schema';
import { useAppDispatch } from 'store/hooks';
import { addPostMeetingDetails, getPostMeetingDetails, getPostMeetingSummary } from 'store/slices/patientInfoSlice';
import COLORS from 'theme/colors';
import MCATIScore from './MCATIScore';
import { Restricted } from 'components/Restricted';
import { permissions } from 'utils/constants';



const PostMeeting = (props: any) => {
  dayjs.extend(utc);
  const dispatch = useAppDispatch();
  const [meetingDetails, setMeetingDetails] = useState<any>();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(afterMeetingSchema),
    mode: 'all',
  });

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'medications', // unique name for your Field Array
  });

  const { fields: diagnosisFields } = useFieldArray({
    control,
    name: 'diagnosis',
  });

  useEffect(() => {
    getData();
    getSummaryData();
    //eslint-disable-next-line
  }, []);

  const getData = ()=>{
    dispatch(getPostMeetingDetails(props.patientId))
      .unwrap()
      .then(async (response: any) => {
        if (response?.status === 200) {
          const medications = response?.data?.medications
            ? await JSON.parse(response?.data?.medications)
            : [{ medication: '', sig: '' }];

          const diagnosis = response?.data?.diagnosis
            ? await JSON.parse(response?.data?.diagnosis)
            : [{ diagnosis: '', diagnosedDate: '' }];
          setValue('medications', medications, { shouldValidate: true, shouldDirty: false });
          setValue('pendingBalance', response?.data?.pendingBalance ? response?.data?.pendingBalance.toString() : '', {
            shouldValidate: true,
            shouldDirty: false,
          });
          setValue('diagnosis', diagnosis, { shouldValidate: true, shouldDirty: false });
          setValue('others', response?.data?.others ? response?.data?.others.toString() : '', {
            shouldValidate: true,
            shouldDirty: false,
          });
        }
      });
  };

  const getSummaryData = ()=>{
    dispatch(getPostMeetingSummary(props.patientId))
      .unwrap()
      .then(async (response: any) => {
        if (response?.status === 200) {
          setMeetingDetails(response?.data);
          // eslint-disable-next-line max-len
          setValue('treatmentPlan', response?.data?.medicationTreatmentPlan ? response?.data?.medicationTreatmentPlan.toString().replaceAll(',',', ').replaceAll('&amp;','&') : '', {
            shouldValidate: true,
            shouldDirty: false,
          });
          // eslint-disable-next-line max-len
          setValue('referrals', response?.data?.referrals ? response?.data?.referrals.toString().replaceAll(',',', ').replaceAll('&amp;','&'): '', 
            { 
              shouldValidate: true, 
              shouldDirty: false 
            });
          setValue('followup', response?.data?.followup ? response?.data?.followup.toString().replaceAll(',',', '): '', 
            { 
              shouldValidate: true, 
              shouldDirty: false 
            });
          // eslint-disable-next-line max-len
          setValue('roiForms', response?.data?.roiForms ? response?.data?.roiForms.toString().replaceAll(',',', ').replaceAll('&amp;','&') : '', 
            { 
              shouldValidate: true, 
              shouldDirty: false 
            });
          // eslint-disable-next-line max-len
          setValue('ratingScales', response?.data?.ratingScales ? response?.data?.ratingScales.toString().replaceAll(',',', ').replaceAll('&amp;','&') : '', 
            { 
              shouldValidate: true, 
              shouldDirty: false 
            });
        }
      });
  };

  const getPayload = (formData: any) => {
    delete formData.medications;
    delete formData.diagnosis;
    delete formData.pendingBalance;
    return {
      patientId: props.patientId,
      ...formData,
    };
  };

  const onSubmitForm = (formData: any) => {
    const payload = getPayload(formData);
    dispatch(addPostMeetingDetails(payload))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const message = 'Post meeting details updated successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
          getData();
        }
      });
  };



  return (
    <Box width={'100%'} component='form' onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
      <Grid container spacing={2} display={'flex'}>
        <Grid item xs={12}>
          {/* medication  accordion*/}
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel0a-content'
              id='panel0a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
                Medication Treatment Plan
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InputLabel htmlFor='treatmentPlan'>Medication Treatment Plan
                <span style={{ color: 'red' }}>*</span></InputLabel>
              <TextField
                id='treatmentPlan'
                fullWidth
                multiline
                rows={4}
                variant='filled'
                {...register('treatmentPlan')}
                value={watch('treatmentPlan')} 
                disabled
              />
            </AccordionDetails>
          </Accordion>

          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
               Medications
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <Grid container spacing={2}
                  sx={{
                    background: '#F2F4F7',
                    paddingBottom: '10px!important',
                    paddingLeft: '0!important',
                    maxWidth: '100%',
                    margin: '0'
                  }}>
                  <Grid item xs={6} display='flex' alignItems='center'>
                    <Typography variant='subtitle1'>Medication</Typography>
                  </Grid>
                  <Grid item xs={6} display='flex' alignItems='center'>
                    <Typography variant='subtitle1'>SIG</Typography>
                  </Grid>
                </Grid>
                {fields.map((data, index) => {
                  return (
                    <Grid container key={data?.id}
                      sx={{
                        border: '1px solid',
                        borderColor: COLORS.BorderGray,
                      }}>
                      <Grid item xs={6} px={2} py={1} >
                        <Typography variant='h4'>
                          {data?.medication === '' ? 'No Data': data?.medication}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} px={2} py={1}>
                        <Typography variant='h4'>
                          {data?.sig}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
                Diagnoses
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <Grid container spacing={2}
                  sx={{
                    background: '#F2F4F7',
                    paddingBottom: '10px!important',
                    paddingLeft: '0!important',
                    maxWidth: '100%',
                    margin: '0'
                  }}>
                  <Grid item xs={6} display='flex' alignItems='center'>
                    <Typography variant='subtitle1'>Diagnosis</Typography>
                  </Grid>
                  <Grid item xs={6} display='flex' alignItems='center'>
                    <Typography variant='subtitle1'>Date Diagnosed</Typography>
                  </Grid>
                </Grid>
                {diagnosisFields.map((data, index) => {
                  return (
                    <Grid container key={data?.id}
                      sx={{
                        border: '1px solid',
                        borderColor: COLORS.BorderGray,
                      }}>
                      <Grid item xs={6} px={2} py={1} >
                        <Typography variant='h4'>
                          {data?.diagnosis === '' ? 'No Data' : data?.diagnosis}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} px={2} py={1}>
                        <Typography variant='h4'>
                          {data?.diagnosedDate}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel3a-content'
              id='panel3a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
                Labs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container
                    sx={{
                      border: '1px solid',
                      borderColor: COLORS.BorderGray,
                    }}>
                    <Grid item xs={3} px={2} py={1} sx={{background: '#F2F4F7'}}>
                      <Typography variant='h4'>
                    Labs Ordered
                      </Typography>
                    </Grid>
                    <Grid item xs={9} px={2} py={1}>
                      <Typography variant='h4'>
                        {meetingDetails?.labsOrdered ? meetingDetails?.labsOrdered?.replaceAll(',',', ') : 'No Data'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container
                    sx={{
                      border: '1px solid',
                      borderColor: COLORS.BorderGray,
                    }}>
                    <Grid item xs={3} px={2} py={1} sx={{background: '#F2F4F7'}}>
                      <Typography variant='h4'>
                      Instructions
                      </Typography>
                    </Grid>
                    <Grid item xs={9} px={2} py={1}>
                      <Typography variant='h4'>
                        {meetingDetails?.labInstructions?.replaceAll(',',', ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container
                    sx={{
                      border: '1px solid',
                      borderColor: COLORS.BorderGray,
                    }}>
                    <Grid item xs={3} px={2} py={1} sx={{background: '#F2F4F7'}}>
                      <Typography variant='h4'>
                      Where and How
                      </Typography>
                    </Grid>
                    <Grid item xs={9} px={2} py={1}>
                      <Typography variant='h4'>
                        <div dangerouslySetInnerHTML=
                          {{ __html: sanitize(meetingDetails?.labswhereAndHow?.replaceAll(',', ', ',),
                            {ADD_ATTR:['target']}) }} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel4a-content'
              id='panel4a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
              Referrals
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel htmlFor='additionalExpertise'>Additional Expertise</InputLabel>
                  <TextField
                    id='additionalExpertise'
                    fullWidth
                    placeholder=''
                    {...register('referrals')}
                    value={watch('referrals')}
                    disabled
                    multiline
                    variant='filled'
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel5a-content'
              id='panel5a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
              Rating Scales
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel htmlFor='ratingScales'>Rating Scales /Questionnaire</InputLabel>
                  <TextField
                    id='ratingScales'
                    fullWidth
                    placeholder=''
                    multiline
                    {...register('ratingScales')}
                    value={watch('ratingScales')}
                    disabled
                    variant='filled'
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel6a-content'
              id='panel6a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
              Coordination of Care
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel htmlFor='roiForms'>ROI Forms</InputLabel>
                  <TextField
                    id='roiForms'
                    fullWidth
                    placeholder=''
                    multiline
                    {...register('roiForms')}
                    value={watch('roiForms')}
                    disabled
                    variant='filled'
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel7a-content'
              id='panel7a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
              Follow-Up
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel htmlFor='follow-up'>Follow-Up</InputLabel>
                  <TextField
                    id='follow-up'
                    fullWidth
                    placeholder=''
                    multiline
                    variant='filled'
                    {...register('followup')}
                    value={watch('followup')}
                    disabled
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion  defaultExpanded
            sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px!important', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel8a-content'
              id='panel8a-header'
              sx={{ background: '#fff!important' }}
            >
              <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
              MCAT i-Score
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Restricted permission={permissions.UPDATE_MCAT_SCORE}>
                <MCATIScore patientId={props.patientId}/>
              </Restricted>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PostMeeting;
