import { Fragment, ReactNode } from 'react';

import { useAuth } from 'utils/AuthContext';

export function Restricted({ children, permission }: { children: ReactNode; permission: string }) {
  const { user } = useAuth();
  const userPermissions = user?.permissions.length ? user?.permissions : [];

  const canAccess = userPermissions.includes(permission);
  if (canAccess) return <Fragment>{children}</Fragment>;
  else return null;
}
