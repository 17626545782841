import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PrimaryGaurdian from './PrimaryGaurdian';
import SecondaryGaurdian from './SecondaryGaurdian';
import EmergencyGaudian from './EmergencyGaudian';
import TabPanel from 'components/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import { NO, YES } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteGuardianDetails, getGuardianDetails, updateGuardianDetails } from 'store/slices/userSlice';
import { toast } from 'react-toastify';
import { useAuth } from 'utils/AuthContext';
import { useLocation } from 'react-router-dom';
import { uploadGuradianImageAPI } from 'services/profileServices';
import GuardianProfilePicture from './GuardianProfilePicture';
import { EmergencyLabel, PrimaryLabel, SecoundaryLabel } from 'utils/functions';

//GaurdianInformation component
const GuardianInfo = (props: any) => {
  // states Gaurdian Info
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDeleteProfileDailog, setOpenDeleteProfileDailog] = useState(false);
  const [SGEmergencyContactValue, setSGEmergencyContactValue] = useState('');
  const [PGEmergecyContact, setPGEmergecyContact] = useState('');
  const guardians:any = useAppSelector((state) => state.user.guardians);
  const [primaryPicture, setPrimaryPicture] = useState<any>(null);
  const [secondaryPicture, setSecondaryPicture] = useState<any>(null);
  const [emergencyPicture, setEmergencyPicture] = useState<any>(null);
  const [primaryGuradianInfoAvailable, setPrimaryGuradianInfoAvailable] = useState(false);
  const [secondaryGuardianInfoAvailable,setSecondaryGuardianInfoAvailable] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { state } = useLocation();

  //handle Tab Change
  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
    if (newValue === 1) {
      setSGEmergencyContactValue(YES);
    }
  };

  //handle EGForm
  const EGForm = (res: any) => {
    if (res) {
      setSelectedTab(0);
    }
  };

  //handle SG Emergency Contact
  const SGEmergencyContact = (res: any) => {
    setSGEmergencyContactValue(res);
  };

  //handle EG tab CloseIcon
  const handleEGCloseIcon = () => {
    const emergencyGuardian = getGuardianDetailsByType('emergency');
    if (emergencyGuardian !== undefined) {
      setOpenDeleteProfileDailog(true);
    } else {
      const message = 'Please add emergency contact before delete it.';
      toast(message, {
        type: 'info',
        icon: true,
      });
    }
  };

  //handle Delete EG Profile
  const handleDeleteEGProfile = () => {
    setOpenDeleteProfileDailog(false);
    deleteGuardian();
    setSGEmergencyContactValue(YES);
  };

  //handle Cancel EG Profile
  const handleCancelEGProfile = () => {
    setOpenDeleteProfileDailog(false);
  };

  const getGuardian = () => {
    if (user?.patientId || state.patientId) {
      const data = { userId: props?.from === 'admin' ? state?.patientId : user?.patientId };
      dispatch(getGuardianDetails(data))
        .unwrap()
        .then(async (response: any) => {
          if (response?.status === 200) {
            // eslint-disable-next-line array-callback-return
            response.data.map((x: any) => {
              if (x.guardianCategory === 'primary' && x.profileImage !== null) {
                setPrimaryPicture(x.profileImage);
              } else if (x.guardianCategory === 'secondary' && x.profileImage !== null) {
                setSecondaryPicture(x.profileImage);
              } else if (x.guardianCategory === 'emergency' && x.profileImage !== null) {
                setEmergencyPicture(x.profileImage);
              }  
            }); 
                      
            const secondaryGuardian: any = response.data.find(
              (guardianObj: any) => guardianObj.guardianCategory === 'secondary'
            );
            if(secondaryGuardian){              
              setSecondaryGuardianInfoAvailable(true);            
              SGEmergencyContact(secondaryGuardian.isEmergency ? YES : NO);
            } 
            
            const primaryGuardian: any = response.data.find(
              (guardianObj: any) => guardianObj.guardianCategory === 'primary'
            );
            if (primaryGuardian) {              
              setPGEmergecyContact(primaryGuardian?.isEmergency ? YES : NO); 
              setSGEmergencyContactValue(primaryGuardian?.isEmergency ? NO : '' );
              setPrimaryGuradianInfoAvailable(true);
            }  
            
      
          } 
        });
    } 
  };

  useEffect(() => {
    getGuardian();
    //eslint-disable-next-line
  }, []);

  const moveToNextTab = (isSecondaryGuardianEmeregency: any, isPrimaryGuardianEmergency:boolean) => {  
    if (selectedTab === 0) setSelectedTab(1);
    else if (selectedTab === 1 && !isSecondaryGuardianEmeregency && !isPrimaryGuardianEmergency) setSelectedTab(2);
    else if (selectedTab === 1 && (isSecondaryGuardianEmeregency || isPrimaryGuardianEmergency)) setSelectedTab(2);
    if (selectedTab === 2) props?.onComplete();
  };

  const skipToEGStep =()=>{
    setSelectedTab(2);    
  };

  const skipStep =()=>{
    props?.onComplete();
  };

  const updateGuardian = (payload: any, onSuccess: any) => {

    if (user?.patientId) {
      if(!primaryGuradianInfoAvailable){
        delete payload[0].id;
        setPrimaryGuradianInfoAvailable(true);
      } else if(!secondaryGuardianInfoAvailable && payload.length > 1){
        delete payload[1].id;
        setSecondaryGuardianInfoAvailable(true); 
      }
      const data = { param: { userId: user?.patientId }, payload };
       
      return dispatch(updateGuardianDetails({ ...data }))
        .unwrap()
        .then((response) => {
          const primaryGuardian: any = response.data.find(
            (guardianObj: any) => guardianObj.guardianCategory === 'primary'
          );
          const secondaryGuardian: any = response.data.find(
            (guardianObj: any) => guardianObj.guardianCategory === 'secondary'
          );
          if (primaryGuardian) {
            setPGEmergecyContact(primaryGuardian?.isEmergency ? YES : NO);
            setSGEmergencyContactValue(primaryGuardian?.isEmergency ? NO : '' );
          }
          if (secondaryGuardian) SGEmergencyContact(secondaryGuardian?.isEmergency ? YES : NO);
          moveToNextTab(secondaryGuardian?.isEmergency, primaryGuardian?.isEmergency);
          if (response.status === 200) onSuccess();
        });      
    } else {
      const message = 'Please add patient details first.';
      toast(message, {
        type: 'info',
        icon: true,
      });
    }
  };

  const getGuardianDetailsByType = (type: string) => {
    const g = guardians.map((g:any) => {
      return {
        email:g.email, 
        firstName:g.firstName,
        guardianCategory:g.guardianCategory,
        id:g.id,
        isActiveCustody:g.isActiveCustody,
        isEmergency:g.isEmergency,
        lastName:g.lastName,
        mobileNumber:g.mobileNumber,
        other:g.other,
        profileImage:g.profileImage,
        relation:g.relation.charAt(0).toUpperCase() + g.relation.slice(1).toLowerCase(),
      };
    }
    );
    if (guardians.length > 0) {
      return g.find((guardian: any) => guardian.guardianCategory === type);
    } else {
      return undefined;
    }
  };

  const deleteGuardian = () => {
    const { id }: any = getGuardianDetailsByType('emergency');
    if (id) {
      const params = {
        patientId: user?.patientId,
        guardianId: id,
      };
      dispatch(deleteGuardianDetails(params))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            const message = 'Guardian information deleted successfully.';
            toast(message, {
              type: 'success',
              icon: true,
            });            
            setSelectedTab(1);
            getGuardian();
            window.location.reload();
          }
        });
    }
  };

  const handleProfileImage = async (acceptedFiles: any, type: string) => {
    if (acceptedFiles?.length > 0) {
      let guardianId = 0;
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (type === 'primary') {         
          setPrimaryPicture(file);         
        }
        else if (type === 'secondary') {
          setSecondaryPicture(file);
        }
        else {
          setEmergencyPicture(file);
        }
      };
      reader.readAsDataURL(file);
      const formData: any = new FormData();
      formData.append('file', file);

      if (type === 'primary') {
        // eslint-disable-next-line array-callback-return
        guardians.map((g: any) => {
          if (g.guardianCategory === 'primary') {
            guardianId = g.id;
            return guardianId;
          }
        });
      } else if (type === 'secondary') {
        // eslint-disable-next-line array-callback-return
        guardians.map((g: any) => {
          if (g.guardianCategory === 'secondary') {
            guardianId = g.id;
            return guardianId;
          }
        });
      } else {
        // eslint-disable-next-line array-callback-return
        guardians.map((g: any) => {
          if (g.guardianCategory === 'emergency') {
            guardianId = g.id;
            return guardianId;
          }
        });
      }

      await uploadGuradianImageAPI(formData, props?.from === 'admin' ? state?.patientId : user?.patientId, guardianId);
    }
  };

  // return GaurdianInformation component
  return (
    <>
      <Container component='main' maxWidth='sm'>
        {/* Tabs for primary, secondary , emergency gaurdaian */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: { xs: 320, sm: '100%' },
          }}
        >   
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
          >
            
            <Tab
              label={<PrimaryLabel PGEmergecyContact={PGEmergecyContact} selectedTab={selectedTab}/>}
              icon={ 
                <GuardianProfilePicture
                  // eslint-disable-next-line max-len
                  onFileUpload={(acceptedFiles: any) => handleProfileImage(acceptedFiles, 'primary')}
                  profileImage={true}
                  profilePicture={primaryPicture}
                  defaultPicture={primaryPicture === null ? '../images/primary_guardian.png' : primaryPicture}
                  selectedTab={selectedTab === 0}
                  guardianDetails = {getGuardianDetailsByType('primary')}
                /> 
              }
            />
            
            <Tab
              label={<SecoundaryLabel SGEmergencyContactValue={SGEmergencyContactValue} selectedTab={selectedTab}/>}
              icon={ 
                <GuardianProfilePicture
                  // eslint-disable-next-line max-len
                  onFileUpload={(acceptedFiles: any) => handleProfileImage(acceptedFiles, 'secondary')}
                  profileImage={true}
                  profilePicture={secondaryPicture}
                  defaultPicture={secondaryPicture === null ? '../images/secondary_guardian.png' : secondaryPicture}
                  selectedTab={selectedTab === 1}
                  guardianDetails = {getGuardianDetailsByType('secondary')}
                />
              }
            /> 
            <Tab
              label={<EmergencyLabel SGEmergencyContactValue={SGEmergencyContactValue} selectedTab={selectedTab}/>}
              icon={
                <GuardianProfilePicture
                  // eslint-disable-next-line max-len
                  onFileUpload={(acceptedFiles: any) => handleProfileImage(acceptedFiles, 'emergency')}
                  profileImage={true}
                  profilePicture={emergencyPicture}
                  defaultPicture={emergencyPicture === null ? '../images/emergency_guardian.png' : emergencyPicture}
                  selectedTab={selectedTab === 2}
                  guardianDetails = {getGuardianDetailsByType('emergency')}
                />
              }
            /> 
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            {/* Content for Tab 1 */}
            <PrimaryGaurdian
              onUpdateGuardian={updateGuardian}
              primaryGaurdian={getGuardianDetailsByType('primary')}
              from={props?.from ? props?.from : null}
              pictureAvailable= {primaryPicture !== null ? true : false}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            {/* Content for Tab 2 */}
            <SecondaryGaurdian
              SGEmergencyContact={SGEmergencyContact}
              onUpdateGuardian={updateGuardian}
              secondaryGaurdian={getGuardianDetailsByType('secondary')}
              from={props?.from ? props?.from : null}
              pictureAvailable= {secondaryPicture !== null ? true : false}
              showEmergency = {getGuardianDetailsByType('primary')}
              skipStep = {skipToEGStep}
              disableSaveButton = {guardians.length === 0}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {/* Content for Tab 3 */}
            <EmergencyGaudian
              EGForm={EGForm}
              onUpdateGuardian={updateGuardian}
              emergencyGaurdian={getGuardianDetailsByType('emergency')}
              handleDeleteGuardian={handleEGCloseIcon}
              from={props?.from ? props?.from : null}
              pictureAvailable= {emergencyPicture !== null ? true : false}
              prePopulateDetails = {PGEmergecyContact === 'yes' ? getGuardianDetailsByType('primary') :
                SGEmergencyContactValue === 'yes' ? getGuardianDetailsByType('secondary') : undefined}
              skipStep = {skipStep}
              disableSaveButton = {guardians.length === 0}
            />
          </TabPanel>
        </Box>

        {/* Delete profile Dialog */}

        <Dialog open={openDeleteProfileDailog} onClose={handleCancelEGProfile} maxWidth='xs'>
          <DialogTitle id='scroll-dialog-title'>
            <Typography id='modal-modal-title' variant='h2'>
              Delete Profile
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label='Close'
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleCancelEGProfile}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <DialogContentText>
              <Typography variant='subtitle1'>
                Are you sure you want to delete emergency contact? Secondary Guardian become default emergency contact
                if primary doesn&apos;t respond.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center !important' }}>
            <Button variant='outlined' fullWidth onClick={handleCancelEGProfile} sx={{ minWidth: {xs:100, md:200} }}>
              Cancel
            </Button>
            <Button onClick={handleDeleteEGProfile} fullWidth sx={{ minWidth: {xs:100, md:200} }}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default GuardianInfo;
