import React from 'react';
import {
  IconButton,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DashboardOnboarding = (props: { isOpen: boolean; handleModalClose: () => void }) => {
  const { isOpen, handleModalClose } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      maxWidth='lg'
      aria-describedby='alert-dialog-description'
      scroll='paper'
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h1' gutterBottom>
            How it Works
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }} textAlign={'center'}>
            <Grid item xs={12} sm={6} md={3}>
              <img src='/images/dashwork1.png' alt='work1' />
              <Typography variant='h3' gutterBottom>
                <IconButton
                  sx={{
                    fontSize: '16px',
                    color: '#FFF',
                    backgroundImage: 'url("images/Ellipse 1.svg")',
                    backgroundSize: 'auto auto',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  1
                </IconButton>
                &nbsp; Profile Setup
              </Typography>
              <Typography variant='body1' gutterBottom>
                Create your profile, review our policies, and sign consents for treatment.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img src='/images/dashwork2.png' alt='work2' />
              <Typography variant='h3' gutterBottom>
                <IconButton
                  sx={{
                    fontSize: '16px',
                    color: '#FFF',
                    backgroundImage: 'url("images/Ellipse 1.svg")',
                    backgroundSize: 'auto auto',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  2
                </IconButton>
                &nbsp; Provide Medical History
              </Typography>
              <Typography variant='body1' gutterBottom>
                Share your child&apos;s relevant medical and family history.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img src='/images/dashwork3.png' alt='work3' />
              <Typography variant='h3' gutterBottom>
                <IconButton
                  sx={{
                    fontSize: '16px',
                    color: '#FFF',
                    backgroundImage: 'url("images/Ellipse 1.svg")',
                    backgroundSize: 'auto auto',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  3
                </IconButton>
                &nbsp;Take M-CAT
              </Typography>
              <Typography variant='body1' gutterBottom>
                Take a comprehensive and interactive assessment of your child&apos;s psychiatric symptoms.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <img src='/images/dashwork4.png' alt='work4' />
              <Typography variant='h3' gutterBottom>
                <IconButton
                  sx={{
                    fontSize: '16px',
                    color: '#FFF',
                    backgroundImage: 'url("images/Ellipse 1.svg")',
                    backgroundSize: 'auto auto',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  4
                </IconButton>
                &nbsp; Schedule Appointment
              </Typography>
              <Typography variant='body1' gutterBottom>
                Schedule a comprehensive psychiatric assessment with our board-certified expert.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button fullWidth type='submit' sx={{ mt: 2, mb: 4, maxWidth: '360px' }} onClick={handleModalClose}>
          Let&apos;s go
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DashboardOnboarding.propTypes = {};

export default DashboardOnboarding;
