import { Button, Container, ContainerProps, Grid, Typography, TypographyProps, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

import COLORS from 'theme/colors';


const PageContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  paddingTop: 30,
}));

PageContainer.defaultProps = {
  content: 'section',
  maxWidth: 'lg',
};

export const Page = (props: any) => {
  const { children, title, backDir } = props;
  const navigate = useNavigate();
  let Pagetitle;
  let BackButton;
  if (title) {
    Pagetitle = <PageTitle>{title}</PageTitle>;
  }
  if (backDir) {
    BackButton = (
      <Button
        sx={{ background: COLORS.LightBlue, marginTop: 3, minWidth: 140 }}
        variant='outlined'
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(`${backDir}`)}
      >
        { props?.buttonText ? props.buttonText : 'Back' }
      </Button>
    );
  }

  return (
    <>
      <PageContainer>
        <Grid container>
          <Grid item xs={12} sm={2}>
            {BackButton}
          </Grid>
          <Grid item xs={12} sm={10}>
            {Pagetitle}
            {children}
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};


export const PageTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 36,
}));

PageTitle.defaultProps = {
  variant: 'h1',
};
