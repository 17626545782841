import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import ProtectedRoute from 'route/ProtectedRoute';
import { ROUTE } from 'route/RouteEnums';
import { AuthContext } from 'utils/AuthContext';

const SignOut = () => {
  const { logoutFromMindWeal } = useContext(AuthContext);

  useEffect(() => {
    logoutFromMindWeal();
    //eslint-disable-next-line
  }, []);

  return (
    <ProtectedRoute
      authenticationPath={ROUTE.LOGIN}
      outlet={
        <Grid sm={12}>
          <Typography variant='h1'> </Typography>
        </Grid>
      }
    />
  );
};

export default SignOut;
