import { createAsyncThunk } from '@reduxjs/toolkit';
import { setChatConversationStatusAPI, getChatConversationStatusAPI, getChartIdAPI, getVMANamesAPI, 
  createTaskAPI, getTaskListAPI, getPatientTaskListAPI, getUnassignedConversationsAPI, joinConversationAPI, 
  getConversationGroupDetailsAPI, assignConversationToOthersAPI, assignConversationToSelfAPI, 
  getAssignedConversationsAPI, getConversationCountAPI, getVMAStatusAPI,getTaskCountAPI } 
  from 'services/secureMessageServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
    data: any;
  }

export const setChatConversationStatus = createAsyncThunk(
  'SET_CONVERSATION_DETAILS',
  async (groupId: any, { rejectWithValue }: any) => {
    try {
      const response = await setChatConversationStatusAPI(groupId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getChatConversationStatus = createAsyncThunk(
  'GET_CONVERSATION_DETAILS',
  async (groupId: any, { rejectWithValue }: any) => {
    try {
      const response = await getChatConversationStatusAPI(groupId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getChartId = createAsyncThunk(
  'GET_CHART_ID',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getChartIdAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getVMANames = createAsyncThunk(
  'GET_VMA_NAMES',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getVMANamesAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const createTask = createAsyncThunk(
  'GET_VMA_NAMES',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await createTaskAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getTaskList = createAsyncThunk(
  'GET_TASK_LIST',
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await getTaskListAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getTaskCount = createAsyncThunk(
  'GET_TASK_COUNT',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getTaskCountAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getPatientTaskList = createAsyncThunk(
  'GET_PATIENT_TASK_LIST',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getPatientTaskListAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getUnassignedConversations = createAsyncThunk(
  'GET_UNASSIGNED_MESSAGES',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getUnassignedConversationsAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getAssignedConversations = createAsyncThunk(
  'GET_ASSIGNED_MESSAGES',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await getAssignedConversationsAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getConversationGroupDetails = createAsyncThunk(
  'GET_GROUP_DETAILS',
  async (id: number, { rejectWithValue }: any) => {
    try {
      const response = await getConversationGroupDetailsAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const assignConversationToOthers = createAsyncThunk(
  'ASSIGN_CONVERSATION_OTHERS',
  async (data:any, { rejectWithValue }: any) => {
    try {
      const response = await assignConversationToOthersAPI(data.groupId,data.id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const assignConversationToSelf = createAsyncThunk(
  'ASSIGN_CONVERSATION_SELF',
  async (id: any, { rejectWithValue }: any) => {
    try {
      const response = await assignConversationToSelfAPI(id);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const joinConversation = createAsyncThunk(
  'JOIN_CONVERSATION',
  async (groupId: number, { rejectWithValue }: any) => {
    try {
      const response = await joinConversationAPI(groupId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getConversationCount = createAsyncThunk(
  'GET_CONVERSATION_COUNT',
  async ( { rejectWithValue }: any) => {
    try {
      const response = await getConversationCountAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const getVMAStatus = createAsyncThunk(
  'GET_VMA_COUNT',
  async ( { rejectWithValue }: any) => {
    try {
      const response = await getVMAStatusAPI();
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);