import { createTheme } from '@mui/material/styles';
import GraphikRegular from '../assets/fonts/Graphik-Regular.woff2';
import GraphikMedium from '../assets/fonts/Graphik-Medium.woff2';
import { createBreakpoints } from '@mui/system';

import COLORS from './colors';

// A custom theme for this app
// &family=:wght@400;600
const breakpoints = createBreakpoints({});
const theme = createTheme({
  typography: {
    fontFamily: ['Graphik-Regular', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontFamily: ['Playfair Display', 'serif'].join(','),
      fontSize: 30,
      color: COLORS.Gray900,
      [breakpoints.down('md')]: {
        fontSize: 24,
      },
      fontWeight: 600,
      lineHeight: 1.26,
      marginBottom: 12,
    },
    h2: {
      fontFamily: ['Playfair Display', 'serif'].join(','),
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.3,
      [breakpoints.down('md')]: {
        fontSize: 20,
      },
    },
    h3: {
      fontFamily: ['Graphik-Medium', 'sans-serif'].join(','),
      fontSize: 18,
      [breakpoints.down('xs')]: {
        fontSize: 12,
      },
      lineHeight: 1.5,
      fontWeight: 500,
      color: COLORS.Gray600,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 2.4,
      letterSpacing: 0,
      textAlign: 'left',
      fontFamily: ['Graphik-Regular', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['Playfair Display', 'serif'].join(','),
      fontSize: 36,
      color: COLORS.Gray900,
      [breakpoints.down('md')]: {
        fontSize: 20,
      },
      fontWeight: 600,
      lineHeight: 1.26,
      marginBottom: 12,
    },
    subtitle1: {
      fontFamily: ['Graphik-Regular', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: 16,
      [breakpoints.down('md')]: {
        fontSize: 14,
      },
      lineHeight: 1.5,
      color: COLORS.Gray600,
    },
    body1: {
      fontFamily: ['Graphik-Regular', 'sans-serif'].join(','),
      fontSize: 14,
      lineHeight: 1.4,
      color: COLORS.Gray600,
    },
    subtitle2: {
      fontFamily: ['Graphik-Regular', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    body2: {
      fontFamily: ['Graphik-Regular', 'sans-serif'].join(','),
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.4,
    },
  },
  palette: {
    // primary: {
    //   main: COLORS.Dynamic_Green,
    // },
    // secondary: {
    //   main: COLORS.Light_Grey,
    // },
    info: {
      main: COLORS.Link,
    },
    // error: {
    //   main: red.A400,
    // },
    text: {
      primary: COLORS.Gray900,
    },
  },
  // component theming
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Graphik-Regular';
        font-style: normal;
        font-display: swap;
        font-weight: normal;
        src: local('Graphik-Regular'), url(${GraphikRegular}) format('woff2');
      };
      @font-face {
        font-family: 'Graphik-Medium';
        font-style: normal;
        font-display: swap;
        font-weight: 500;
        src: local('Graphik-Medium'), url(${GraphikMedium}) format('woff2');
      };
      @media (hover: none) {
        button:hover{
          background: #A0B2FA !important;
        }
      };
      @media only screen and (min-width: 0.75em) and (max-width: 47.9375em) {  
        button:hover{
          background: #A0B2FA !important;
        }
       };
    `,
    },

    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        // disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: 1.4,
          fontWeight: 500,
          fontFamily: ['Graphik-Medium', 'sans-serif'].join(','),
          color: COLORS.FormLabel,
          marginBottom: 6,
          whiteSpace: 'pre-line',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.BorderGray,
            borderRadius: 8,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          },
          height: 44,
        },
        input: {
          color: COLORS.FormInput,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: COLORS.Link,
          fontSize: 14,
          lineHeight: 1.4,
          fontWeight: 500,
          fontFamily: ['Graphik-Medium', 'sans-serif'].join(','),
          textDecoration: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: COLORS.BorderGray,
          '&.Mui-checked': {
            color: COLORS.Link,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: COLORS.FormLabel,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            background: COLORS.Link,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: 8,
            minHeight: 44,
            minWidth: 200,
            '&.Mui-disabled': {
              background: '#A0B2FA',
              color: COLORS.White,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            minWidth: 200,
          }),
          ':hover': {
            ...(ownerState.variant === 'contained' && {
              background: COLORS.DarkBlue,
            }),
            ...(ownerState.variant === 'outlined' && {
              background: COLORS.LightBlue,
            }),
          },
          ...(ownerState.variant === 'outlined' && {
            color: COLORS.DarkBlue,
            borderColor: COLORS.DarkBlue,
          }),
          ...(ownerState.variant === 'text' && {
            color: COLORS.Link,
            fontSize: 14,
            lineHeight: 1.4,
            textTransform: 'none',
          }),
          borderRadius: 32,
          fontSize: 16,
          [breakpoints.down('md')]: {
            fontSize: 14,
          },
          lineHeight: 1.5,
          fontWeight: 500,
          fontFamily: ['Graphik-Medium', 'sans-serif'].join(','),
          padding: '9px 16px',
        }),
      },
    },
    MuiStepper: {
      defaultProps: {
        alternativeLabel: true,
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          left: 'calc(-53% + 20px) !important',
          right: 'calc(47% + 20px) !important',
        },
        line: {
          borderColor: '#3467FF !important',
          borderTop: 'solid',
          borderTopWidth: ' 2px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .Mui-active': {
            color: '#3467FF !important',
          },
          '& .Mui-completed': {
            color: '#344054 !important',
          },
        },
        label: {
          color: '#98A2B3',
          fontSize: '14px !important',
          fontFamily: 'Graphik-Medium',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: '1px solid #F2F4F7',
          borderRadius: '24px',
          padding: '6px',
          gap: '8px',
          background: '#F9FAFB',
          '& .Mui-selected': {
            color: '#FFFFFF !important',
            background: '#9F84FF !important',
            borderRadius: '24px !important',
          },
          '& .Mui-selected:hover': {
            color: '#FFFFFF !important',
            background: '#9F84FF !important',
            borderRadius: '24px !important',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          padding: '10px 14px',
          border: 'none',
          fontFamily: 'Graphik-Medium',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          [breakpoints.down('md')]: {
            fontSize: '14px',
            padding: '10px 10px',
          },
        },
      },
    },
    MuiDialogActions:{
      styleOverrides:{
        root:{
          '&.MuiPickersLayout-actionBar .MuiButton-containedPrimary': {
            minWidth:'auto',
          },
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // Add your custom CSS properties for the Accordion root here
          backgroundColor: COLORS.White,
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.12)',
          margin: '8px 0 !important',
          borderRadius: '8px',
          // width: 672,
          ':before': {
            height: '0px !important',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: 54,
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          backgroundColor: COLORS.White, // Initial color is white
          transition: 'background-color 0.3s ease', // Smooth transition animation
          '&.Mui-expanded': {
            backgroundColor: COLORS.Yellow, // Color when expanded
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: COLORS.Black,
          backgroundColor: COLORS.White,
          border: '1px solid black',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 24,
          fontFamily: ['Playfair Display', 'serif'].join(','),
          fontWeight: 600,
          lineHeight: 3.2,
          letterSpacing: 0,
          textAlign: 'center',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize', // Set textTransform to capitalize,
          fontFamily: 'Graphik-Medium !importanat',
          fontWeight: 600,
          fontSize: '4px !importanat',
          '& .MuiAvatar-root': {
            width: 72,
            height: 'auto',
            marginBottom: 6,
          },
          '&.Mui-selected': {
            '.MuiAvatar-img': {
              border: ' 2px solid #FFC44D !important',
            },
          },
          '& .MuiIconButton-root': {
            padding: 0,
            backgroundColor: COLORS.White,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffff',
          border: '1px solid #D0D5DD',
          borderRadius: '8px',
          padding:'14px',
          '&:before': {
            borderBottom:'none !important',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // '.MuiTabs-indicator':{
          //   display:'none',
          // },
        },
      },
    },
  },
});

export default theme;
