import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  gridClasses,
} from '@mui/x-data-grid';

import COLORS from 'theme/colors';
import { useAppDispatch } from 'store/hooks';
import { getPatientTaskList } from 'store/slices/secureMessageSlice';


const TaskList = (props: any) => {
  const [taskList, setTaskList] = useState<any[] | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const StyledDataGrid = styled(DataGrid)<DataGridProps>(({ theme }) => ({
    border: 'none',
    backgroundColor: COLORS.LightYellow,
    fontSize: 14,
    fontWeight: 400,
    color: COLORS.Black,
    '.MuiDataGrid-toolbarContainer': {
      background: '#FFFBF1',
    },
    '.MuiDataGrid-columnHeaders': {
      marginBottom: 20,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
    },
    '.MuiDataGrid-columnHeadersInner': {
      background: COLORS.White,
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: COLORS.White,
      '&:hover, &.Mui-hovered': {
        backgroundColor: COLORS.LightBlue,
      },
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: COLORS.LightGray,
      '&:hover, &.Mui-hovered': {
        backgroundColor: COLORS.LightBlue,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 400,
      fontSize: '16px',
      color: '#000000',
      lineHeight: '24px'
    },
  }));

  const getList = async () => {
    await dispatch(getPatientTaskList(props.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setLoading(false);
          setTaskList(response?.data);
        }
      });
  };

  const columns: GridColDef[] = [
    {
      field:'id',
      headerName:'Id'
    },
    {
      field: 'taskTitle',
      headerName: 'Task Title',
      flex: 1.5,
    },
    {
      field: 'vmaName',
      headerName: 'Assigned To',
      flex: 1.5,
  
    },
    {
      field: 'status',
      headerName: 'Task Status',
      flex: 1,
      renderCell:(params:any) => {
        return(
          <span
            style={{
              textTransform: 'capitalize'
            }}
          >
            {params?.row?.status}
          </span>
        );
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      renderCell:(params:any)=>{
        return(
          <>
            {params?.row?.priority === 'medium' ?
              <span
                style={{
                  color: '#CA8504'
                }}>
                Medium
              </span> :
              params?.row?.priority === 'low' ?
                <span
                  style={{
                    color: '#12B76A',
                  }}>
                  Low
                </span> :
                <span
                  style={{
                    color: '#F04438'
                  }}>
                  High
                </span> 
            }

          </>
        );
      }
    },
  ];

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    getList();
    //eslint-disable-next-line
  }, [props.onClose]);
 
  const rowSelect=(params:any)=>{
    // eslint-disable-next-line array-callback-return
    const editItem = taskList?.filter((d:any) =>{
      if(d.id === params.row.id){
        return d;
      }
    });    
    props.onEdit(editItem);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2' sx={{ borderBottom: '1px solid #EAECF0' }} textAlign={'center'} pb={2}>
              Task List
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledDataGrid
              autoHeight
              loading={loading}
              rows={taskList ? taskList : []}
              columns={columns}
              onRowClick={rowSelect}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
                sorting:{
                  sortModel:[{field:'id', sort:'desc'}]
                }
              }}
              columnVisibilityModel={{
                id:false
              }}
              pageSizeOptions={[10, 25, 50]}
              rowSpacingType='margin'
              getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};


export default TaskList;