import { configureStore } from '@reduxjs/toolkit';
import { globalErrorHandler } from './middleware';
import userSlice from './slices/userSlice';
import signinSlice from './slices/signInSlice';
import documentListSlice from './slices/documentSlice';
import mcatSlice from './slices/mcatSlice';
import appointmentSlice from './slices/appointmentSlice';
import patientInfoSlice from './slices/patientInfoSlice';
import adminSlice from './slices/adminSlice';
import appointmentHistorySlice from './slices/appointmentHistorySlice';
import vitalSlice from './slices/vitalSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    signin: signinSlice,
    documentList: documentListSlice,
    mcatQuestion: mcatSlice,
    appointmentSlice: appointmentSlice,
    patientInfo: patientInfoSlice,
    admin: adminSlice,
    appointmentHistory: appointmentHistorySlice,
    vital: vitalSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([globalErrorHandler]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
