export const stripePublicKey =
  process.env.REACT_APP_ENV === 'prod'
    ? 'pk_test_51NVBrNSFInz4PQvrfOfcdBQmePgpUjyCVi7Xe9MLOuNiAXaCJCr91KJyCbwVR2fh5qeusUEIAZHaK3wC5Vbjlk3X00Iyz3YkQ6'
    : 'pk_test_51NVBrNSFInz4PQvrfOfcdBQmePgpUjyCVi7Xe9MLOuNiAXaCJCr91KJyCbwVR2fh5qeusUEIAZHaK3wC5Vbjlk3X00Iyz3YkQ6';

export const appId = 
    process.env.REACT_APP_ENV === 'prod' 
      ? '2551847beb666d4c' : process.env.REACT_APP_ENV === 'qa' ? '2551859deb59fec1' : '247824eb72b40ea5';

export const authKey = 
      process.env.REACT_APP_ENV === 'prod' 
        ? '8ade555928e2c93340c770033f6ac779aee4b88a' : 
        process.env.REACT_APP_ENV === 'qa' ? 'ac84bcf87627857446193275c032e4e3c9aaa64b' :
          'e2da73dca313163d3bb3fd711cb0da72b2b77cf5';
         
export const captchaKey = '6LcfpqUoAAAAALokYxUjtTv_vEXHJTR22og6JmOw';          
