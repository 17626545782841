import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sanitize } from 'dompurify';
import COLORS from 'theme/colors';

interface MultiSelectProps {
  question: any;
  answers: any;
  handleNext: any;
  openModal: any;
}

const MultiSelectPrimary: React.FC<MultiSelectProps> = ({ question, answers, handleNext, openModal }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
  const [otherValue, setOtherValue] = useState<any>([{ id: null, disable: true, value: '' }]);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    if (question?.type !== 'optional') {
      if (selectedAnswers?.length > 0 || otherValue[1]?.value.length > 0) {
        setDisableNextButton(false);
      } else {
        setDisableNextButton(true);
      }
    }
    else {
      setDisableNextButton(false);
    }

  }, [otherValue, question.type, selectedAnswers]);

  const handleChange = async (e: any) => {
    const selected = [...selectedAnswers];
    if (e.target.checked) {
      const { id } = e.target;
      const val = parseInt(id);
      if (e.target.name === 'other') {
        const otherVal = [...otherValue];
        const newValues = { id: val, disable: false, value: '' };
        otherVal.push(newValues);
        setOtherValue(otherVal);
      } else if (e.target.name === 'exclusive') {
        setSelectedAnswers([{ id: val, type: 'exclusive' }]);
      }
      else {
        const selectedWithoutExclusive = selectedAnswers?.filter((x: any) => x.type !== 'exclusive');
        selectedWithoutExclusive.push({ id: val });
        setSelectedAnswers(selectedWithoutExclusive);
      }
    } else if (!e.target.checked) {
      const { id } = e.target;
      if (e.target.name === 'other') {
        const filterVal = otherValue?.filter((x: any) => x.id !== parseInt(id));
        setOtherValue(filterVal);
        setErrorMessage(false);
      } else if (e.target.name === 'exclusive') {
        setSelectedAnswers([]);
      }
      else {
        setSelectedAnswers(selected.filter((x: any) => x.id !== parseInt(id)));
      }
    }
  };

  const handleSubmit = () => {
    let answers = [...selectedAnswers];

    otherValue?.length > 0 && otherValue?.map((o: any) => {
      if (o.id !== null && o.value !== '') {
        const newObj = { id: o.id, input: o.value };
        answers.push(newObj);
      }
      return null;
    });

    if (question.type === 'optional' && selectedAnswers[1]?.id === '') {
      answers = [];
    }
    const answerDetails = {
      quizId: question.questionId,
      answers: answers,
      launchType: question.launchType
    };
    handleNext(answerDetails);
    setSelectedAnswers([]);
    setOtherValue({ id: null, disable: true, value: '' });
  };

  const handleBlur = (e: any) => {
    if (e.target.value.length === 0) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleOtherValue = (e: any, id: any) => {
    const val = [...otherValue];
    val.forEach((v: any) => {
      if (v.id === id) {
        v.value = e.target.value;
      }
      return null;
    });
    setOtherValue(val);
    if (e.target.value.length > 0) {
      setDisableNextButton(false);
    }
  };

  const handleChecked = (id: any, type: string) => {
    const len = selectedAnswers?.filter((s: any) => {
      return s.id === id;
    });
    const otherValLen = Array.isArray(otherValue) && otherValue?.filter((s: any) => {
      return s.id === id;
    });
    if (len.length === 1 || Object.keys(otherValLen).length === 1) {
      return true;
    }
    else {
      return false;
    }
  };


  const handleTextFiledClick = (e: any) => {
    e.stopPropagation();
    return false;
  };

  const handleBackButton=()=>{
    const obj = { event : 'back'};
    handleNext(obj);
  };
  
  return (
    <Grid container maxWidth='100%'>
      <Box sx={{position: {xs:'inherit', md:'absolute'}, left:'20px', top:'130px'}} >
        <Button 
          sx={{ background: COLORS.LightBlue, minWidth: 140, mb:1  }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => handleBackButton()}  
          disabled={question?.event === 'back'? true : false}
        >Back</Button>
      </Box>
      {question?.header !== null ? question.header : null}
      <Container sx={{ background: '#fff', padding: '20px 40px', borderRadius: '12px' }}>
        <Typography sx={{ color: '#6941C6', fontSize: '18px', fontWeight: 500, mb: '24px', lineHeight:'28px' }}>
          <div dangerouslySetInnerHTML={{ __html: sanitize(question?.heading) }} /></Typography>
        <Typography mb={3}
          sx={{
            lineHeight: '28px', mt: '5px', mb: '24px', fontSize: '18px',
            fontWeight: 500, color: '#2C3446', fontStyle:'italic'
          }}>
          <div dangerouslySetInnerHTML={{ __html: sanitize(question?.prompt) }} /> </Typography>
        <Typography mb={3}
          sx={{ lineHeight: '28px', mt: '5px', mb: '36px', fontSize: '20px', fontWeight: 500, color: '#2C3446' }}>
          <div dangerouslySetInnerHTML={{ __html: sanitize(question?.question) }} /> </Typography>

        {question.info !== null ? (           
          <Box sx={{ background: '#F8F5FF', p:'8px 12px', alignSelf:'stretch', borderRadius:'8px', mb:'48px' }}>
            <Grid container>
              <Grid item xs={0.5}>
                <InfoOutlinedIcon fontSize='small' color='primary' sx={{ mr: '10px' }} />
              </Grid>
              <Grid item xs={11.5} mt='2px'>
                <Typography color='#1C1F24'>
                  <div dangerouslySetInnerHTML={{ __html: sanitize(question?.info) }} /></Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {question?.instruction !== null ?
          <Typography mb={3} sx={{ fontWeight: 400, fontSize:'18px', color:'#2C3446' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.instruction) }} /></Typography>
          : null}  
        {/* <Grid container item xs={12} direction='column'> */}
        {answers?.length > 1 ?
          answers?.map((a: any) => {
            return (
              <>
                <Typography mt={2} variant='h2' fontSize={20} mb={3} color='#1C1F24'><b>{a.text}</b></Typography>
                {a?.answers?.map((ans: any, i: number) => {
                  return (
                    <>
                      <FormControlLabel
                        sx={{ 
                          background:'#F2F4F7', 
                          margin:'10px',
                          pr:'10px',
                          justifyContent:'center',
                          borderRadius:'8px',
                          border:'1px solid #EEF0F6',
                          boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                          '&.Mui-checked': {
                            '&, & + .MuiFormControlLabel-label': {
                              color: 'red',                            
                            }
                          } 
                        }}
                        control={
                          <Checkbox
                            id={ans.id.toString()}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled', }}
                            value={ans.text}
                            sx={{ marginLeft: '30px' }}
                            name={ans.type}
                            checked={handleChecked(ans.id, ans.type)}
                            disabled = {ans.preSelected}
                          />
                        }
                        label={ <Typography sx={{color:'#1C1F24', fontSize:'16px' }}>
                          <div dangerouslySetInnerHTML={{ __html: sanitize(ans?.text) }} />
                        </Typography>}
                        labelPlacement='end'
                      />
                      {ans.info && ans.info !== null ? (
                        <Tooltip title={ans.info} arrow placement='bottom-start' enterTouchDelay={0}>
                          <InfoOutlinedIcon fontSize='small' sx={{ position: 'relative', top: '10px', right: 4 }} />
                        </Tooltip>
                      ) : null}

                      {ans.type === 'other' ? (
                        <Box sx={{ width: { sm: '50%' }, position: 'relative' }}>
                          <TextField
                            autoComplete='other'
                            placeholder='Describe'
                            fullWidth
                            id='other'
                            autoFocus
                            multiline
                            rows={1}
                            sx={{ background: '#fff' }}
                            inputProps={{ maxLength: 255 }}
                            // eslint-disable-next-line max-len 
                            disabled={otherValue?.length && otherValue?.filter((o: any) => { return o.id === ans.id; }).length === 1 ?
                              otherValue[otherValue?.map((o: any) => o.id).indexOf(ans.id)]?.disable :
                              otherValue[0]?.disable}
                            // eslint-disable-next-line max-len
                            value={otherValue?.length && otherValue?.filter((o: any) => { return o.id === ans.id; })?.length === 1 ?
                              otherValue[otherValue?.length &&
                                    otherValue?.map((o: any) => o.id).indexOf(ans.id)]?.value : otherValue[0]?.value
                            }
                            onChange={(e) => handleOtherValue(e, ans.id)}
                            onBlur={(e) => handleBlur(e)}
                            onClick={(e) => handleTextFiledClick(e)}
                          />
                          {errorMessage ?
                            otherValue?.length &&
                                otherValue.map((o: any) => {
                                  if (o.id === ans.id) {
                                    return (
                                      <Box key={o.id} sx={{ position: 'absolute', left: 0, right: 0, top: '100%' }} >
                                        <Alert severity='error' >Please describe </Alert>
                                      </Box>
                                    );
                                  }
                                  return null;
                                })
                            :
                            null}
                        </Box>
                      ) : null}
                    </>
                      
                  );
                })}
              </>
            );
          })

          :
          answers[0]?.answers.map((ans: any, i: number) => {
            return (
              <>
                <FormControlLabel
                  sx={{ 
                    background:'#F2F4F7', 
                    margin:'10px',
                    pr:'10px',
                    justifyContent:'center',
                    borderRadius:'8px',
                    border:'1px solid #EEF0F6',
                    boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    '&.Mui-checked': {
                      '&, & + .MuiFormControlLabel-label': {
                        color: 'red',                            
                      }
                    } 
                  }}
                  control={
                    <Checkbox
                      id={ans.id.toString()}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      value={ans.text}
                      name={ans.type}                      
                      checked={handleChecked(ans.id, ans.type)}  
                      disabled = {ans.preSelected}                    
                      sx={{   
                        color:'#D0D5DD',                                          
                        '.MuiSvgIcon-root':{  
                          background:'#fff!important',
                          path: {
                            transform: 'translate(-2px, -2px) scale(1.2)',
                          },
                        },   
                        '&.Mui-checked': {
                          '&, & + .MuiFormControlLabel-label': {
                            color: '#9F84FF',  
                                                     
                          }
                        }                     
                      }}
                    />
                  }
                  label={ <Typography sx={{color:'#1C1F24', fontSize:'16px' }}>
                    <div dangerouslySetInnerHTML={{ __html: sanitize(ans?.text) }} /></Typography>}
                  labelPlacement='end'
                />
                {ans.info && ans.info !== null ? (
                  <Tooltip title={ans.info} arrow placement='bottom-start' enterTouchDelay={0}>
                    <InfoOutlinedIcon fontSize='small' sx={{ position: 'relative', top: '10px', right: 4 }} />
                  </Tooltip>
                ) : null}
                {ans.type === 'other' ? (
                  <Box sx={{ width: { sm: '50%' }, position: 'relative' }}>
                    <TextField
                      autoComplete='other'
                      placeholder='Describe'
                      fullWidth
                      id='other'
                      autoFocus
                      multiline
                      rows={1}
                      sx={{ background: '#fff' }}
                      inputProps={{ maxLength: 255 }}
                      disabled={otherValue?.length > 1 ? otherValue[1]?.disable : otherValue[0]?.disable}
                      value={otherValue?.length > 0 ? otherValue[1]?.value : ''}
                      onChange={(e) => handleOtherValue(e, ans.id)}
                      onBlur={(e) => handleBlur(e)}
                      onClick={(e) => handleTextFiledClick(e)}
                    />

                    {errorMessage ?
                      <Box sx={{ position: 'absolute', left: 0, right: 0, top: '100%' }}>
                        <Alert severity='error' > Please describe </Alert>
                      </Box> : null}
                  </Box>
                ) : null}

              </>
            );
          })
        }
        {/* </Grid> */}

        {question?.informationSection !== null ? (
          <Box mt={4} mb={4}>
            <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}> 
              {question?.informationSection} </Alert>
          </Box>
        ) : null}
        <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: errorMessage ? '50px' : '15px' }}>
          <Box sx={{ textAlign: { xs: 'center' } }} width='70%'>
            
            <Button onClick={handleSubmit} disabled={disableNextButton} size='large' fullWidth>
              Next
            </Button>
          </Box>
        </Grid>
      </Container >
    </Grid>
  );
};

export default MultiSelectPrimary;
