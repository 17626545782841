import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signInAPI, validateZipcodeAPI } from 'services/authServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}

export const signIn = createAsyncThunk(
  'USER_DETAILS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await signInAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


export const validateZipcode = createAsyncThunk(
  'VALIDATE_ZIP_CODE',
  async (zipcode: number, { rejectWithValue }) => {
    try {
      const response = await validateZipcodeAPI(zipcode);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);


interface Loader {
  isUserLoggedIn: boolean;
}


interface StateI {
  loaders: Loader;
  error: undefined;
  insuranceProviders:any
}

const initialState = {
  loaders: {
    isUserLoggedIn: false,
  },
  error: undefined,
  insuranceProviders:[],
};

const signinSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state: StateI) => {
      state.loaders.isUserLoggedIn = true;
    });
    builder.addCase(signIn.fulfilled, (state: StateI) => {
      state.loaders.isUserLoggedIn = false;
    });
    builder.addCase(signIn.rejected, (state: StateI) => {
      state.loaders.isUserLoggedIn = false;
    });
    builder.addCase(validateZipcode.fulfilled, (state:StateI, action:any) =>{
      state.insuranceProviders = action.payload.data;
    });
  },
});

export default signinSlice.reducer;