/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
  Box, Card, CardActionArea, CardContent,
  CardMedia, CardProps, Grid, IconButton, Typography, styled
} from '@mui/material';
import COLORS from 'theme/colors';

import cigna from 'assets/images/cards/cigna-bg.png';
import cignaLogo from 'assets/images/cards/cigna.svg';
import tricare from 'assets/images/cards/tricare-bg.png';
import tricareLogo from 'assets/images/cards/tricare.svg';
import humana from 'assets/images/cards/humana-bg.png';
import humanaLogo from 'assets/images/cards/humana.svg';
import aetna from 'assets/images/cards/aetna-bg.png';
import aetnaLogo from 'assets/images/cards/aetna.svg';
import uhc from 'assets/images/cards/united-health-care-bg.png';
import uhcLogo from 'assets/images/cards/united-health-care.svg';
import bcbs from 'assets/images/cards/bluecross-blueshield-bg.png';
import bcbsLogo from 'assets/images/cards/bluecross-blueshield.svg';
import defaultCard from 'assets/images/cards/default-bg.png';
import editIcon from 'assets/images/edit.svg';
import logo from 'assets/images/logo.svg';

interface PrimaryCardProps {
  editPrimaryInsurance?: (status: boolean) => any;
  insurancetype?: string;
  data: any;
  fromadmin?: boolean;
}

const PrimaryCard: React.FC<PrimaryCardProps> = ({ editPrimaryInsurance, insurancetype, data, fromadmin }) => {

  const [cardImage, setCardImage] = useState({
    cardBg: '',
    insuranceProvider: '',
    cardLogo: ''
  });

  useEffect(() => {
    if (data !== null)
      switch (data?.insuranceCarrier) {
        case 'Blue Cross Blue Shield (Commercial plans of all states)':
          setCardImage({
            insuranceProvider: 'Blue Cross Blue Shield (BCBS)',
            cardBg: bcbs,
            cardLogo: bcbsLogo,
          });
          break;
        case 'United Health Care':
          setCardImage({
            insuranceProvider: 'United Health Care (UHC)',
            cardBg: uhc,
            cardLogo: uhcLogo,
          });
          break;
        case 'Aetna':
          setCardImage({
            insuranceProvider: 'Aetna',
            cardBg: aetna,
            cardLogo: aetnaLogo,
          });
          break;
        case 'Health Alliance Network':
          setCardImage({
            insuranceProvider: 'Health Alliance Network',
            cardBg: humana,
            cardLogo: humanaLogo,
          });
          break;
        case 'TriCare (as out of network provider)':
          setCardImage({
            insuranceProvider: 'Tricare',
            cardBg: tricare,
            cardLogo: tricareLogo,
          });
          break;
        case 'Cigna':
          setCardImage({
            insuranceProvider: 'Cigna',
            cardBg: cigna,
            cardLogo: cignaLogo,
          });
          break;
        default:
          setCardImage({
            insuranceProvider: 'default',
            cardBg: defaultCard,
            cardLogo: logo,
          });
      }
  }, [data]);


  const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
    maxWidth: 335,
    boxShadow: 'none',
    '.MuiCardContent-root': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      top: 0,
      '& .MuiTypography-root': {
        color: COLORS.White,
      }
    },
    
  }));

  return (
    <> {data !== null ?
      <StyledCard>
        <CardActionArea>
          <CardMedia component='img' height='auto' image={cardImage.cardBg} alt='Card background' />
          <CardContent>
            <Box sx={{cursor: !fromadmin ? 'pointer' :'default'}}>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                    {insurancetype} Insurance
                  </Typography>
                  {!fromadmin ?
                    <Typography variant='body2' gutterBottom
                      sx={{ fontSize: { xs: '12px', sm: '14px' } }}
                      onClick={() => editPrimaryInsurance && editPrimaryInsurance(false)}>
                      <IconButton >
                        <CardMedia component='img' src={editIcon} alt='editicon' />
                      </IconButton>
                      Edit
                    </Typography> :
                    <IconButton sx={{ marginTop: '40px' }} />
                  }

                </Grid>
                <Grid item xs={4}>
                  <CardMedia
                    component='img'
                    src={cardImage.cardLogo}
                    alt={cardImage.insuranceProvider}
                    style={{ width: '70px', height: 'auto' }}
                  />
                </Grid>
                <Grid item xs={12} sx={{paddingTop:'0px!important'}}>
                  <Typography variant='subtitle1' gutterBottom sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                    {data?.subscriberName.toUpperCase()}
                  </Typography>
                  <Typography variant='subtitle1' gutterBottom sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
                    {data?.planId.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </CardActionArea>
      </StyledCard>
      : null}
    </>
  );
};


export default PrimaryCard;
