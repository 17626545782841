import { StatusCode } from 'services/axios';
import { errorMessages } from 'utils/helper';



export const handleMessage = (status: any) => {
  let message;
  switch (status) {
  case StatusCode.InternalServerError: {
    message = errorMessages.internal_server_error;
    break;
  }
  case StatusCode.Forbidden: {
    message = errorMessages.forbidden;
    break;
  }
  case StatusCode.Unauthorized: {
    message = errorMessages.unauthorised;
    break;
  }
  case StatusCode.TooManyRequests: {
    message = errorMessages.too_many_request;
    break;
  }
  case StatusCode.BadRequest: {
    message = errorMessages.bad_request;
    break;
  }
  // User not found
  case 1000: {
    message = errorMessages[status as keyof typeof errorMessages];
    break;
  }
  case 1002: {
    message = errorMessages[status as keyof typeof errorMessages];
    break;
  }
  case 2000: {
    message = errorMessages[status as keyof typeof errorMessages];
    break;
  }
  case 2001: {
    message = errorMessages[status as keyof typeof errorMessages];
    break;
  }
  case 2002: {
    message = errorMessages[status as keyof typeof errorMessages];
    break;
  }

  default: {
    message = errorMessages.somthing_went_wrong;
    break;
  }
  }
  return message;
};