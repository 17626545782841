import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';

export const getDocumentListAPI = () => {
  return apiInterceptor
    .get(`${API_URL.DOCUMENT_LIST}`)
    .then((response: any) => {
      return response;
    });
};


export const downloadDocumentAPI = () => {
  return apiInterceptor
    .get(`${API_URL.DOWNLOAD_DOCUMENT}`)
    .then((response: any) => {
      return response;
    });
};
