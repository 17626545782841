import { Grid } from '@mui/material';
import LogoLoader from './LogoLoader';

const Loader = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      minHeight={'80%'}
    >
      <LogoLoader />
    </Grid>
  );
};

export default Loader;
