import { useEffect, useState, useContext, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CardMedia,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Link,
  Tooltip,
  DialogActions,
  Alert,
  AlertTitle,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AuthContext } from 'utils/AuthContext';
import { pharmacyDetailsSchema } from 'utils/schema';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { getPharmacyDetails, savePharmacyDetails } from 'store/slices/userSlice';
import { toast } from 'react-toastify';
import { ROUTE } from 'route/RouteEnums';
import { useNavigate } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { YES, permissions } from 'utils/constants';
import { Restricted } from 'components/Restricted';

const PharmacyDetails = (props: any) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [authorizationMsg, setAuthorizationMsg] = useState<boolean>(false);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    clearErrors,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(pharmacyDetailsSchema),
    mode: 'all',
  });
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);
  const { user } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [opentooltip, setOpenTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (user?.patientId !== undefined || props?.patientId !== undefined) {
      fetchPharmacyDetails(props?.from === 'admin' ? props?.patientId : user?.patientId);
    } else {
      const message = 'Please add patient details first.';
      toast(message, {
        type: 'info',
        icon: true,
      });
      navigate(ROUTE.PATIENT_INFORMATION);
    }
    //eslint-disable-next-line
  }, [dispatch, setValue, user?.patientId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setOpenTooltip(false);
      }     
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    //eslint-disable-next-line
  }, []);

  const fetchPharmacyDetails = (patientId: any) => {
    dispatch(getPharmacyDetails(patientId))
      .unwrap()
      .then((response: any) => {
        displayGenoaModal(response?.data?.primaryPharmacy);
        if (response?.status === 200 && response?.data !== '') {
          Object.keys(response?.data).forEach((key: any) => {
            switch (key) {
            case 'accessMedicationHistory':
              setValue(
                'accessMedicationHistory',
                response?.data?.accessMedicationHistory !== null
                  ? response?.data?.accessMedicationHistory
                    ? 'true'
                    : 'false'
                  : '',
                {
                  shouldValidate: true,
                  shouldDirty: false,
                }
              );
              if (!response?.data?.accessMedicationHistory && response?.data?.accessMedicationHistory !== null)
                setAuthorizationMsg(!response?.data?.accessMedicationHistory);
              break;
            case 'phoneNumber':
              if (response?.data[key].length === 10) setValue(key, `1${response?.data[key]}`);
              else setValue(key, response?.data[key]);
              break;
            default:
              setValue(key, response.data[key], { shouldValidate: true, shouldDirty: false });
            }
          });
        }
      });
  };

  const displayGenoaModal = (primaryPharmacy: any) => {
    if (primaryPharmacy !== 'yes' && props?.from !== 'admin') {
      setDisplayModal(true);
    } else {
      setDisplayModal(false);
    }
  };

  const onSubmitForm = (formData: any) => {
    if(isValid && isDirty){
      const payload = {
        ...formData,
        phoneNumber: formData?.phoneNumber.slice(1),
      };
      if (user?.patientId) {
        dispatch(savePharmacyDetails({ patientId: user?.patientId, payload: payload }))
          .unwrap()
          .then((res: any) => {
            if (res.status === 200) {
              toast('Pharmacy details added successfully', {
                type: 'success',
                icon: true,
              });
              reset(formData);
              props?.onComplete();
            }
          });
      } else {
        const message = 'Please add patient details first.';
        toast(message, {
          type: 'info',
          icon: true,
        });
        navigate(ROUTE.PATIENT_INFORMATION);
      }
    }else{
      toast('No changes to update.',{
        type:'info',
        icon:true
      });
    }
  };

  const handleClose = () => {
    setDisplayModal(false);
  };

  const handleProceed = () => {
    setValue('primaryPharmacy', 'yes', { shouldValidate: true, shouldDirty: true });
    setValue('pharmacyName', 'Genoa Healthcare', { shouldValidate: true });
    setValue('phoneNumber', '1 618.208.6937', { shouldValidate: true });
    setValue('address', '2615 Edwards St Ste 102, Alton, IL', { shouldValidate: true });
    setValue('zipCode', '62002', { shouldValidate: true });
    setValue('accessMedicationHistory', '', { shouldValidate: true });
    clearErrors();
    setDisplayModal(false);
  };

  const handleRadioClick = () => {
    if (getValues('pharmacyName') === 'Genoa Healthcare') {
      setValue('primaryPharmacy', 'no', { shouldValidate: true, shouldDirty: true });
      setValue('pharmacyName', '', { shouldValidate: false });
      setValue('phoneNumber', '1', { shouldValidate: false });
      setValue('address', '', { shouldValidate: false });
      setValue('zipCode', '', { shouldValidate: false });
    }
  };

  const tooltipContent = (
    <>
      <Typography variant='subtitle2'>Genoa Healthcare more info1</Typography>
      <Typography variant='body2'>
        We strongly encourage you to consider Genoa Healthcare as your preferred pharmacy for a more convenient
        medication refill experience. To learn more, please visit; {' '}
        <Link href='https://www.genoahealthcare.com/pharmacy/' target='_blank' variant='body1'>
          https://www.genoahealthcare.com/pharmacy/
        </Link>
      </Typography>
    </>
  );

  return (
    <Container component='main' maxWidth='sm'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component='form' noValidate sx={{ mt: 1 }} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <InputLabel htmlFor='primaryPharmacy' sx={{ display: 'block', alignItems: 'center'}}>
                Do you wish to make Genoa Healthcare your Primary Pharmacy?* (only available for Illinois residents)    
                <Tooltip
                  ref={tooltipRef}
                  open={opentooltip}
                  title={tooltipContent}
                  arrow
                  placement='bottom-start'
                  enterTouchDelay={0}
                  enterDelay={0}
                  leaveDelay={8000}
                >
                  <InfoOutlinedIcon onMouseEnter={() => setOpenTooltip(true)}/>
                </Tooltip>
              </InputLabel>
              <RadioGroup
                row
                aria-labelledby='demo-form-control-label-placement'
                id='primaryPharmacy'
                value={watch('primaryPharmacy') ? watch('primaryPharmacy') : null}
              >
                <FormControlLabel
                  value='yes'
                  control={<Radio />}
                  label='Yes'
                  {...register('primaryPharmacy')}
                  onClick={props?.from !== 'admin' ? handleProceed : ()=>{console.log();}}
                  disabled={props?.from === 'admin'}
                />
                <FormControlLabel
                  value='no'
                  control={<Radio />}
                  label='No'
                  {...register('primaryPharmacy')}
                  onClick={props?.from !== 'admin' ? handleRadioClick : ()=>{console.log();}}
                  disabled={props?.from === 'admin'}
                />
              </RadioGroup>
              <FormHelperText error={!!errors.primaryPharmacy}>
                {errors.primaryPharmacy ? errors.primaryPharmacy?.message : ''}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='pharmacyName' disabled={watch('primaryPharmacy') === YES}>
                {props?.from !== 'admin' ? 'Pharmacy Name*' : 'Pharmacy Name'}
              </InputLabel>
              <TextField
                required
                fullWidth
                placeholder="Enter your pharmacy's name"
                id='pharmacyName'
                autoComplete='pharmacyName'
                {...register('pharmacyName')}
                error={!!errors.pharmacyName}
                helperText={errors?.pharmacyName?.message}
                disabled={watch('primaryPharmacy') === YES || props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='phoneNumber' disabled={watch('primaryPharmacy') === YES}>
                {props?.from !== 'admin' ? 'Pharmacy’s  Phone number*' : 'Pharmacy’s  Phone number'}
              </InputLabel>
              <ReactPhoneInput
                component={TextField}
                label=''
                onlyCountries={['us']}
                country={'us'}
                masks={{ us: '(...) ...-....' }}
                autoFormat={true}
                disableDropdown={true}
                defaultErrorMessage={'Please enter a Pharmacy’s  phone number'}
                placeholder='+1 (555) 000-0000'
                {...register('phoneNumber')}
                inputProps={{
                  helperText: errors.phoneNumber ? errors.phoneNumber?.message : '',
                  error: !!errors.phoneNumber,
                }}
                countryCodeEditable={false}
                onChange={(e) => {
                  setValue('phoneNumber', e, { shouldValidate: true });
                }}
                value={watch('phoneNumber') ? watch('phoneNumber') : null}
                disabled={watch('primaryPharmacy') === YES || props?.from === 'admin'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor='address' disabled={watch('primaryPharmacy') === YES}>
                Pharmacy&apos;s Address*
              </InputLabel>
              <TextField
                fullWidth
                multiline
                rows={6}
                placeholder="Enter pharmacy's address..."
                id='address'
                autoComplete='address'
                variant='filled'
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address ? errors.address?.message : ''}
                value={watch('address')}
                disabled={watch('primaryPharmacy') === YES || props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='zip-code' disabled={watch('primaryPharmacy') === YES}>
                {props?.from !== 'admin' ? 'Zip Code*' : 'Zip Code'}
              </InputLabel>
              <TextField
                fullWidth
                id='where-are-you-located?'
                autoComplete='zip-code'
                placeholder='Zip Code'
                {...register('zipCode')}
                error={!!errors.zipCode}
                helperText={errors?.zipCode ? errors?.zipCode?.message : ''}
                value={watch('zipCode')}
                disabled={watch('primaryPharmacy') === YES || props?.from === 'admin'}
                inputProps={{
                  maxLength: 5,
                  pattern:'[0-9]{5}'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor='accessMedicationHistory'>
                Do you authorize us to obtain medication history?*
              </InputLabel>
              <FormControl error={!!errors.accessMedicationHistory}>
                <RadioGroup
                  aria-labelledby='demo-form-control-label-placement'
                  name='accessMedicationHistory'
                  id='accessMedicationHistory'
                  value={watch('accessMedicationHistory') ? watch('accessMedicationHistory') : null}
                >
                  <FormControlLabel
                    value='true'
                    control={<Radio />}
                    label='Yes'
                    {...register('accessMedicationHistory')}
                    disabled={props?.from === 'admin'}
                    onClick={props?.from !== 'admin' ? () => setAuthorizationMsg(false) : ()=>{console.log();}}
                  />
                  <FormControlLabel
                    value='false'
                    control={<Radio />}
                    label='No'
                    {...register('accessMedicationHistory')}
                    disabled={props?.from === 'admin'}
                    onClick={props?.from !== 'admin' ? () => setAuthorizationMsg(true) : ()=>{console.log();}}
                  />
                </RadioGroup>
                {errors.accessMedicationHistory && (
                  <FormHelperText>{errors.accessMedicationHistory?.message}</FormHelperText>
                )}
              </FormControl>
              {authorizationMsg ? (
                <Alert severity='error'>
                  Authorization needed for comprehensive medication history to enhance care. Please reconsider your
                  response.
                </Alert>
              ) : null}
            </Grid>

            <Restricted permission={permissions.EDIT_PROFILE}>
              <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <LoadingButton
                  loading={isProfileDetailsFetched}
                  variant='contained'
                  type='submit'
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Restricted>
          </Grid>
        </Box>
      </Box>

      <Dialog
        open={displayModal}
        onClose={handleClose}
        maxWidth='sm'
        aria-describedby='alert-dialog-description'
        scroll='paper'
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <CardMedia
              component={'img'}
              src='../images/genoa-logo.png'
              alt='genoa logo'
              sx={{ width: 'auto', height: 'auto', maxWidth: '100%', margin: '0 auto' }}
            />
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color='success' />
                </ListItemIcon>
                {
                  //eslint-disable-next-line
                  <ListItemText primary='Directly delivering prescriptions to you by mail (only available in Illinois).' />
                }
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color='success' />
                </ListItemIcon>
                {/* eslint-disable-next-line max-len */}
                <ListItemText primary='Ensuring timely medication refills by maintaining an adequate stock of your current medications.' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color='success' />
                </ListItemIcon>
                <ListItemText primary='Offering specialized packaging organized for different times of the day.' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color='success' />
                </ListItemIcon>
                <ListItemText primary='Coordinating with both you and our office to ensure prompt refills.' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color='success' />
                </ListItemIcon>
                <ListItemText primary='Accepting major insurances with no extra cost to you.' />
              </ListItem>
            </List>
            <Alert severity='info' sx={{ maxWidth: 667, width: '100%' }}>
              <AlertTitle>Genoa Healthcare more info</AlertTitle>
              We strongly encourage you to consider Genoa Healthcare as your preferred pharmacy for a more convenient
              medication refill experience. To learn more, please visit; {' '}
              <Link href='https://www.genoahealthcare.com/pharmacy/' target='_blank' variant='body1'>
                https://www.genoahealthcare.com/pharmacy/
              </Link>
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Grid container spacing={2} justifyContent={'center'}>
            {/* <Grid item xs={12} sm={6}>
              <Button onClick={handleProceed} sx={{ maxWidth: '360px', width: '100%' }}>
                Proceed
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant='outlined' onClick={handleClose} sx={{ maxWidth: '360px', width: '100%' }}>
                Cancel
              </Button>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Button onClick={handleClose} sx={{ maxWidth: '360px', width: '100%' }}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PharmacyDetails;
