import { Box, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PrimaryContact from './PrimaryContact';
import { Restricted } from 'components/Restricted';
import { permissions } from 'utils/constants';
import { useAppDispatch } from 'store/hooks';
import { getPatientDetails, sendAssessmentReminder, sendDocumentReminder } from 'store/slices/patientInfoSlice';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getInsuranceDetails, getPatientDetailsFromAdmin } from 'store/slices/userSlice';
import COLORS from 'theme/colors';
import VerifiedIcon from '@mui/icons-material/Verified';
import { downloadPDFAPI, downloadWellnessChildPDFAPI, downloadWellnessParentPDFAPI } from 'services/adminServices';

const PatientDetails = (props: any) => {
  const dispatch = useAppDispatch();

  const [userDetails, setUserDetails] = useState({
    patientId: null,
    patientName: '',
    contactNumber: '',
    mcat: null,
    mfsh: null,
    wellbeing: null,
    intakePaperwork: null,
    type: null,
    upcomingAppointment: null,
    childWellness:'',
    parentWellness:''
  });
  const [patientDetails, setPatientDetails] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  });
  const [insurance, setInsurance] = useState('');

  useEffect(() => {
    getData();
    getInsuranceData();
    getPatientDetailsFromAdminPortal();
    //eslint-disable-next-line
  }, []);

  const getData = () => {
    dispatch(getPatientDetails(props?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setUserDetails({ ...response.data });
        }
      });
  };

  const getPatientDetailsFromAdminPortal = () => {
    dispatch(getPatientDetailsFromAdmin(props?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setPatientDetails({
            firstName: response?.data?.firstName,
            lastName: response?.data?.lastName,
            dateOfBirth: response?.data?.dateOfBirth,
          });
        }
      });
  };

  const getInsuranceData = () => {
    dispatch(getInsuranceDetails(props?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setInsurance(response?.data?.payOutOfPocket ? 'No' : 'Yes');
        } else {
          setInsurance('Not available');
        }
      });
  };

  const getPatientFormattedName = () => {
    if (userDetails?.patientName) return userDetails?.patientName;
    else if (patientDetails?.firstName && patientDetails?.lastName)
      return `${patientDetails.firstName} ${patientDetails.lastName}`;
    else if (patientDetails?.firstName) return patientDetails?.firstName;
    else if (patientDetails?.lastName) return patientDetails?.lastName;
    else return '';
  };

  const sendDocReminder = () => {
    dispatch(sendDocumentReminder(Number(props?.patientId)))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const message = 'Reminder has been send successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
        }
      });
  };
  const sendReminder = (type: string) => {
    dispatch(sendAssessmentReminder({ patientId: Number(props?.patientId), type }))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const message = 'Reminder has been sent successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
        }
      });
  };

  const downloadPDF = (data: any) => {
    downloadPDFAPI(data).then((response) => {
      if (response.status === 200) {
        const linkSource =
          // eslint-disable-next-line max-len
          `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `${data.type}_${data.patientId}_report.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        const message = 'Unable to download please try later.';
        toast(message, {
          type: 'info',
          icon: false,
        });
      }
    });    
  };

  const downloadParentPDF=(data:any)=>{
    downloadWellnessParentPDFAPI(data).then((response) => {
      if (response.status === 200) {
        const linkSource =
          `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `Wellness_${data.patientId}_report_1.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
        const message = 'Unable to download please try later.';
        toast(message, {
          type: 'info',
          icon: false,
        });
      }
    });
  };

  const downloadPatientPDF = (data: any) => {
    downloadWellnessChildPDFAPI(data).then((response) => {
      if (response.status === 200) {
        const linkSource =
          `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `Wellness_${data.patientId}_report_2.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  };

  return (
    <>
      <Grid container borderBottom={'1px solid #EAECF0'}>
        <Grid item xs={6} md={8} alignItems={'flex-start'}>
          <Typography variant='h2' sx={{ fontSize: 20, fontWeight: 500, lineHeight: 1.4 }}>
            {getPatientFormattedName()}
          </Typography>
          <Typography variant='h3' sx={{ fontSize: 14, fontWeight: 400, lineHeight: 1.4 }}>
            {insurance !== '' ? (
              <>
                {' '}
                Insurance : <b> {insurance}</b>{' '}
              </>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} display='flex' alignItems={'flex-start'} justifyContent='end'>
          {patientDetails?.dateOfBirth && (
            <Typography variant='h2' sx={{ span: { fontSize: 10 }, fontSize: 20, fontWeight: 500 }}>
              <span>DOB:</span> {dayjs(patientDetails?.dateOfBirth).format('MMM DD, YYYY')}
            </Typography>
          )}
        </Grid>
      </Grid>

      <PrimaryContact patientId={props?.patientId} />
      <Grid container  borderTop={'1px solid #EAECF0'} 
        borderBottom={'1px solid #EAECF0'} paddingBottom={2.5} paddingTop={2.5}>
        <Grid item xs={6} md={3} display={'flex'} flexDirection='column' justifyContent='center'>
          <Grid
            item
            display='flex'
            justifyContent='flex-start'
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{ height: '100%' }}
          >
            <Typography variant='subtitle1' sx={{ alignItems: 'flex-start' }}>
              MCAT Status&nbsp;
            </Typography>
            {userDetails?.mcat !== 'Not Started' && <VerifiedIcon sx={{ color: COLORS.Link }} />}
          </Grid>
          {userDetails.mcat !== 'Not Started' ? (
            <Restricted permission={permissions.DOWNLOAD_MCAT}>
              <Link variant='button' 
                sx={{cursor:'pointer', mt:'10px'}}
                onClick={() => downloadPDF({ patientId: props?.patientId, type: 'mcat' })}>
                DOWNLOAD PDF
              </Link>
            </Restricted>
          ) : (
            <Restricted permission={permissions.SEND_REMINDERS}>
              <Link variant='button' sx={{cursor:'pointer', mt:'10px'}} onClick={() => sendReminder('mcat')} >
                Send Reminder
              </Link>
            </Restricted>
          )}
        </Grid>
        <Grid item xs={6} md={3} display={'flex'} flexDirection='column' justifyContent='center'>
          <Grid
            item
            display='flex'
            justifyContent='flex-start'
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{ height: '100%' }}
          >
            <Typography variant='subtitle1' sx={{ alignItems: 'flex-start' }}>
              Intake Paperwork&nbsp;
            </Typography>
            {userDetails?.intakePaperwork !== 'Not Started' && <VerifiedIcon sx={{ color: COLORS.Link }} />}
          </Grid>
          {userDetails?.intakePaperwork === 'Not Started' && (
            <Restricted permission={permissions.SEND_REMINDERS}>
              <Link variant='button' sx={{cursor:'pointer', mt:'10px'}} onClick={sendDocReminder}>
                Send Reminder
              </Link>
            </Restricted>
          )}
        </Grid>
        <Grid item xs={6} md={2} display={'flex'} flexDirection='column' justifyContent='flex-start'>
          <Grid
            item
            display='flex'
            justifyContent='flex-start'
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{ height: '100%' }}
          >
            <Typography variant='subtitle1' sx={{ alignItems: 'flex-start' }}>
              MFSH&nbsp;
            </Typography>
            {userDetails?.mfsh !== 'Not Started' && <VerifiedIcon sx={{ color: COLORS.Link }} />}
          </Grid>
          {/* {userDetails?.mfsh ? ( */}
          {userDetails.mfsh !== 'Not Started' ? (
            <Restricted permission={permissions.DOWNLOAD_MFSH}>
              <Link variant='button' sx={{cursor:'pointer', mt:'10px'}} 
                onClick={() => downloadPDF({ patientId: props?.patientId, type: 'mfsh' })}>
                DOWNLOAD PDF
              </Link>
            </Restricted>
          ) : (
            <Restricted permission={permissions.SEND_REMINDERS}>
              <Link variant='button' sx={{cursor:'pointer', mt:'10px'}}
                onClick={() => sendReminder('mfsh')}>
                Send Reminder
              </Link>
            </Restricted>
          )}
        </Grid>
        <Grid item xs={6} md={4} display={'flex'} flexDirection='column' justifyContent='flex-start'>
          <Grid
            item
            display='flex'
            justifyContent='flex-start'
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{ height: '100%' }}
          >
            <Typography variant='subtitle1' sx={{ alignItems: 'flex-start' }}>
              Wellness&nbsp;
            </Typography>
            {userDetails?.parentWellness === 'COMPLETED' && 
             userDetails?.childWellness === 'COMPLETED' &&
            <VerifiedIcon sx={{ color: COLORS.Link }} />}
          </Grid>
          {/* {userDetails?.wellbeing ? ( */}
          {userDetails.wellbeing !== 'Not Started' ? (
            <Restricted permission={permissions.DOWNLOAD_WELLBEING}>
              <Box>
                { userDetails?.parentWellness === 'COMPLETED' ?
                  <Link variant='button' sx={{ cursor: 'pointer', mt: '10px' }}
                    onClick={() => downloadParentPDF({ patientId: props?.patientId, type: 'wellbeing' })}>
                  GUARDIAN PDF
                  </Link> 
                  :  
                  <Link variant='button' sx={{cursor:'pointer', mt:'10px'}}
                    onClick={() => sendReminder('wellbeing')}>
                    Send Reminder
                  </Link>
                } /&nbsp;
                { userDetails?.childWellness === 'COMPLETED' ?
                  <Link variant='button' sx={{ cursor: 'pointer', mt: '10px' }}
                    onClick={() => downloadPatientPDF({ patientId: props?.patientId, type: 'wellbeing' })}>
                   PATIENT PDF
                  </Link> : 
                  <Link variant='button' sx={{cursor:'pointer', mt:'10px'}}
                    onClick={() => sendReminder('wellbeing')}>
                    Send Reminder
                  </Link>
                }
              </Box>
            </Restricted>
          ) : (
            <Restricted permission={permissions.SEND_REMINDERS}>
              <Link variant='button' sx={{cursor:'pointer', mt:'10px'}}
                onClick={() => sendReminder('wellbeing')}>
                Send Reminder
              </Link>
            </Restricted>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PatientDetails;
