import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Typography,  
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { sanitize } from 'dompurify';
import COLORS from 'theme/colors';
import { ROUTE } from 'route/RouteEnums';

interface SingleSelectProps {
  question: any;
  answers: any;
  handleNext: any;
  openModal: any;
}

const SingleSelectPrimary: React.FC<SingleSelectProps> = ({ question, answers, handleNext, openModal }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [otherValue, setOtherValue] = useState<string>('');
  const [value, setValue] = useState('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (question.type !== 'optional') {
      if (selectedAnswers > 0) {
        setDisableNextButton(false);
      } else {
        setDisableNextButton(true);
      }
    } else {
      setDisableNextButton(false);
    }

  }, [question, selectedAnswers]);


  const handleChange = (e: any) => {
    const { id } = e.target;
    setSelectedAnswers(parseInt(id));
    setValue(e.target.value);
    if (e.target.name === 'other') {
      setDisabled(false);
      setDisableNextButton(true);
    } else {
      setOtherValue('');
      setDisabled(true);
      setErrorMessage(false);
    }
  };

  const handleGridClick = (obj: any) => {
    const data = {
      target: {
        id: obj.id,
        name: obj.type,
        value: obj.text
      }
    };
    handleChange(data);
  };

  const handleSubmit = () => {
    let answerDetails: { quizId: any; answers: { id: any; input: any; }[] | { id: any; }[]; launchType: any };
    if (!disabled && otherValue !== '') {
      answerDetails = {
        quizId: question.questionId,
        answers: [{ id: selectedAnswers, input: otherValue }],
        launchType: question.launchType
      };
    } else if (selectedAnswers > 0) {
      answerDetails = {
        quizId: question.questionId,
        answers: [{ id: selectedAnswers }],
        launchType: question.launchType
      };
    } else {
      answerDetails = {
        quizId: question.questionId,
        answers: [],
        launchType: question.launchType
      };
    }
    handleNext(answerDetails);
    setValue('');
    setSelectedAnswers([]);
  };

  const handleBackButton=()=>{
    navigate(`${ROUTE.DASHBOARD}`);
  };

  return (
    <Grid container maxWidth='100%'>
      <Box sx={{position: {xs:'inherit', md:'absolute'}, left:'20px', top:'130px'}} >
        <Button 
          sx={{ background: COLORS.LightBlue, minWidth: 140, mb:1  }}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => handleBackButton()}  
          disabled={question?.event === 'back'? true : false}
        >Back To Dashboard</Button>
      </Box>
      {question?.header !== null ? question.header : null}
      <Container sx={{ background: '#fff', padding: '20px 40px', borderRadius: '12px' }}>
        <Typography sx={{ color: '#6941C6', fontSize: '18px', fontWeight: 500, mb: '24px', lineHeight:'28px' }}>
          <div dangerouslySetInnerHTML={{ __html: sanitize(question?.heading) }} /></Typography>

        <Typography mb={3}
          sx={{
            lineHeight: '28px', mt: '5px', mb: '24px', fontSize: '18px',
            fontWeight: 500, color: '#2C3446', fontStyle:'italic'
          }}>
          <div dangerouslySetInnerHTML={{ __html: sanitize(question?.prompt) }} /> </Typography>  
        
        <Typography mb={3} 
          sx={{ lineHeight: '28px', mt: '5px', fontSize:'20px', fontWeight:500, mb:'12px', color:'#2C3446' }}>
          <div dangerouslySetInnerHTML={{ __html: sanitize(question?.question) }} />        
        </Typography>

        {question?.informationSection && question?.informationSection !== null ? (
          <Box mb={6}>
            <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}> 
              <div dangerouslySetInnerHTML={{ __html: sanitize(question?.informationSection) }} /> </Alert>
          </Box>
        ) : null}

        {question.info !== null ? (           
          <Box sx={{ background: '#F8F5FF', p:'8px 12px', alignSelf:'stretch', borderRadius:'8px', mb:'48px' }}>
            <Grid container>
              <Grid item xs={0.5}>
                <InfoOutlinedIcon fontSize='small' color='primary' sx={{ mr: '10px' }} />
              </Grid>
              <Grid item xs={11.5} mt='2px'>
                <Typography color='#1C1F24'>
                  <div dangerouslySetInnerHTML={{ __html: sanitize(question?.info) }} />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {question?.instruction !== null ?
          <Typography mb={3} sx={{ fontSize:'18px', fontWeight: 400, color:'#2C3446' }}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(question?.instruction) }} /></Typography>
          : null}      
          
          
        {answers[0]?.answers?.map((a: any) => {
          return (
            <ToggleButtonGroup
              key={a.text}
              value={value}
              exclusive
              onChange={() => handleGridClick(a)}
              sx={{borderRadius:'8px', 
                border:'1px solid #EEF0F6', 
                margin:'10px',
                background:'#EEF0F6', 
                padding:0,
                '.Mui-selected':{
                  background:'#F8F5FF!important',
                  border:'1.5px solid #9F84FF',
                  borderRadius:'8px!important',
                  color:'#6941C6!important'
                },
                '.Mui-selected:hover':{
                  background:'#F8F5FF!important',
                  border:'1.5px solid #9F84FF',
                  borderRadius:'8px!important',
                  color:'#6941C6!important'
                }
              }}
            >
              <ToggleButton 
                value={a.text} 
                sx={{display:'flex',
                  padding:'10px 24px', 
                  justifyContent:'center',
                  alignContent:'center', 
                  flexWrap:'wrap',
                  color:'#1C1F24!important',  
                  fontSize: '16px',
                  textTransform: 'initial',
                  fontFamily: 'Graphik-Regular,sans-serif'
                }}
                disabled = {a.preSelected}
              >
                <div dangerouslySetInnerHTML={{ __html: sanitize(a?.text) }} />
              </ToggleButton>
                
            </ToggleButtonGroup>
               
          );
        })}         
      
        
        <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: errorMessage ? '50px' : '15px' }}>
          <Box sx={{ textAlign: { xs: 'center' } }} width='70%'>           
            <Button onClick={handleSubmit} disabled={disableNextButton} size='large' fullWidth>
              Next
            </Button>
          </Box>
        </Grid>
      </Container >
    </Grid>
  );
};

export default SingleSelectPrimary;
