/* eslint-disable max-len */

import {useState, useEffect} from 'react';
import { Grid, Container, Typography, CardMedia, styled, Card, CardProps, 
  CardContent, Box, Button, 
  TextField,
  Link,
  CircularProgress} from '@mui/material';
import { useNavigate } from 'react-router-dom';  
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { useAppDispatch } from 'store/hooks';
import { getMCATReportTiles, getMCATReportDetails, saveRatings, saveSuggestions,
  getMFSHStatus,downloadMCATReport } from 'store/slices/mcatSlice';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { toast } from 'react-toastify';
import { useAuth } from 'utils/AuthContext';
import { getPostMeetingSummary } from 'store/slices/patientInfoSlice';
  
const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
  width:'100%'
}));

const MCATResult = () => {
  const [cardDetails, setCardDetails] = useState<any>();
  const [selectedCard, setSelectedCard] = useState<string>('');
  const [q1Rating, setQ1Rating] = useState<number>(0);
  const [q2Rating, setQ2Rating] = useState<number>(0);
  const [q3Rating, setQ3Rating] = useState<number>(0); 
  const [suggestions,setSuggestions]= useState('');
  const [hasAttemptedTest, setHasAttemptedTest] = useState<boolean>();
  const [hasValidResult, setHasValidResult] = useState<boolean>(false);
  const [displayResult,setDisplayResult] = useState<boolean>(true);
  const [likely, setLikely] = useState<any>();
  const [veryLikely, setVeryLikely] = useState<any>();
  const [someWhatLikely, setSomeWhatLikely] = useState<any>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(()=>{
    dispatch(getMCATReportTiles({}))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){  
          setHasAttemptedTest(response?.data?.hasAttemptedTest);
          setHasValidResult(response?.data?.hasValidResult);
          setCardDetails(response?.data?.rootCauses);
          if(response?.data?.rootCauses !== null){
            setSelectedCard(response?.data?.rootCauses[0].domainName);
            getReportDetails(response?.data?.rootCauses[0].domainName);
          }
        }
      });
    dispatch(getPostMeetingSummary(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response?.data !== '') {
          setDisplayResult(false);
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getReportDetails=(type:string)=>{
    dispatch(getMCATReportDetails(type))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setLikely(response?.data?.likely);
          setVeryLikely(response?.data?.very);
          setSomeWhatLikely(response?.data?.someWhat);
        }
      });
  };

  const handleCardClick=(c:any) =>{
    setSelectedCard(c?.domainName);
    getReportDetails(c?.domainName);
  };

  const handleRating1=(rating:number)=>{
    setQ1Rating(rating);
  };
  const handleRating2=(rating:number)=>{
    setQ2Rating(rating);
  };
  const handleRating3=(rating:number)=>{
    setQ3Rating(rating);
  };

  const handleSuggestionsChange=(e:any)=>{
    setSuggestions(e.target.value);
  };

  const handleSuggestions=()=>{
 
    dispatch(saveSuggestions(suggestions))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setSuggestions('');
          toast('Thank you for your suggestions', {
            type: 'success',
            icon: true
          });
        }
      });
  };

  const handleRatings=()=>{
    const data = {
      'How well did the questions resonate with your concerns?': q1Rating,
      'How seamless was your experience with the questionnaire?': q2Rating,
      'How satisfied are you with your overall experience?' : q3Rating
    };

    dispatch(saveRatings(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setQ1Rating(0);
          setQ2Rating(0);
          setQ3Rating(0);
          toast('Rating saved successfully', {
            type: 'success',
            icon: true
          });
        }
      });
  };

  const handleBookAppointment=()=>{
    window.open('https://nextpatient.co/p/mindweal-health/schedule', '_blank');
  };


  const responsive = {
   
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };


  const handleStartTest=()=>{
    dispatch(getMFSHStatus({}))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          if(response?.data === 'COMPLETED' || user?.isExistingUser === true){
            navigate(ROUTE.MCAT_TEST);
          }
          else{
            toast('Please complete the Medical and Family History Assessment first',
              {
                type:'info',
                icon:true
              }
            );
          }
        }
      });
  };
 
  const handleDownloadReport = () => {
    dispatch(downloadMCATReport(user?.userId))
      .unwrap()
      .then((response:any) => {
        if (response.status === 200) {
          const linkSource =
          // eslint-disable-next-line max-len
          `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement('a');
          const fileName = 'M-CAT_Report.pdf';
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          const message = 'Unable to download please try later.';
          toast(message, {
            type: 'info',
            icon: false,
          });
        }
      });
  };

  return (
    <Page backDir={ROUTE.DASHBOARD}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {hasAttemptedTest === undefined ?
            <CircularProgress/> :          
            <Container component='section'>
              {displayResult ?
                <>
                  <Typography
                    variant='h5'
                    position={'relative'}
                    width={'fit-content'}
                    sx={{ paddingTop: 6, textTransform: 'capitalize' }}
                  >
              Your Result
                    <CardMedia
                      component={'img'}
                      src='../images/vector.svg'
                      alt='heading divider'
                      sx={{ position: 'absolute', right: 0, width: 108, height: 'auto' }}
                    />
                  </Typography>
                  {cardDetails !== undefined && cardDetails !== null ?
                    <Grid container marginTop={4}>
                      <Box
                        sx={{
                          background: '#FFECC8',
                          display: 'flex',
                          padding: '30px',
                          margin: '30px 16px',
                          borderRadius: '24px',
                          border: '1px solid #F2F4F7',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'stretch'
                        }}>
                        <Grid container>
                          <Grid item xs={12} md={3}>
                            <CardMedia
                              component={'img'}
                              src='../images/watering.svg'
                              alt='card-img'
                              sx={{ width: 230, height: 'auto', position: 'relative' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography sx={{
                              fontSize: '30px', fontWeight: 600, lineHeight: '55px', color: '#1C1F24',
                              fontFamily: 'Playfair Display,serif'
                            }}>
                   Understanding your child&apos;s M-CAT Results</Typography> 
                            <CardMedia
                              component={'img'}
                              src='../images/medicationVector.svg'
                              alt='heading divider'
                              sx={{ width: 170, height: 'auto' }}
                            />
                            <Typography 
                              sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '30px',
                                mt: '22px', color: '#1C1F24'
                              }}>
                            The M-CAT report serves as a guide to highlight areas for further discussion, helping your provider understand key 
                            focus areas. A final diagnosis is completed after a thorough, face-to-face clinical evaluation. At your upcoming 
                            visit, your provider will build on insights from the M-CAT report, addressing any gray areas to reach a conclusive
                            diagnosis.
                            </Typography>
                            <Button sx={{mt:'24px'}} onClick={handleBookAppointment}>Book Appointment</Button>
                          </Grid>
                        </Grid>
                      </Box>
                      < StyleCard >
                        <CardContent sx={{ display: 'grid' }}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant='h2'>Your Concerns</Typography>
                            </Grid>
                            <Grid item xs={6} textAlign={'right'}>
                              <Button variant='text' onClick={handleDownloadReport}>Download Report</Button>
                            </Grid>
                          </Grid>
                          <Typography variant='body1'>
                        Below are the concerns you reported. Click each &quot;area of concern&quot; to
                        explore potential underlying causes:
                          </Typography>

                          <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={responsive}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                          >
                            {
                              cardDetails?.length && cardDetails?.map((c: any) => {
                                return (
                                  <Box
                                    key={c.title}
                                    textAlign={'center'}
                                    sx={{
                                      background: selectedCard === c?.domainName ? '#FFFBF1' : '#FFF',
                                      border: selectedCard === c?.domainName ? '2px solid #FFC44D' : '1px solid #EFF4FF',
                                      borderRadius: '12px',
                                      padding: '12px 8px 20px 8px',
                                      margin: '10px 20px',
                                      gap: '12px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleCardClick(c)}
                                  >
                                    <Typography minHeight={'80px'}>{c?.title}</Typography>
                                    <CardMedia
                                      component={'img'}
                                      src={`../images/disordericons/${c.imageName}.png`}
                                      alt='heading divider'
                                      sx={{ m: '0 auto', width: 108, height: 'auto' }}
                                    />
                                  </Box>
                                );
                              })
                            }

                          </Carousel>
                          <Box mt='36px'>
                            <Typography variant='h2'>Root Cause Analysis</Typography>
                            <Typography variant='body1'>
                            With over 1000 interactive touch point&apos;s, M-CAT thoroughly examines your
                            child&apos;s mental health, identifying the true cause of your concerns by meticulously evaluating all
                            symptoms, especially those that overlap in various conditions
                            </Typography>
                          </Box>
                          <Box>                        
                            {veryLikely?.length > 0 ? 
                              <Typography sx={{fontWeight:600}}>
                              Possible root causes for this concern may include &nbsp;
                                {veryLikely?.map((v: any, i:number) => { return (<>{(i ? ', ' : '') + v}</>); })}which could be contributing to what you’re experiencing.</Typography> : null}

                            {likely?.length > 0 ?
                              <Typography sx={{fontWeight:600}}>
                              The underlying reasons for this issue could involve &nbsp;
                                {likely?.map((l: any, i:number) => { return (<>{(i ? ', ' : '') + l}  </>); })} suggesting possible explanations for what you’re experiencing.</Typography> :null}
                       
                            { veryLikely?.length === 0 && likely?.length === 0 && someWhatLikely?.length > 0 ?
                              <Typography sx={{fontWeight:600}}>
                              This issue may stem from &nbsp;
                                {someWhatLikely?.map((s: any, i:number) => { return (<>{(i ? ', ' : '') + s} </>);})} possibly affecting what you’re experiencing.</Typography>:null
                            } 
                          </Box>                       
                        </CardContent>
                      </StyleCard>              

                      <StyleCard>
                        <CardContent>
                          <Typography variant='h2'> Next Steps </Typography>

                          <Grid container sx={{background:'#F8F9FC', borderRadius:'12px', padding:'16px 20px'}}>
                          
                            <Typography> 1. Return to the dashboard to complete the Wellness Questionnaire.</Typography>
                          
                            <Typography> 2. Schedule a clinical visit with our board-certified provider, if not already scheduled.</Typography>
                          
                            <Typography> 3. Complete the clinical evaluation to reach a definitive diagnosis and receive a personalized treatment plan.</Typography>
                          
                            <Typography> 4. Receive psycho education materials,including DIY tool-kits and parent support guides.</Typography>
                          
                            <Typography> 5. Begin your journey toward mental wellness. </Typography>                  
                          </Grid>                       

                        </CardContent>      
                      </StyleCard>

                      <StyleCard>
                        <CardContent>
                          <Typography variant='h2'>  Tell Us What You Think, Your Input Matters</Typography>
                          <Typography>Please rate each question on scale of 1 to 5, where 1
                           means &ldquo;Not at all&ldquo; and 5 means &ldquo;Extremely&ldquo;.</Typography>

                          <Grid container sx={{background:'#F8F9FC', borderRadius:'12px', padding:'16px 20px'}}>
                            <Grid item xs={12} md={8}>
                              <Typography variant='h4'>How well did the questions resonate with your concerns?
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} md={4} textAlign='center' pt={'4px'}>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q1Rating === 1 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating1(1)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>1</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background:q1Rating === 2 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating1(2)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>2</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background:q1Rating === 3 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating1(3)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>3</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q1Rating === 4 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating1(4)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>4</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q1Rating === 5 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  cursor:'pointer'     
                                }}
                                onClick={() => handleRating1(5)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>5</Typography></Box>
                            </Grid>                    
                          </Grid>

                          <Grid container 
                            sx={{ background: '#F8F9FC', borderRadius: '12px', padding: '16px 20px', m: '12px 0' }}>
                            <Grid item xs={12} md={8}>
                              <Typography variant='h4'>
                              How seamless was your experience with the questionnaire?</Typography>
                            </Grid>
                            <Grid container item xs={12} md={4} textAlign='center' pt={'4px'}>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q2Rating === 1 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating2(1)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>1</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background:q2Rating === 2 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating2(2)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>2</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background:q2Rating === 3 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating2(3)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>3</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q2Rating === 4 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating2(4)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>4</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q2Rating === 5 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  cursor:'pointer'     
                                }}
                                onClick={() => handleRating2(5)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>5</Typography></Box>
                            </Grid>                    
                          </Grid>

                          <Grid container sx={{background:'#F8F9FC', borderRadius:'12px', padding:'16px 20px'}}>
                            <Grid item xs={12} md={8}>
                              <Typography variant='h4'>How satisfied are you with your overall experience?</Typography>
                            </Grid>
                            <Grid container item xs={12} md={4} textAlign='center' pt={'4px'}>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q3Rating === 1 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating3(1)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>1</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background:q3Rating === 2 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating3(2)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>2</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background:q3Rating === 3 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating3(3)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>3</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q3Rating === 4 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  mr:'20px',
                                  cursor:'pointer'                        
                                }}
                                onClick={() => handleRating3(4)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>4</Typography></Box>
                              <Box 
                                textAlign={'center'}
                                sx={{
                                  height:'30px',
                                  width:'30px',
                                  background: q3Rating === 5 ? '#FFC44D' : '#FFFBF1',
                                  border:'1.5px solid #FFC44D',
                                  borderRadius:'50%',  
                                  paddingTop:'1.5%',
                                  cursor:'pointer'     
                                }}
                                onClick={() => handleRating3(5)}
                              ><Typography sx={{color:'#2D2D2D', fontSize:'16px', fontWeight:600}}>5</Typography></Box>
                            </Grid>                    
                          </Grid>

                          <Box textAlign={'right'} mt='20px'>
                            <Button variant='outlined' onClick={()=>handleRatings()}
                              disabled={q1Rating === 0 || q2Rating === 0 || q3Rating === 0}>
                        Submit</Button>
                          </Box>

                        </CardContent>      
                      </StyleCard>

                      <StyleCard>
                        <CardContent>
                          <Typography variant='h2'>Additional Comments</Typography>
                          <Typography>
                  Please share any additional thoughts or suggestions:
                          </Typography>
                          <TextField
                            id='suggestions'
                            fullWidth
                            multiline
                            rows={2}
                            placeholder='Enter a description...'
                            variant='filled'
                            onChange={(e) => handleSuggestionsChange(e)}
                          />     
                          <Box textAlign={'right'} mt='20px'>
                            <Button variant='outlined' onClick={handleSuggestions} 
                              disabled={suggestions === '' ? true : false}>
                        Submit</Button>
                          </Box>
                        </CardContent>
                      </StyleCard>
                    </Grid>
                    :
                    <Grid container marginTop={4}>
                      <StyleCard>
                        <CardContent>                        
                          {!hasAttemptedTest ?
                            <>
                              <Typography>You have not attempted test</Typography>
                              <Button onClick={() => handleStartTest()}> Start Test</Button>
                            </>  :
                            !hasValidResult ?
                              <Typography>No result available</Typography>: ''}
                 
                        </CardContent>
                      </StyleCard>
                    </Grid>
                  }
                  {
                    hasAttemptedTest ?
                      <Box textAlign={'center'}>
                        <Button size='small' variant='outlined' sx={{marginRight:'12px'}}
                          onClick={() => navigate(ROUTE.DASHBOARD)}> Book Later</Button>
                        <Button size='small' onClick={handleBookAppointment}>Book Appointment</Button>
                      </Box>
                      : ''
                  }
                </> :               
                <Grid container marginTop={4}>
                  <StyleCard>
                    <CardContent>
                      <Box width='100%'
                        sx={{
                          background: '#FFECC8',
                          display: 'flex',
                          padding: '30px',
                          margin: '30px 0',
                          borderRadius: '24px',
                          border: '1px solid #F2F4F7',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'stretch'
                        }}>
                        <Grid container>
                          <Grid item xs={12} md={3}>
                            <CardMedia
                              component={'img'}
                              src='../images/medicationMgmt.svg'
                              alt='card-img'
                              sx={{ width: 170, height: 'auto', position: 'relative' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography sx={{
                              fontSize: '28px', fontWeight: 600, lineHeight: '55px', color: '#1C1F24',
                              fontFamily: 'Playfair Display,serif'
                            }}>
                  Your Personalized Treatment Plan is Ready!</Typography>
                            <CardMedia
                              component={'img'}
                              src='../images/medicationVector.svg'
                              alt='heading divider'
                              sx={{ width: 170, height: 'auto' }}
                            />
                            <Typography 
                              sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '30px', mt: '22px',
                                color: '#1C1F24'
                              }}>
                  Our treatment plan is tailored specifically for your mental health needs based on your
                  M-CAT assessment and consultation with your provider. It outlines key areas of focus, 
                  recommended therapies, medications (if applicable), and lifestyle changes to help 
                  you on your path to wellness. Regular follow-ups and adjustments will ensure your 
                  treatment evolves with your progress.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '30px',
                              color: '#1C1F24'
                            }}>
                              <Link href={ROUTE.TREATMENT} sx={{ fontSize: '16px',  fontWeight: 400,}}>
                            Click here for comprehensive details </Link> 
                     and access your personalized treatment plan.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    
                    </CardContent>
                  </StyleCard>
                </Grid>
              }
            </Container>
          }
        </Grid > 
      </Grid >
    </Page>
  );
};

export default MCATResult;
