import React from 'react';
import { Container, Typography } from '@mui/material';

const DiyPosts = (props:any) => {
  return (
    <Container component='section' maxWidth='md'>
      <Typography variant='h1'>
        DIY Posts Placeholder
      </Typography>
    </Container>
  );
};


export default DiyPosts;