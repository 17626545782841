import React, { useContext, useEffect, useState } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import ProviderDetails from './ProviderInfo';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import PatientInfo from './PatientInfo';
import PharmacyDetails from './PharmacyDetails';
import InsuranceDetails from './InsuranceDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';
import GuardianInfo from './GuardianInfo/GuardianInfo';
import COLORS from 'theme/colors';
import { Page } from 'components/Page';
import { useAppDispatch } from 'store/hooks';
import { AuthContext } from 'utils/AuthContext';
import { getProfileStatus } from 'store/slices/userSlice';
import { uploadProfileImageAPI, getProfileImageAPI} from 'services/profileServices';
import FileUploader from './InsuranceDetails/FileUploader';

const Profile = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { user, setUserDetails } = useContext(AuthContext);

  const [selectedPanel, setSelectedPanel] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [insuranceStatus, setInsuranceStatus] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const AccordionData = [
    {
      id: 1,
      summary: 'Patient’s Information',
      key: 'patientInfo',
      route: ROUTE.PATIENT_INFORMATION,
      adminRoute: ROUTE.ADMIN_PATIENT_INFORMATION,
      component: (
        <PatientInfo
          onComplete={() => navigate(ROUTE.GUARDIAN_INFORMATION)}
          from={props?.from ? props?.from : null}
          patientId={state?.patientId}
        />
      ),
    },
    {
      id: 2,
      summary: 'Guardian Information',
      key: 'guardianInfo',
      route: ROUTE.GUARDIAN_INFORMATION,
      adminRoute: ROUTE.ADMIN_GUARDIAN_INFORMATION,
      component: (
        <GuardianInfo
          onComplete={() => navigate(ROUTE.PROVIDER_INFORMATION)}
          from={props?.from ? props?.from : null}
          patientId={state?.patientId}
        />
      ),
    },
    {
      id: 3,
      summary: 'Child\'s Primary Care Provider\'s Information ',
      key: 'providerInfo',
      route: ROUTE.PROVIDER_INFORMATION,
      adminRoute: ROUTE.ADMIN_PROVIDER_INFORMATION,
      component: (
        <ProviderDetails
          onComplete={() => navigate(ROUTE.PHARMACY_DETAILS)}
          from={props?.from ? props?.from : null}
          patientId={state?.patientId}
        />
      ),
    },
    {
      id: 4,
      summary: 'Pharmacy Details',
      key: 'pharmacyDetails',
      route: ROUTE.PHARMACY_DETAILS,
      adminRoute: ROUTE.ADMIN_PHARMACY_DETAILS,
      component: (
        <PharmacyDetails
          onComplete={() => navigate(ROUTE.INSURANCE_DETAILS)}
          from={props?.from ? props?.from : null}
          patientId={state?.patientId}
        />
      ),
    },
    {
      id: 5,
      summary: 'Insurance Information',
      key: 'insuranceDetails',
      route: ROUTE.INSURANCE_DETAILS,
      adminRoute: ROUTE.ADMIN_INSURANCE_DETAILS,
      component: (
        <InsuranceDetails
          onComplete={() => {
            setSelectedPanel(null);
            setInsuranceStatus(!insuranceStatus);
          }}
          from={props?.from ? props.from : null}
          patientId={state?.patientId}
        />
      ),
    },
  ];

 

  useEffect(() => {
    setSelectedPanel(props?.for);
    getImage();
    if (props?.from !== 'admin') {
      dispatch(getProfileStatus(user?.userId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            const { data } = response;
            const status = {
              patientInfo: data?.patientInfoStatus,
              guardianInfo: data?.guardianInfoStatus,
              providerInfo: data?.childProviderInfoStatus,
              pharmacyDetails: data?.pharmacyInfoStatus,
              insuranceDetails: data?.paymentInfoStatus,
            };
            setStatus(status);
            if (
              data?.patientInfoStatus &&
              data?.guardianInfoStatus &&
              data?.childProviderInfoStatus &&
              data?.pharmacyInfoStatus &&
              data?.paymentInfoStatus
            ) {
              const data = {
                ...user,
                profileStatus: 'complete',
              };
              setUserDetails(data);
            } else {
              const data = {
                ...user,
                profileStatus: 'incomplete',
              };
              setUserDetails(data);
            }
          }
        });
    }
    //eslint-disable-next-line
  }, [dispatch, props.for, user?.userId, insuranceStatus]);

  const navigateRoute = (path: string) => {
    if (props?.from === 'admin') navigate(path, { state: { patientId: state?.patientId } });
    else navigate(path);
  };

  


  const getImage=()=>{
    const patientId = props?.from === 'admin' ? state?.patientId : user?.patientId;
    getProfileImageAPI(patientId)
      .then((response:any) => {
        if (response.status === 200 && response.data) {
          setProfilePicture(response.data);
        }else{
          setProfilePicture('');
        }
      });
  };

  const handleProfileImage = (acceptedFiles: any) => {
    
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(file);
      };
      reader.readAsDataURL(file);
      const formData: any = new FormData();
      formData.append('file', file);
      uploadProfileImageAPI(formData, user?.patientId);
    }
    
    getImage();
  };


  return (
    <Page backDir={props?.from === 'admin' ? `${ROUTE.ADMIN_PATIENT_INFO}/${state?.patientId}` :
      ROUTE.PROFILE_DASHBOARD}>
      <Container component='main' maxWidth='md'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container>
            <Grid item xs={10} display={'flex'} alignItems={'center'}>
              <Typography variant='h1' gutterBottom>
                Profile Information
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {profilePicture !== null ?
                <IconButton>
                  <FileUploader onFileUpload={handleProfileImage}
                    profileImage={true}
                    profilePicture={profilePicture} 
                    disable={props?.from ==='admin'}
                  />
                </IconButton>
                : null}
            </Grid>
          </Grid>
          {AccordionData.map((item: any, index: number) => {
            return (
              <Accordion
                key={item.key}
                expanded={selectedPanel ? selectedPanel === item.key : index === 0}
                onChange={() => navigateRoute(props.from !== 'admin' ? item.route : item.adminRoute)}
                sx={{ width: '100%', borderRadius: '8px' }}
              >
                <AccordionSummary
                  expandIcon={
                    selectedPanel === item.key ? (
                      <RemoveIcon sx={{ color: '#797979' }} onClick={() => setSelectedPanel(index)} />
                    ) : (
                      <AddIcon sx={{ color: '#797979' }} onClick={() => setSelectedPanel(item.key)} />
                    )
                  }
                  aria-controls='panel-content'
                  id='panel-header'
                  sx={{ flexDirection: 'row-reverse' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      // flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h4' color={COLORS.FormInput} sx={{lineHeight:1.4}}>
                      &nbsp;{item.summary}
                    </Typography>
                    {selectedPanel === item.key ? (
                      <VerifiedIcon sx={{ color: COLORS.White }} />
                    ) : status?.[item?.key] ? (
                      <VerifiedIcon sx={{ color: COLORS.Link }} />
                    ) : (
                      <VerifiedIcon sx={{ color: COLORS.Gray300 }} />
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>{selectedPanel === item.key ? item.component : null}</AccordionDetails>
              </Accordion>
            );
          })}
          <Button sx={{mt:'20px'}} onClick={() => navigate(ROUTE.DASHBOARD)}>Go to Dashboard</Button>
        </Box>
      </Container>
    </Page>
  );
};

export default Profile;
