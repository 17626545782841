import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addDrChronoChartDetailsAPI,
  addMCATIScoreAPI,
  addPaymentStatusAPI,
  addPostMeetingAPI,
  getDrChronoChartIdDetailsAPI,
  getMCATIScoreAPI,
  getMCATIScoreAppointmentAPI,
  getPatientDetailsAPI,
  getPaymentStatusAPI,
  getPostMeetingDetailsAPI,
  getPostMeetingSummaryAPI,
  getUpcomingAppointmentAPI,
  scheduleUpcomingAppointmentAPI,
  sendAssessmentReminderAPI,
  sendDocumentReminderAPI,
} from 'services/adminServices';
import { handleMessage } from 'shared/api/ErrorMessages';

interface Error {
  data: any;
}

export const getPatientDetails = createAsyncThunk(
  'GET_ADMIN_PATIENTS_DETAILS',
  async (patientId: any, { rejectWithValue }: any) => {
    try {
      const response = await getPatientDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getDrChronoChartDetails = createAsyncThunk(
  'GET_DR_CHRONO_CHART_DETAILS',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await getDrChronoChartIdDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getPaymentStatus = createAsyncThunk(
  'GET_PAYMENT_STATUS',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await getPaymentStatusAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getUpcomingAppointmentDetails = createAsyncThunk(
  'GET_UPCOMING_APPOINTMENT',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await getUpcomingAppointmentAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getPostMeetingDetails = createAsyncThunk(
  'GET_POST_MEETING_DETAILS',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await getPostMeetingDetailsAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMCATIScoreDetails = createAsyncThunk(
  'GET_MCAT_I_SCORE',
  async (patientId: any, { rejectWithValue }: any) => {
    try {
      const response = await getMCATIScoreAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getMCATIScoreAppointmentDetails = createAsyncThunk(
  'GET_MCAT_I_SCORE_APPOINTMENTS',
  async (patientId: any, { rejectWithValue }: any) => {
    try {
      const response = await getMCATIScoreAppointmentAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const addDrChronoChartDetails = createAsyncThunk(
  'ADD_DR_CHRONO_CHART_DETAILS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await addDrChronoChartDetailsAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const addPaymentStatus = createAsyncThunk(
  'ADD_PAYMENT_STATUS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await addPaymentStatusAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const scheduleUpcomingAppointment = createAsyncThunk(
  'SCHEDULE_UPCOMING_APPOINTMENT',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await scheduleUpcomingAppointmentAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const addPostMeetingDetails = createAsyncThunk(
  'ADD_POST_MEETING_DETAILS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await addPostMeetingAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const addMCATIScoreDetails = createAsyncThunk(
  'ADD_MCAT_I_SCORE_DETAILS',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await addMCATIScoreAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const sendDocumentReminder = createAsyncThunk(
  'GET_DOCUMENT_REMINDER',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await sendDocumentReminderAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);
export const sendAssessmentReminder = createAsyncThunk(
  'GET_ASSESSMENT_REMINDER',
  async (data: { patientId: number; type: string }, { rejectWithValue }: any) => {
    try {
      const response = await sendAssessmentReminderAPI(data);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

export const getPostMeetingSummary = createAsyncThunk(
  'GET_POST_MEETING_SUMMARY',
  async (patientId: number, { rejectWithValue }: any) => {
    try {
      const response = await getPostMeetingSummaryAPI(patientId);
      return response;
    } catch (err: any) {
      const e = err as Error;
      const status = e?.data?.errorCode;
      const message = handleMessage(status);
      const error = {
        err: err.data,
        message,
        show: false,
      };
      return rejectWithValue(error);
    }
  }
);

interface Loader {
  isPatientDetailsFetched: boolean;
  isDrChronoDetailsFetched: boolean;
  isUpcomingAppointmentFetched: boolean;
  isPostMeetingDetailsFetched: boolean;
  isMCATIScoreFetched: boolean;
  isReminderSend: boolean;
}

interface StateI {
  loaders: Loader;
  error: undefined;
}

const initialState = {
  loaders: {
    isPatientDetailsFetched: false,
    isDrChronoDetailsFetched: false,
    isUpcomingAppointmentFetched: false,
    isPostMeetingDetailsFetched: false,
    isMCATIScoreFetched: false,
    isReminderSend: false,
  },
  error: undefined,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearError(state: StateI) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientDetails.pending, (state: StateI) => {
      state.loaders.isPatientDetailsFetched = true;
    });
    builder.addCase(getPatientDetails.fulfilled, (state: StateI) => {
      state.loaders.isPatientDetailsFetched = false;
    });
    builder.addCase(getPatientDetails.rejected, (state: StateI) => {
      state.loaders.isPatientDetailsFetched = false;
    });

    builder.addCase(getDrChronoChartDetails.pending, (state: StateI) => {
      state.loaders.isDrChronoDetailsFetched = true;
    });
    builder.addCase(getDrChronoChartDetails.fulfilled, (state: StateI) => {
      state.loaders.isDrChronoDetailsFetched = false;
    });
    builder.addCase(getDrChronoChartDetails.rejected, (state: StateI) => {
      state.loaders.isDrChronoDetailsFetched = false;
    });
    builder.addCase(getUpcomingAppointmentDetails.pending, (state: StateI) => {
      state.loaders.isUpcomingAppointmentFetched = true;
    });
    builder.addCase(getUpcomingAppointmentDetails.fulfilled, (state: StateI) => {
      state.loaders.isUpcomingAppointmentFetched = false;
    });
    builder.addCase(getUpcomingAppointmentDetails.rejected, (state: StateI) => {
      state.loaders.isUpcomingAppointmentFetched = false;
    });
    builder.addCase(getPostMeetingDetails.pending, (state: StateI) => {
      state.loaders.isPostMeetingDetailsFetched = true;
    });
    builder.addCase(getPostMeetingDetails.fulfilled, (state: StateI) => {
      state.loaders.isPostMeetingDetailsFetched = false;
    });
    builder.addCase(getPostMeetingDetails.rejected, (state: StateI) => {
      state.loaders.isPostMeetingDetailsFetched = false;
    });
    builder.addCase(getMCATIScoreDetails.pending, (state: StateI) => {
      state.loaders.isMCATIScoreFetched = true;
    });
    builder.addCase(getMCATIScoreDetails.fulfilled, (state: StateI) => {
      state.loaders.isMCATIScoreFetched = false;
    });
    builder.addCase(getMCATIScoreDetails.rejected, (state: StateI) => {
      state.loaders.isMCATIScoreFetched = false;
    });
    builder.addCase(addDrChronoChartDetails.pending, (state: StateI) => {
      state.loaders.isDrChronoDetailsFetched = true;
    });
    builder.addCase(addDrChronoChartDetails.fulfilled, (state: StateI) => {
      state.loaders.isDrChronoDetailsFetched = false;
    });
    builder.addCase(addDrChronoChartDetails.rejected, (state: StateI) => {
      state.loaders.isDrChronoDetailsFetched = false;
    });
    builder.addCase(scheduleUpcomingAppointment.pending, (state: StateI) => {
      state.loaders.isUpcomingAppointmentFetched = true;
    });
    builder.addCase(scheduleUpcomingAppointment.fulfilled, (state: StateI) => {
      state.loaders.isUpcomingAppointmentFetched = false;
    });
    builder.addCase(scheduleUpcomingAppointment.rejected, (state: StateI) => {
      state.loaders.isUpcomingAppointmentFetched = false;
    });
    builder.addCase(addPostMeetingDetails.pending, (state: StateI) => {
      state.loaders.isPostMeetingDetailsFetched = true;
    });
    builder.addCase(addPostMeetingDetails.fulfilled, (state: StateI) => {
      state.loaders.isPostMeetingDetailsFetched = false;
    });
    builder.addCase(addPostMeetingDetails.rejected, (state: StateI) => {
      state.loaders.isPostMeetingDetailsFetched = false;
    });
    builder.addCase(addMCATIScoreDetails.pending, (state: StateI) => {
      state.loaders.isMCATIScoreFetched = true;
    });
    builder.addCase(addMCATIScoreDetails.fulfilled, (state: StateI) => {
      state.loaders.isMCATIScoreFetched = false;
    });
    builder.addCase(addMCATIScoreDetails.rejected, (state: StateI) => {
      state.loaders.isMCATIScoreFetched = false;
    });
    builder.addCase(sendAssessmentReminder.pending, (state: StateI) => {
      state.loaders.isReminderSend = true;
    });
    builder.addCase(sendAssessmentReminder.fulfilled, (state: StateI) => {
      state.loaders.isReminderSend = false;
    });
    builder.addCase(sendAssessmentReminder.rejected, (state: StateI) => {
      state.loaders.isReminderSend = false;
    });
    builder.addCase(sendDocumentReminder.pending, (state: StateI) => {
      state.loaders.isReminderSend = true;
    });
    builder.addCase(sendDocumentReminder.fulfilled, (state: StateI) => {
      state.loaders.isReminderSend = false;
    });
    builder.addCase(sendDocumentReminder.rejected, (state: StateI) => {
      state.loaders.isReminderSend = false;
    });
  },
});

export default adminSlice.reducer;
