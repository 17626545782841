/* eslint-disable indent */
import { toast } from 'react-toastify';

// eslint-disable-next-line max-len
export const illinoisZipCodes = [
  '60002',
  '60004',
  '60005',
  '60006',
  '60007',
  '60008',
  '60009',
  '60010',
  '60011',
  '60012',
  '60013',
  '60014',
  '60015',
  '60016',
  '60017',
  '60018',
  '60019',
  '60020',
  '60021',
  '60022',
  '60025',
  '60026',
  '60029',
  '60030',
  '60031',
  '60033',
  '60034',
  '60035',
  '60037',
  '60038',
  '60039',
  '60040',
  '60041',
  '60042',
  '60043',
  '60044',
  '60045',
  '60046',
  '60047',
  '60048',
  '60050',
  '60051',
  '60053',
  '60055',
  '60056',
  '60060',
  '60061',
  '60062',
  '60064',
  '60065',
  '60067',
  '60068',
  '60069',
  '60070',
  '60071',
  '60072',
  '60073',
  '60074',
  '60075',
  '60076',
  '60077',
  '60078',
  '60079',
  '60081',
  '60082',
  '60083',
  '60084',
  '60085',
  '60086',
  '60087',
  '60088',
  '60089',
  '60090',
  '60091',
  '60093',
  '60094',
  '60095',
  '60096',
  '60097',
  '60098',
  '60099',
  '60101',
  '60102',
  '60103',
  '60104',
  '60105',
  '60106',
  '60107',
  '60108',
  '60109',
  '60110',
  '60111',
  '60112',
  '60113',
  '60115',
  '60116',
  '60117',
  '60118',
  '60119',
  '60120',
  '60121',
  '60122',
  '60123',
  '60124',
  '60126',
  '60128',
  '60129',
  '60130',
  '60131',
  '60132',
  '60133',
  '60134',
  '60135',
  '60136',
  '60137',
  '60138',
  '60139',
  '60140',
  '60141',
  '60142',
  '60143',
  '60144',
  '60145',
  '60146',
  '60147',
  '60148',
  '60150',
  '60151',
  '60152',
  '60153',
  '60154',
  '60155',
  '60156',
  '60157',
  '60159',
  '60160',
  '60161',
  '60162',
  '60163',
  '60164',
  '60165',
  '60168',
  '60169',
  '60171',
  '60172',
  '60173',
  '60174',
  '60175',
  '60176',
  '60177',
  '60178',
  '60179',
  '60180',
  '60181',
  '60183',
  '60184',
  '60185',
  '60186',
  '60187',
  '60188',
  '60189',
  '60190',
  '60191',
  '60192',
  '60193',
  '60194',
  '60195',
  '60196',
  '60197',
  '60199',
  '60201',
  '60202',
  '60203',
  '60204',
  '60208',
  '60301',
  '60302',
  '60303',
  '60304',
  '60305',
  '60399',
  '60401',
  '60402',
  '60403',
  '60404',
  '60406',
  '60407',
  '60408',
  '60409',
  '60410',
  '60411',
  '60412',
  '60415',
  '60416',
  '60417',
  '60418',
  '60419',
  '60420',
  '60421',
  '60422',
  '60423',
  '60424',
  '60425',
  '60426',
  '60428',
  '60429',
  '60430',
  '60431',
  '60432',
  '60433',
  '60434',
  '60435',
  '60436',
  '60437',
  '60438',
  '60439',
  '60440',
  '60441',
  '60442',
  '60443',
  '60444',
  '60445',
  '60446',
  '60447',
  '60448',
  '60449',
  '60450',
  '60451',
  '60452',
  '60453',
  '60454',
  '60455',
  '60456',
  '60457',
  '60458',
  '60459',
  '60460',
  '60461',
  '60462',
  '60463',
  '60464',
  '60465',
  '60466',
  '60467',
  '60468',
  '60469',
  '60470',
  '60471',
  '60472',
  '60473',
  '60474',
  '60475',
  '60476',
  '60477',
  '60478',
  '60479',
  '60480',
  '60481',
  '60482',
  '60484',
  '60487',
  '60490',
  '60491',
  '60499',
  '60501',
  '60502',
  '60503',
  '60504',
  '60505',
  '60506',
  '60507',
  '60510',
  '60511',
  '60512',
  '60513',
  '60514',
  '60515',
  '60516',
  '60517',
  '60518',
  '60519',
  '60520',
  '60521',
  '60522',
  '60523',
  '60525',
  '60526',
  '60527',
  '60530',
  '60531',
  '60532',
  '60534',
  '60536',
  '60537',
  '60538',
  '60539',
  '60540',
  '60541',
  '60542',
  '60543',
  '60544',
  '60545',
  '60546',
  '60548',
  '60549',
  '60550',
  '60551',
  '60552',
  '60553',
  '60554',
  '60555',
  '60556',
  '60557',
  '60558',
  '60559',
  '60560',
  '60561',
  '60563',
  '60564',
  '60565',
  '60566',
  '60567',
  '60568',
  '60569',
  '60572',
  '60585',
  '60586',
  '60598',
  '60599',
  '60601',
  '60602',
  '60603',
  '60604',
  '60605',
  '60606',
  '60607',
  '60608',
  '60609',
  '60610',
  '60611',
  '60612',
  '60613',
  '60614',
  '60615',
  '60616',
  '60617',
  '60618',
  '60619',
  '60620',
  '60621',
  '60622',
  '60623',
  '60624',
  '60625',
  '60626',
  '60628',
  '60629',
  '60630',
  '60631',
  '60632',
  '60633',
  '60634',
  '60636',
  '60637',
  '60638',
  '60639',
  '60640',
  '60641',
  '60642',
  '60643',
  '60644',
  '60645',
  '60646',
  '60647',
  '60649',
  '60651',
  '60652',
  '60653',
  '60654',
  '60655',
  '60656',
  '60657',
  '60659',
  '60660',
  '60661',
  '60664',
  '60666',
  '60668',
  '60669',
  '60670',
  '60673',
  '60674',
  '60675',
  '60677',
  '60678',
  '60680',
  '60681',
  '60682',
  '60684',
  '60685',
  '60686',
  '60687',
  '60688',
  '60689',
  '60690',
  '60691',
  '60693',
  '60694',
  '60695',
  '60696',
  '60697',
  '60699',
  '60701',
  '60706',
  '60707',
  '60712',
  '60714',
  '60803',
  '60804',
  '60805',
  '60827',
  '60901',
  '60910',
  '60911',
  '60912',
  '60913',
  '60914',
  '60915',
  '60917',
  '60918',
  '60919',
  '60920',
  '60921',
  '60922',
  '60924',
  '60926',
  '60927',
  '60928',
  '60929',
  '60930',
  '60931',
  '60932',
  '60933',
  '60934',
  '60935',
  '60936',
  '60938',
  '60939',
  '60940',
  '60941',
  '60942',
  '60944',
  '60945',
  '60946',
  '60948',
  '60949',
  '60950',
  '60951',
  '60952',
  '60953',
  '60954',
  '60955',
  '60956',
  '60957',
  '60958',
  '60959',
  '60960',
  '60961',
  '60962',
  '60963',
  '60964',
  '60966',
  '60967',
  '60968',
  '60969',
  '60970',
  '60973',
  '60974',
  '61001',
  '61006',
  '61007',
  '61008',
  '61010',
  '61011',
  '61012',
  '61013',
  '61014',
  '61015',
  '61016',
  '61018',
  '61019',
  '61020',
  '61021',
  '61024',
  '61025',
  '61027',
  '61028',
  '61030',
  '61031',
  '61032',
  '61036',
  '61037',
  '61038',
  '61039',
  '61041',
  '61042',
  '61043',
  '61044',
  '61046',
  '61047',
  '61048',
  '61049',
  '61050',
  '61051',
  '61052',
  '61053',
  '61054',
  '61057',
  '61059',
  '61060',
  '61061',
  '61062',
  '61063',
  '61064',
  '61065',
  '61067',
  '61068',
  '61070',
  '61071',
  '61072',
  '61073',
  '61074',
  '61075',
  '61077',
  '61078',
  '61079',
  '61080',
  '61081',
  '61084',
  '61085',
  '61087',
  '61088',
  '61089',
  '61091',
  '61101',
  '61102',
  '61103',
  '61104',
  '61105',
  '61106',
  '61107',
  '61108',
  '61109',
  '61110',
  '61111',
  '61112',
  '61114',
  '61115',
  '61125',
  '61126',
  '61130',
  '61131',
  '61132',
  '61201',
  '61204',
  '61230',
  '61231',
  '61232',
  '61233',
  '61234',
  '61235',
  '61236',
  '61237',
  '61238',
  '61239',
  '61240',
  '61241',
  '61242',
  '61243',
  '61244',
  '61250',
  '61251',
  '61252',
  '61254',
  '61256',
  '61257',
  '61258',
  '61259',
  '61260',
  '61261',
  '61262',
  '61263',
  '61264',
  '61265',
  '61266',
  '61270',
  '61272',
  '61273',
  '61274',
  '61275',
  '61276',
  '61277',
  '61278',
  '61279',
  '61281',
  '61282',
  '61283',
  '61284',
  '61285',
  '61299',
  '61301',
  '61310',
  '61311',
  '61312',
  '61313',
  '61314',
  '61315',
  '61316',
  '61317',
  '61318',
  '61319',
  '61320',
  '61321',
  '61322',
  '61323',
  '61324',
  '61325',
  '61326',
  '61327',
  '61328',
  '61329',
  '61330',
  '61331',
  '61332',
  '61333',
  '61334',
  '61335',
  '61336',
  '61337',
  '61338',
  '61340',
  '61341',
  '61342',
  '61344',
  '61345',
  '61346',
  '61348',
  '61349',
  '61350',
  '61353',
  '61354',
  '61356',
  '61358',
  '61359',
  '61360',
  '61361',
  '61362',
  '61363',
  '61364',
  '61367',
  '61368',
  '61369',
  '61370',
  '61371',
  '61372',
  '61373',
  '61374',
  '61375',
  '61376',
  '61377',
  '61378',
  '61379',
  '61401',
  '61402',
  '61410',
  '61411',
  '61412',
  '61413',
  '61414',
  '61415',
  '61416',
  '61417',
  '61418',
  '61419',
  '61420',
  '61421',
  '61422',
  '61423',
  '61424',
  '61425',
  '61426',
  '61427',
  '61428',
  '61430',
  '61431',
  '61432',
  '61433',
  '61434',
  '61435',
  '61436',
  '61437',
  '61438',
  '61439',
  '61440',
  '61441',
  '61442',
  '61443',
  '61447',
  '61448',
  '61449',
  '61450',
  '61451',
  '61452',
  '61453',
  '61454',
  '61455',
  '61458',
  '61459',
  '61460',
  '61462',
  '61465',
  '61466',
  '61467',
  '61468',
  '61469',
  '61470',
  '61471',
  '61472',
  '61473',
  '61474',
  '61475',
  '61476',
  '61477',
  '61478',
  '61479',
  '61480',
  '61482',
  '61483',
  '61484',
  '61485',
  '61486',
  '61488',
  '61489',
  '61490',
  '61491',
  '61501',
  '61516',
  '61517',
  '61519',
  '61520',
  '61523',
  '61524',
  '61525',
  '61526',
  '61528',
  '61529',
  '61530',
  '61531',
  '61532',
  '61533',
  '61534',
  '61535',
  '61536',
  '61537',
  '61539',
  '61540',
  '61541',
  '61542',
  '61543',
  '61544',
  '61545',
  '61546',
  '61547',
  '61548',
  '61550',
  '61552',
  '61553',
  '61554',
  '61555',
  '61558',
  '61559',
  '61560',
  '61561',
  '61562',
  '61563',
  '61564',
  '61565',
  '61567',
  '61568',
  '61569',
  '61570',
  '61571',
  '61572',
  '61601',
  '61602',
  '61603',
  '61604',
  '61605',
  '61606',
  '61607',
  '61610',
  '61611',
  '61612',
  '61613',
  '61614',
  '61615',
  '61616',
  '61625',
  '61629',
  '61630',
  '61633',
  '61634',
  '61635',
  '61636',
  '61637',
  '61638',
  '61639',
  '61641',
  '61643',
  '61650',
  '61651',
  '61652',
  '61653',
  '61654',
  '61655',
  '61656',
  '61701',
  '61702',
  '61704',
  '61705',
  '61709',
  '61710',
  '61720',
  '61721',
  '61722',
  '61723',
  '61724',
  '61725',
  '61726',
  '61727',
  '61728',
  '61729',
  '61730',
  '61731',
  '61732',
  '61733',
  '61734',
  '61735',
  '61736',
  '61737',
  '61738',
  '61739',
  '61740',
  '61741',
  '61742',
  '61743',
  '61744',
  '61745',
  '61747',
  '61748',
  '61749',
  '61750',
  '61751',
  '61752',
  '61753',
  '61754',
  '61755',
  '61756',
  '61758',
  '61759',
  '61760',
  '61761',
  '61764',
  '61769',
  '61770',
  '61771',
  '61772',
  '61773',
  '61774',
  '61775',
  '61776',
  '61777',
  '61778',
  '61790',
  '61791',
  '61799',
  '61801',
  '61802',
  '61803',
  '61810',
  '61811',
  '61812',
  '61813',
  '61814',
  '61815',
  '61816',
  '61817',
  '61818',
  '61820',
  '61821',
  '61822',
  '61824',
  '61825',
  '61826',
  '61830',
  '61831',
  '61832',
  '61833',
  '61834',
  '61839',
  '61840',
  '61841',
  '61842',
  '61843',
  '61844',
  '61845',
  '61846',
  '61847',
  '61848',
  '61849',
  '61850',
  '61851',
  '61852',
  '61853',
  '61854',
  '61855',
  '61856',
  '61857',
  '61858',
  '61859',
  '61862',
  '61863',
  '61864',
  '61865',
  '61866',
  '61870',
  '61871',
  '61872',
  '61873',
  '61874',
  '61875',
  '61876',
  '61877',
  '61878',
  '61880',
  '61882',
  '61883',
  '61884',
  '61910',
  '61911',
  '61912',
  '61913',
  '61914',
  '61917',
  '61919',
  '61920',
  '61924',
  '61925',
  '61928',
  '61929',
  '61930',
  '61931',
  '61932',
  '61933',
  '61936',
  '61937',
  '61938',
  '61940',
  '61941',
  '61942',
  '61943',
  '61944',
  '61949',
  '61951',
  '61953',
  '61955',
  '61956',
  '61957',
  '62001',
  '62002',
  '62006',
  '62009',
  '62010',
  '62011',
  '62012',
  '62013',
  '62014',
  '62015',
  '62016',
  '62017',
  '62018',
  '62019',
  '62021',
  '62022',
  '62023',
  '62024',
  '62025',
  '62026',
  '62027',
  '62028',
  '62030',
  '62031',
  '62032',
  '62033',
  '62034',
  '62035',
  '62036',
  '62037',
  '62040',
  '62044',
  '62045',
  '62046',
  '62047',
  '62048',
  '62049',
  '62050',
  '62051',
  '62052',
  '62053',
  '62054',
  '62056',
  '62058',
  '62059',
  '62060',
  '62061',
  '62062',
  '62063',
  '62065',
  '62067',
  '62069',
  '62070',
  '62071',
  '62074',
  '62075',
  '62076',
  '62077',
  '62078',
  '62079',
  '62080',
  '62081',
  '62082',
  '62083',
  '62084',
  '62085',
  '62086',
  '62087',
  '62088',
  '62089',
  '62090',
  '62091',
  '62092',
  '62093',
  '62094',
  '62095',
  '62097',
  '62098',
  '62201',
  '62202',
  '62203',
  '62204',
  '62205',
  '62206',
  '62207',
  '62208',
  '62214',
  '62215',
  '62216',
  '62217',
  '62218',
  '62219',
  '62220',
  '62221',
  '62222',
  '62223',
  '62225',
  '62226',
  '62230',
  '62231',
  '62232',
  '62233',
  '62234',
  '62236',
  '62237',
  '62238',
  '62239',
  '62240',
  '62241',
  '62242',
  '62243',
  '62244',
  '62245',
  '62246',
  '62248',
  '62249',
  '62250',
  '62252',
  '62253',
  '62254',
  '62255',
  '62256',
  '62257',
  '62258',
  '62259',
  '62260',
  '62261',
  '62262',
  '62263',
  '62264',
  '62265',
  '62266',
  '62268',
  '62269',
  '62271',
  '62272',
  '62273',
  '62274',
  '62275',
  '62277',
  '62278',
  '62279',
  '62280',
  '62281',
  '62282',
  '62284',
  '62285',
  '62286',
  '62288',
  '62289',
  '62292',
  '62293',
  '62294',
  '62295',
  '62297',
  '62298',
  '62301',
  '62305',
  '62306',
  '62311',
  '62312',
  '62313',
  '62314',
  '62316',
  '62319',
  '62320',
  '62321',
  '62323',
  '62324',
  '62325',
  '62326',
  '62329',
  '62330',
  '62334',
  '62336',
  '62338',
  '62339',
  '62340',
  '62341',
  '62343',
  '62344',
  '62345',
  '62346',
  '62347',
  '62348',
  '62349',
  '62351',
  '62352',
  '62353',
  '62354',
  '62355',
  '62356',
  '62357',
  '62358',
  '62359',
  '62360',
  '62361',
  '62362',
  '62363',
  '62365',
  '62366',
  '62367',
  '62370',
  '62373',
  '62374',
  '62375',
  '62376',
  '62378',
  '62379',
  '62380',
  '62401',
  '62410',
  '62411',
  '62413',
  '62414',
  '62417',
  '62418',
  '62419',
  '62420',
  '62421',
  '62422',
  '62423',
  '62424',
  '62425',
  '62426',
  '62427',
  '62428',
  '62431',
  '62432',
  '62433',
  '62434',
  '62436',
  '62438',
  '62439',
  '62440',
  '62441',
  '62442',
  '62443',
  '62444',
  '62445',
  '62446',
  '62447',
  '62448',
  '62449',
  '62450',
  '62451',
  '62452',
  '62454',
  '62458',
  '62459',
  '62460',
  '62461',
  '62462',
  '62463',
  '62464',
  '62465',
  '62466',
  '62467',
  '62468',
  '62469',
  '62471',
  '62473',
  '62474',
  '62475',
  '62476',
  '62477',
  '62478',
  '62479',
  '62480',
  '62481',
  '62501',
  '62510',
  '62512',
  '62513',
  '62514',
  '62515',
  '62517',
  '62518',
  '62519',
  '62520',
  '62521',
  '62522',
  '62523',
  '62524',
  '62525',
  '62526',
  '62530',
  '62531',
  '62532',
  '62533',
  '62534',
  '62535',
  '62536',
  '62537',
  '62538',
  '62539',
  '62540',
  '62541',
  '62543',
  '62544',
  '62545',
  '62546',
  '62547',
  '62548',
  '62549',
  '62550',
  '62551',
  '62553',
  '62554',
  '62555',
  '62556',
  '62557',
  '62558',
  '62560',
  '62561',
  '62563',
  '62565',
  '62567',
  '62568',
  '62570',
  '62571',
  '62572',
  '62573',
  '62601',
  '62610',
  '62611',
  '62612',
  '62613',
  '62615',
  '62617',
  '62618',
  '62621',
  '62622',
  '62624',
  '62625',
  '62626',
  '62627',
  '62628',
  '62629',
  '62630',
  '62631',
  '62633',
  '62634',
  '62635',
  '62638',
  '62639',
  '62640',
  '62642',
  '62643',
  '62644',
  '62649',
  '62650',
  '62651',
  '62655',
  '62656',
  '62659',
  '62660',
  '62661',
  '62662',
  '62663',
  '62664',
  '62665',
  '62666',
  '62667',
  '62668',
  '62670',
  '62671',
  '62672',
  '62673',
  '62674',
  '62675',
  '62677',
  '62681',
  '62682',
  '62683',
  '62684',
  '62685',
  '62688',
  '62689',
  '62690',
  '62691',
  '62692',
  '62693',
  '62694',
  '62695',
  '62701',
  '62702',
  '62703',
  '62704',
  '62705',
  '62706',
  '62707',
  '62708',
  '62711',
  '62712',
  '62715',
  '62716',
  '62719',
  '62722',
  '62723',
  '62726',
  '62736',
  '62739',
  '62756',
  '62757',
  '62761',
  '62762',
  '62763',
  '62764',
  '62765',
  '62766',
  '62767',
  '62769',
  '62776',
  '62777',
  '62781',
  '62786',
  '62791',
  '62794',
  '62796',
  '62801',
  '62803',
  '62806',
  '62807',
  '62808',
  '62809',
  '62810',
  '62811',
  '62812',
  '62814',
  '62815',
  '62816',
  '62817',
  '62818',
  '62819',
  '62820',
  '62821',
  '62822',
  '62823',
  '62824',
  '62825',
  '62827',
  '62828',
  '62829',
  '62830',
  '62831',
  '62832',
  '62833',
  '62834',
  '62835',
  '62836',
  '62837',
  '62838',
  '62839',
  '62840',
  '62841',
  '62842',
  '62843',
  '62844',
  '62846',
  '62848',
  '62849',
  '62850',
  '62851',
  '62852',
  '62853',
  '62854',
  '62856',
  '62858',
  '62859',
  '62860',
  '62861',
  '62862',
  '62863',
  '62864',
  '62865',
  '62866',
  '62867',
  '62868',
  '62869',
  '62870',
  '62871',
  '62872',
  '62874',
  '62875',
  '62876',
  '62877',
  '62878',
  '62879',
  '62880',
  '62881',
  '62882',
  '62883',
  '62884',
  '62885',
  '62886',
  '62887',
  '62888',
  '62889',
  '62890',
  '62891',
  '62892',
  '62893',
  '62894',
  '62895',
  '62896',
  '62897',
  '62898',
  '62899',
  '62901',
  '62902',
  '62903',
  '62905',
  '62906',
  '62907',
  '62908',
  '62909',
  '62910',
  '62912',
  '62914',
  '62915',
  '62916',
  '62917',
  '62918',
  '62919',
  '62920',
  '62921',
  '62922',
  '62923',
  '62924',
  '62926',
  '62927',
  '62928',
  '62930',
  '62931',
  '62932',
  '62933',
  '62934',
  '62935',
  '62938',
  '62939',
  '62940',
  '62941',
  '62942',
  '62943',
  '62946',
  '62947',
  '62948',
  '62949',
  '62950',
  '62951',
  '62952',
  '62953',
  '62954',
  '62956',
  '62957',
  '62958',
  '62959',
  '62960',
  '62961',
  '62962',
  '62963',
  '62964',
  '62965',
  '62966',
  '62967',
  '62969',
  '62970',
  '62972',
  '62973',
  '62974',
  '62975',
  '62976',
  '62977',
  '62979',
  '62982',
  '62983',
  '62984',
  '62985',
  '62987',
  '62988',
  '62990',
  '62992',
  '62993',
  '62994',
  '62995',
  '62996',
  '62997',
  '62998',
  '62999',
];
export const errorMessages = {
  somthing_went_wrong: 'Something went wrong',
  internal_server_error: 'Internal server error occured',
  forbidden: 'You do not have access',
  bad_request: 'Bad request',
  too_many_request: 'Too many request sent',
  unauthorised: 'Unauthorized! Please login again',
  1000: 'Invalid credentials, please enter the right credentials. In case you have not registered, please sign up',
  1002: 'This user is already registered',
  2000: 'Invalid credentials, please enter the right credentials. In case you have not registered, please sign up',
  2001: 'Looks like you have not verified your email. Please find the link your email to verify',
  2002: 'Verification token expired',
  3001: 'Please fill patient information',
};

export const displayError = (errorMsg: any) => {
  const message = errorMessages[errorMsg as keyof typeof errorMessages];
  toast(message, {
    type: 'error',
    icon: true
  });
};
