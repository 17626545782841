/* eslint-disable indent */
import { useState } from 'react';
import { Dialog, DialogContent, Box, CardMedia, Typography, Button, Stack } from '@mui/material';
import useIosInstallPrompt from './useISOInstallPrompt';
import useWebInstallPrompt from './useWebInstallPrompt';
import logo from 'assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';


export const InstallPWA = () => {
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();
    const [showDialog,setShowDialog] = useState(true);
    const navigate = useNavigate();
    
    if (!iosInstallPrompt && !webInstallPrompt) {
        return (
            <>
                <Dialog
                    open={showDialog}
                    onClose={handleIOSInstallDeclined}
                    maxWidth='xs'
                    aria-describedby='alert-dialog-description'
                    scroll='paper'
                >
                    <DialogContent>
                        <Box className="d-flex justify-content-around">
                            <CardMedia
                                component="img"
                                image={logo}
                                sx={{
                                    width: 'auto',
                                    height: { xs: '45px', md: '60px' },
                                    margin: '0 auto 20px auto',
                                }}
                                alt="logo"
                            />
                            <Typography variant='h3' mb={3} align={'center'}>
                                MindWeal app is already installed on your device.
                            </Typography>

                            <Stack justifyContent={'center'} flexDirection={'row'}>
                                <Button onClick={handleWebInstallDeclined}>Close</Button>
                            </Stack>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    const handleCloseDialog=()=>{
        setShowDialog(false);
        handleIOSInstallDeclined();
        navigate(ROUTE.LOGIN);
    };

    return (
        <Dialog
            open={true}
            onClose={handleIOSInstallDeclined}
            maxWidth='xs'
            aria-describedby='alert-dialog-description'
            scroll='paper'
        >
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {iosInstallPrompt && (
                        <>
                         <Typography variant='body2'>Install the app on your device to access it anytime. 
                         No app store. No download. No hassle.</Typography>
                            <Box mb={'10px'}>
                              1.  Tap
                                <img
                                    src="images/safari_navigate.png"                                               
                                    alt="Add to home screen"
                                    style={{margin:'8px 0 0 8px', background:'#f3f3f3', padding:'4px'}}
                                    width="30"
                                />
                               
                            </Box>
                            <Box mb={'10px'}>
                              2.  Select <span style={{background:'#f3f3f3', padding:'4px'}}>Add to Home Screen </span>
                            </Box>
                            <div className="d-flex justify-content-center" style={{alignSelf:'center'}}>
                                <Button onClick={() => handleCloseDialog()}>Close</Button>
                            </div>
                        </>
                    )}

                    {webInstallPrompt && (
                        <Box className="d-flex justify-content-around">
                            <CardMedia
                                component="img"
                                image={logo}
                                sx={{
                                    width: 'auto',
                                    height: { xs: '45px', md: '60px' },
                                    margin: '0 auto 20px auto',
                                }}

                                alt="image"
                            />
                            <Typography variant='h3' mb={3} align={'center'}>
                                Installing MindWeal app on your device.
                            </Typography>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <Button color="primary" onClick={handleWebInstallAccepted}>
                                    Yes! Go ahead
                                </Button>
                                <Button variant='outlined' onClick={handleWebInstallDeclined}>Cancel</Button>
                            </Stack>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};